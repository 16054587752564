import React from "react";
import search from "../../assets/svg/search.svg";

interface IProps {
  id: string;
  searchValue: string;
  setSearchValue: (e: any) => void;
  extraClass?: string;
  iconClass?: string;
  placeholder?: string;
  inputClass?: string;
}

const Search: React.FC<IProps> = ({
  id,
  searchValue,
  setSearchValue,
  extraClass,
  iconClass,
  placeholder,
  inputClass,
}) => {
  return (
    <div
      className={`flex items-center overflow-hidden rounded-lg bg-[#F5F5F5] ${extraClass}`}
    >
      <label htmlFor={id} className="flex items-center justify-center pl-3">
        <img
          src={search}
          alt="ic_search"
          className={`h-[16px] w-[16px] ${iconClass}`}
        />
      </label>
      <input
        id={id}
        type="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        style={{ backgroundColor: "transparent" }}
        className={`flex-1 border-none py-2 pl-[10px] pr-3 text-sm text-[#8991A3] outline-none placeholder:text-sm placeholder:text-[#8991A3] focus:outline-none ${inputClass}`}
        placeholder={placeholder || "Tìm kiếm"}
      />
    </div>
  );
};

export default Search;
