export const ERROR_CODE: any = {
    UNKNOWN_ERROR: "Lỗi không xác định.",
    AUTHEN_FAIL: 'Lỗi xác thực người dùng',
    LOGIN_FAIL: "Tên đăng nhập hoặc Mật khẩu không hợp lệ.",
    TOKEN_EXPIRED: "Token hết hạn.",
    BANK_INCORRECT: "Tài khoản ngân hàng không hợp lệ.",
    BANK_EXIST: "Số tài khoản ngân hàng đã tồn tại.",
    BANK_NOT_EXIST: "Tài khoản ngân hàng không tồn tại.",
    DELETE_BANK_ERROR: "Vui lòng xóa kênh thanh toán liên kết với ngân hàng trước khi xóa ngân hàng.",
    USER_INCORRECT: "Tài khoản người dùng không đúng.",
    EMAIL_IS_EXIST: "Vui lòng nhập email chưa đăng ký.",
    TAX_NO_INCORECT: "Mã số thuế không đúng với đăng ký.",
    TAX_NO_IS_EXST: "Vui lòng nhập mã số thuế chưa đăng ký.",
    TAX_NO_NOT_EXIST: "Mã số thuế không tồn tại.",
    HOLDER_NAME_NOT_MATCH: "Tài khoản ngân hàng không khớp với Mã số thuế. Vui lòng thử lại.",
    PERFIX_EXIST: "Tiền tố đơn hàng đã tồn tại.",
    CHANNEL_EXIST: "Kênh thanh toán đã tồn tại.",
    CHANNEL_NAME_EXIST: "Kênh thanh toán đã tồn tại.",
    CHANNEL_NOT_FOUND: "Không tìm thấy kênh thanh toán.",
    PERMISSION_DENY: "Người dùng không có quyền truy cập, chỉnh sửa.",
    VA_NOT_FOUND: "Không tìm thấy tài khoản VA.",
    VA_NAME_EXIST: "Kênh thanh toán đã tồn tại.",
    TELEGRAM_NAME_EXIST: "Tên tích hợp Telegram đã tồn tại.",
    TXN_SAVE_ERROR: "Lưu giao dịch lỗi.",
    BIN_CODE_EXPIRE: "Mã pin hết hạn. Vui lòng thử lại.",
    E_ADD_DEVICE: "Lỗi thêm thiết bị.",
    SUBSCRIBE_NOTY_ERROR: "Lỗi đăng ký nhận thông báo.",
    DELETE_DEVICE_ERROR: "Xóa thiết bị thất bại. Vui lòng kiểm tra lại.",
    PASSWORD_NOT_MATCH: 'Sai mật khẩu hiện tại. Vui lòng thử lại.',
    TOKEN_INCORRECT: "Mã xác thực đã hết hạn.",
    ACCOUNT_EXIST: "Tài khoản người dùng đã tồn tại.",
    OTP_EXPIRE: "Mã OTP hết hạn. Vui lòng thử lại.",
    SEND_EMAIL_ERROR: "Lỗi xảy ra trong quá trình gửi email.",
    REQUIRED: "Lỗi yêu cầu phải nhập một field đã bắt buộc.",
    IDENTIFICATION_INVALID: "CCCD không đúng.",
    MOBILE_PHONE_INVALID: "Số điện thoại sai hoặc chưa đăng ký.",
    EMAIL_INVALID: "Email sai.",
    CUSTOMER_NAME_INVALID: "Tên chủ tài khoản không đúng.",
    MERCHANT_NAME_INVALID: "Tên của khách hàng không đúng.",
    BANK_ACCOUNT_INVALID: "Số tài khoản không hợp lệ.",
    BAD_REQUEST: "Lỗi không xác định.",
    ACCOUNT_EXISTED: 'Tài khoản đã tồn tại.',
    ACCOUNT_NUMBER_INVALID: 'Số tài khoản không hợp lệ.',
    CAN_NOT_CREATE_ACCOUNT: 'Không thể tạo tài khoản.',
    ACCOUNT_TYPE_INVALID: 'Loại tài khoản không hợp lệ.',
    ACCOUNT_NO_PURE_INVALID: 'Cấu hình khởi tạo tài khoản không hợp lệ.',
    ACCOUNT_NO_CONFLICT: 'Thông tin khởi tạo tài khoản có xung đột trong request.',
    ACCOUNT_NAME_INVALID: 'Tên tài khoản không hợp lệ.',
    ACCOUNT_NO_SUFFIX_INVALID: 'Số tài khoản hậu tố không hợp lệ.',
    ACCOUNT_STATUS_INVALID: 'Trạng thái tài khoản không hợp lệ.',
    ACCOUNT_NOT_FOUND: 'Không tìm thấy tài khoản.',
    PARTNER_NOT_FOUND: 'Không tìm thấy tên đối tác.',
    MERCHANT_NOT_FOUND: 'Không tìm thấy tên merchant.',
    PARTNER_OR_MERCHANT_INVALID: 'Đối tác không hợp lệ.',
    PARTNER_ACCOUNT_INVALID: 'Tài khoản đối tác không hợp lệ.',
    PARTNER_ACCOUNT_NOT_FOUND: 'Không tìm thấy tài khoản đối tác.',
    PARTNER_MAINTAIN_ERR: 'Lỗi bảo trì tài khoản đối tác.',
    FIXED_AMOUNT_INVALID: 'Số tiền cần thu hộ không hợp lệ.',
    REQUEST_ID_INVALID: 'Request Id không hợp lệ.',
    REQUEST_ID_DUPLICATE: 'Request Id bị trùng.',
    EXCEPTION: 'Lỗi hệ thống.',
    ERROR_UNKNOWN: 'Lỗi không xác định.',
    ACCOUNT_NAME_INVALID_VCCB: 'Họ tên đăng ký không trùng khớp với họ tên chủ tài khoản tại BVBank. Quý khách vui lòng kiểm tra lại.',
    ACCOUNT_NUMBER_INVALID_VCCB: 'Số tài khoản không tồn tại tại BVBank. Quý khách vui lòng kiểm tra lại.',
    CUSTOMER_NOT_FOUND_VCCB: (isBusiness: boolean) => `Quý khách chưa đăng ký dịch vụ NHĐT. Vui lòng quét mã QR tải app ${isBusiness ? 'Digibiz' : 'Digimi'} để đăng ký dịch vụ NHĐT.`,
    ACCOUNT_CLOSED_UNREGISTERED: (isBusiness: boolean) => `Quét mã QR tải app ${isBusiness ? 'Digibiz' : 'Digimi'} để đăng ký lại tài khoản.`,
    PHONE_NO_MATCH_VCCB: 'Số điện thoại đăng ký không trùng khớp với số điện thoại tại BVBank. Quý khách vui lòng kiểm tra lại.',
    TAX_NUMBER_NO_MATCH_VCCB: 'Mã số thuế không trùng khớp thông tin đăng ký ở BVBank.',
    ACCOUNT_SPECILIZED: "Tài khoản không hợp lệ. Quý khách vui lòng liên hệ 1900 555 596.",
    ACCOUNT_WRITE_LOCKED: "Tài khoản đang bị khóa. Quý khách vui lòng liên hệ 1900 555 596.",
    ACCOUNT_CLOSED_REDISTERED: "Tài khoản đã đóng. Quý khách vui lòng đến BVBank gần nhất để được hỗ trợ hoặc liên hệ 1900 555 596.",
}