import Button from "components/button/button";
import Checkbox from "components/checkbox";
import InputField from "components/fields/InputField";
import InputFieldPw from "components/fields/InputFieldPw";
import { useAppDispatch } from "hooks/appHook";
import { md5 } from "js-md5";

import { LocalStorage } from "lib/LocalStorage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { login } from "store/actions/userAction";
import { setUser } from "store/reducers/userSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  account_login: yup.string().required('Vui lòng nhập email'),
  password: yup.string().required('Vui lòng nhập mật khẩu').trim(),
});

export default function SignIn() {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = async (data: yup.Asserts<typeof schema>) => {
    setLoading(true);
    try {
      const res: any = await dispatch(
        login({ ...data, password: md5(data.password) })
      );
      if (res?.meta?.requestStatus === "fulfilled" && res?.payload) {
        LocalStorage.setToken(res?.payload?.data?.access_token);
        LocalStorage.setRefreshToken(res?.payload?.data?.refresh_token);
        dispatch(setUser(true));
        navigate("/");
      } else {
        setError("account_login", { message: "" });
        setError("password", {
          message: "Vui lòng kiểm tra tên đăng nhập hoặc mật khẩu",
        });
      }
    } catch (error) {
      console.log(errors);
    }
    setLoading(false);
  };

  return (
    <form
      className="flex w-full flex-col gap-6 md:gap-8"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <h4 className="text-center text-2xl font-bold text-[#0F1D40] md:text-3xl">
        Đăng nhập
      </h4>
      <div className=" flex w-full flex-col gap-4">
        {/* Account */}
        <InputField
          isRequired
          errors={errors}
          state={errors?.account_login ? "error" : " "}
          register={register}
          id="name"
          name="account_login"
          label="Tên đăng nhập"
          placeholder="Nhập email"
          variant="auth"
          type="text"
        />

        {/* Password */}
        <InputFieldPw
          isRequired={true}
          errors={errors}
          register={register}
          state={errors?.password ? "error" : " "}
          id="password"
          name="password"
          variant="auth"
          extra=""
          label="Mật khẩu"
          placeholder="Nhập mật khẩu"
        />

        {/* Checkbox */}
        <div className="flex items-center justify-between px-2">
          <div className="flex items-center gap-2">
            <Checkbox />
            <p className=" text-sm font-normal text-[#0F1D40]">Lưu tài khoản</p>
          </div>
          <a
            className="text-sm font-medium text-[#1890FF] underline hover:opacity-60"
            href="/auth/forgot-password"
          >
            Quên mật khẩu
          </a>
        </div>
      </div>
      <Button
        title={loading ? "Đang đang nhập..." : "Đăng nhập"}
        disabled={loading}
      />
      <p className="text-center text-sm font-normal text-[#0F1D40]">
        Bạn chưa có tài khoản?{" "}
        <a
          href={
            process.env.REACT_APP_REDIRECT_REGISTER +
            (localStorage.getItem("language") ||
              process.env.REACT_APP_DEFAULT_LANGUAGE) +
            // "/create-account?account_type=PERSONAL"
            "/create-account?account_type=PERSONAL"
          }
          target="_blank"
          rel="noreferrer"
          className="font-semibold text-[#1890FF] underline"
        >
          Đăng ký
        </a>
      </p>
    </form>
  );
}
