import { useLocation, useNavigate } from "react-router-dom";
import FormBVB from "./bvb";
import FormOCB from "./ocb";
import HeaderTitle from "components/header-title/HeaderTitle";
import { BANK_NAME_CODE } from "lib/constants";

const FormBankStep2 = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const searchQuery = new URLSearchParams(location.search);
  const type = BANK_NAME_CODE[searchQuery.get("type")]
  const isOnboard = location.pathname.split("/")[1] == "register";

  return (
    <div className="space-y-6">
      {!isOnboard && (
        <HeaderTitle
          title="Ngân hàng"
          subTitle="Thêm ngân hàng"
          onClickTitle={() => navigate("/admin/bank")}
        />
      )}
      <div className="mx-auto mt-6 h-fit w-full max-w-[1076px] rounded-2xl bg-white px-4 py-5 shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)] lg:p-8">
        <h2 className="mb-6 text-xl font-bold text-[#0F1D40] md:mb-8 md:text-[24px] md:leading-6">
          Nhập tài khoản ngân hàng
        </h2>
        {type === "OCB" ? (
          <FormOCB type={type} isOnboard={isOnboard} />
        ) : (
          <FormBVB type={type} isOnboard={isOnboard} />
        )}
      </div>
    </div>
  );
};

export default FormBankStep2;
