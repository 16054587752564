import add from "assets/svg/add-red.svg";
import { BtnDelete, BtnEdit } from "components/button/actionButton";
import DeleteForm from "components/deleteform";
import Table from "components/table";
import { useAppDispatch } from "hooks/appHook";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteIntegrateTelegram,
  getIntegrateTelegramDevices,
} from "store/actions/telegramAction";
import { setLoading } from "store/reducers/loadingSlice";
import { HeaderTitle } from "../vam";

const IntegrateTelegram = ({ onlyTable }: { onlyTable?: boolean }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { success, error } = CustomToastMessage;
  const [item, setItem] = useState<any>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const page = useSelector((state: any) => state.page.page);
  const pageSize = useSelector((state: any) => state.page.page_size);
  const [totalPage, setTotalPage] = useState(0);
  const [listIntegrateTele, setListIntegrateTele] = useState<any>([]);
  const { va_account_no } = useParams();

  const getListIntegrateTelegramDevices = async () => {
    dispatch(setLoading(true));
    const res: any = await dispatch(
      getIntegrateTelegramDevices({
        page: page,
        page_size: pageSize,
        va_account_no,
      })
    );
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      setTotalPage(res?.payload?.data?.total_page);
      setListIntegrateTele(res?.payload?.data?.data);
      dispatch(setLoading(false));
    }
  };

  const deleteIntegrateTelegramDevices = async (id: string) => {
    const res: any = await dispatch(deleteIntegrateTelegram(id));
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      getListIntegrateTelegramDevices();
      success(
        "Xóa tích hợp thành công",
        `Bạn vừa xoá thành công tích hợp ${item.config.name}.`
      );
    } else error("Xóa tích hợp thất bại", "Vui lòng kiểm tra lại.");
  };

  useEffect(() => {
    getListIntegrateTelegramDevices();
  }, [page, pageSize]);

  const columns = useMemo(
    () => [
      {
        Header: "Tên",
        accessor: "name",
        Cell: ({ row }: any) => (
          <p className="text-black-highlight">{row.original?.config?.name}</p>
        ),
      },
      {
        Header: "Loại báo cáo giao dịch",
        Cell: ({ row }: any) => (
          <p>{row.original?.config?.transaction_type_notice?.name}</p>
        ),
      },
      {
        Header: "Điều kiện",
        accessor: "condition",
        Cell: ({ row }: any) => {
          const isBankAll =
            row.original?.config?.bank_account?.toLowerCase() == "all";
          const isVaAll =
            row.original?.config?.va_account_no?.toLowerCase() == "all";
          return (
            <div className="text-sm">
              <div
                className={`flex ${
                  isBankAll ? "items-center gap-1" : "flex-col"
                }`}
              >
                Tài khoản chính: {!isBankAll && <br />}
                <span className="flex items-center gap-1">
                  {!isBankAll && (
                    <img
                      src={row.original?.config?.logo_url2}
                      alt="logo"
                      width={20}
                      height={20}
                    />
                  )}
                  <span className="text-primary">
                    {isBankAll
                      ? "Tất cả"
                      : `| ${row.original?.config?.bank_account}`}
                  </span>
                </span>
              </div>
              Kênh thanh toán: {!isVaAll && <br />}
              <span className="text-primary">
                {isVaAll ? "Tất cả" : row.original?.config?.va_account_no}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Chat ID",
        accessor: "group_id",
        Cell: ({ row }: any) => (
          <p className="text-sm text-[#0F1D40]">
            {row.original?.config?.group_id}
          </p>
        ),
      },
      {
        Header: "Trạng thái",
        accessor: "is_active",
        Cell: ({ row }: any) => (
          <p className="text-sm font-semibold text-[#8C8C8C]">
            {row.original?.config.is_active === true ? (
              <span className="text-primary">Kích hoạt</span>
            ) : (
              "Tạm ngưng"
            )}
          </p>
        ),
      },
      {
        Header: "Hành động",
        accessor: "action",
        Cell: ({ row }: any) => (
          <div className="flex items-center gap-4">
            <BtnEdit
              onClick={() =>
                navigate(
                  onlyTable
                    ? `/admin/payment/edit-telegram/${row.original.id}`
                    : `/admin/integrate-telegram/edit/${row.original.id}`
                )
              }
            />
            <BtnDelete
              onClick={() => {
                setItem(row.original);
                setShowDeleteModal(true);
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="flex flex-col gap-6 font-inter">
        {!onlyTable && (
          <div className="flex items-end justify-between">
            <HeaderTitle title="Tích hợp" subTitle="Tích hợp Telegram" />
            <Link
              to={`create`}
              className="flex items-center justify-center gap-[8px] rounded border border-primary px-[16px] py-[8px]"
            >
              <img src={add} alt="ic_add" />
              <span className="text-sm font-semibold text-primary">Thêm</span>
            </Link>
          </div>
        )}
        <Table
          columns={columns}
          data={listIntegrateTele}
          row={true}
          totalPage={totalPage}
        />
      </div>
      {showDeleteModal && (
        <DeleteForm
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          title="Xoá tích hợp Telegram"
          desc={`Bạn có chắc chắn muốn xoá tích hợp Telegram ${item?.config?.name}?`}
          item={item}
          handleAction={deleteIntegrateTelegramDevices}
        />
      )}
    </>
  );
};

export default IntegrateTelegram;
