import { useNavigate, useSearchParams } from "react-router-dom";

type TGetDataRequest = {
  page?: number;
  page_size?: number;
  q?: string;
};

export function useControlQueryParams() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const getParams = (): TGetDataRequest => {
    const obj: { [key: string]: string | null } = {
      page: "1",
      page_size: "10",
    };
    for (const [key, value] of searchParams.entries()) {
      obj[key] = value;
    }
    return obj;
  };

  function setParams(paramsObj: { [key: string]: string | number }) {
    const keys = Object.keys(paramsObj);
    keys.forEach((key) => {
      searchParams.set(key, String(paramsObj[key]));
    });

    navigate({ search: searchParams.toString() });
  }

  function deleteParams(param: string, ...anotherParams: string[]) {
    searchParams.delete(param);
    anotherParams.forEach((param) => {
      searchParams.delete(param);
    });
    navigate({ search: searchParams.toString() });
  }

  return { getParams, setParams, deleteParams };
}
