import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "hooks/appHook";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { emailAuthenticator } from "store/actions/userAction";
import * as yup from "yup";

import Button from "components/button/button";
import InputField from "components/fields/InputField";
import { HeaderPw, Notify } from "./common";

const schema = yup.object({
  email: yup.string().required("Vui lòng nhập email"),
});

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const res: any = await dispatch(emailAuthenticator(data));
    if (res?.payload?.data) {
      setIsSuccess(true);
    } else if (res?.payload?.error_code == 'USER_NOT_FOUND') setError("email", { message: "Tên đăng nhập không hợp lệ" });
    setIsLoading(false);
  };

  const subTitle = (
    <h2 className="text-center text-sm font-normal text-black-highlight">
      Kiểm tra Email<span className="font-semibold"> {watch("email")} </span>để
      hoàn tất thao tác lấy lại mật khẩu
    </h2>
  );

  return isSuccess ? (
    <Notify
      icon={
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 16L21.78 26.52C22.4373 26.9586 23.2098 27.1926 24 27.1926C24.7902 27.1926 25.5627 26.9586 26.22 26.52L42 16M10 38H38C39.0609 38 40.0783 37.5786 40.8284 36.8284C41.5786 36.0783 42 35.0609 42 34V14C42 12.9391 41.5786 11.9217 40.8284 11.1716C40.0783 10.4214 39.0609 10 38 10H10C8.93913 10 7.92172 10.4214 7.17157 11.1716C6.42143 11.9217 6 12.9391 6 14V34C6 35.0609 6.42143 36.0783 7.17157 36.8284C7.92172 37.5786 8.93913 38 10 38Z"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      title="Vui lòng kiểm tra Email"
      subTitle={subTitle}
    />
  ) : (
    <div className="w-full space-y-6 md:space-y-8">
      <HeaderPw
        title="Quên mật khẩu"
        desc="Quý khách vui lòng nhập tên đăng nhập đã đăng ký để lấy lại mật khẩu"
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="h-full w-full space-y-6 md:space-y-8"
      >
        <InputField
          id={"email"}
          name={"email"}
          isRequired={true}
          errors={errors}
          state={errors?.email ? "error" : " "}
          register={register}
          label="Tên đăng nhập"
          placeholder="Nhập email"
          variant="auth"
        />
        <div className="space-y-4">
          <Button
            title="Tiếp tục"
            disabled={!watch("email") || isLoading}
            className="w-full"
          />
          <Button
            title="Trở lại"
            outline
            onClick={() => navigate(-1)}
            className="w-full"
            type="button"
          />
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
