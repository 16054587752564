import eye from "assets/img/auth/ic_eye-on.svg";
import pen from "assets/svg/pen.svg";
import trash from "assets/svg/trash.svg";
import { CgClose } from "react-icons/cg";

interface IBtn {
  onClick?: () => void;
}

export const BtnView = ({ onClick }: IBtn) => {
  return (
    <button
      className="flex items-center justify-center rounded-full sm-max:bg-[#1890FF14] sm-max:h-9 sm-max:w-9"
      onClick={onClick}
    >
      <img src={eye} alt="eye" className="size-5" />
    </button>
  );
};

export const BtnEdit = ({ onClick }: IBtn) => {
  return (
    <button
      className="flex items-center justify-center rounded-full sm-max:bg-[#1890FF14] sm-max:h-9 sm-max:w-9"
      onClick={onClick}
    >
      <img src={pen} alt="pen" className="size-5" />
    </button>
  );
};

export const BtnDelete = ({ onClick }: IBtn) => {
  return (
    <button
      className="flex items-center justify-center rounded-full sm-max:bg-[#1890FF14] sm-max:h-9 sm-max:w-9"
      onClick={onClick}
    >
      <img src={trash} alt="trash" className="size-5" />
    </button>
  );
};

export const BtnClose = ({ onClick }: IBtn) => {
  return (
    <CgClose
      className="z-1 absolute right-4 top-4 h-6 w-6 cursor-pointer"
      onClick={onClick}
    />
  );
};
