import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Papa, { LocalFile } from "papaparse";

export const convertCsvToXlsx = (file: any) => {
  let csvData;

  Papa.parse(file, {
    complete: (result) => {
      csvData = result.data;
    },
    header: true,
  });

  const worksheet = XLSX.utils.json_to_sheet(csvData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([wbout], { type: "application/octet-stream" });
  saveAs(blob, "transaction.xlsx");
};
