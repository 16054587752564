import { motion } from "framer-motion";
import { useState } from "react";
import calendarIcon from "../../../assets/icon/icon-calendar.svg";
import { dropdownVariants } from "../../../constants/motion.constant";
import useClickOutside from "../../../hooks/useClickOutside";
import { SelectDateValue } from "../../../types/txn";

import { formatCustomDate } from "lib/date";
import SelectItem from "../select-item";
import SelectCustomDate from "./select-custom-date";

type SelectDateFieldProps = {
  value: SelectDateValue;
  /**
   * Callback when user select date or submit custom date
   * @param value value of selected date
   */
  onChange: (
    value: SelectDateValue,
    startTime?: number,
    endTime?: number
  ) => void;
};

const SelectDateField = ({ value, onChange }: SelectDateFieldProps) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [customDateData, setCustomDateData] = useState<string>("Tùy chỉnh");

  const { nodeRef } = useClickOutside(() => setOpenDropdown(false));

  const options = generateOptions();

  function handleChooseOption(value: SelectDateValue) {
    onChange(value);
    // If in custom mode, show the custom date picker and do not close the dropdown
    value === "custom" ? setShowCustomDate(true) : setOpenDropdown(false);
  }

  function generateOptions(): { label: string; value: SelectDateValue }[] {
    return [
      {
        label: "Hôm nay",
        value: "today",
      },
      {
        label: "Hôm qua",
        value: "yesterday",
      },
      {
        label: "7 ngày trước",
        value: "7_days_ago",
      },
      {
        label: "Tháng này",
        value: "this_month",
      },
      {
        label: "Tháng trước",
        value: "last_month",
      },
      {
        label: "Quý này",
        value: "this_quarter",
      },
      {
        label: "Tùy chỉnh",
        value: "custom",
      },
    ];
  }

  function handleSubmitCustomDate(startTime: number, endTime: number) {
    onChange("custom", startTime, endTime);
    setShowCustomDate(false);
    setOpenDropdown(false);
    const customData = `${formatCustomDate(startTime).slice(
      0,
      10
    )} - ${formatCustomDate(endTime).slice(0, 10)}`;
    setCustomDateData(customData);
  }

  function handleCloseCustomDate() {
    setShowCustomDate(false);
  }

  return (
    <div
      ref={nodeRef}
      className="relative h-fit min-w-[210px]"
      onClick={() => setOpenDropdown(!openDropdown)}
    >
      <div className="flex h-11 w-full cursor-pointer justify-between gap-2 rounded-lg border bg-white p-2.5 text-gray-700">
        {value === "custom"
          ? customDateData
          : options.find((option) => option.value === value)?.label}
        <img src={calendarIcon} width={24} height={24} alt="calendar" />
      </div>
      <motion.div
        animate={openDropdown ? "open" : "closed"}
        initial="closed"
        exit="closed"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        variants={dropdownVariants}
        className={`${
          !showCustomDate
            ? "no-scrollbar mt-1 w-full max-w-full overflow-y-auto"
            : ""
        } absolute top-full z-10 origin-top rounded-lg bg-white shadow-[1px_1px_15px_0px_#0D27501A]`}
      >
        {showCustomDate ? (
          <SelectCustomDate
            onClose={handleCloseCustomDate}
            onSubmit={handleSubmitCustomDate}
          />
        ) : (
          options.map((option) => (
            <SelectItem
              key={option.value}
              value={option.value}
              label={option.label}
              onClick={(value: any) => {
                handleChooseOption(value as SelectDateValue);
              }}
            />
          ))
        )}
      </motion.div>
    </div>
  );
};

export default SelectDateField;
