import Label from "./Label";
import { useState } from "react";
import Step from "components/step";

// register
import register1 from "assets/img/step/register/register1.png";
import register2 from "assets/img/step/register/register2.png";
import register3 from "assets/img/step/register/register3.png";
import register4 from "assets/img/step/register/register4.png";
import register5 from "assets/img/step/register/register5.png";
import register6 from "assets/img/step/register/register6.png";

//login
import login1 from "assets/img/step/login/login1.png";
import login2 from "assets/img/step/login/login2.png";

//forgot
import forgot1 from "assets/img/step/forgot/forgot1.png";
import forgot2 from "assets/img/step/forgot/forgot2.png";
import forgot3 from "assets/img/step/forgot/forgot3.png";
import forgot4 from "assets/img/step/forgot/forgot4.png";
import forgot5 from "assets/img/step/forgot/forgot5.png";
import forgot6 from "assets/img/step/forgot/forgot6.png";
import forgot7 from "assets/img/step/forgot/forgot7.png";

//transaction
import transaction1 from "assets/img/step/transaction/transaction2.png";
import transaction2 from "assets/img/step/transaction/transaction2.png";
import transaction3 from "assets/img/step/transaction/transaction3.png";
import transaction4 from "assets/img/step/transaction/transaction.1.2.png";
import transaction5 from "assets/img/step/transaction/transaction.2.2.png";

// banking
import bank1 from "assets/img/step/banking/bank_1.png";
import bank2 from "assets/img/step/banking/bank_2.png";
import bank3 from "assets/img/step/banking/bank_3.png";

// payment
import payment1 from "assets/img/step/payment/payment1.png";
import payment2 from "assets/img/step/payment/payment2.png";
import payment3 from "assets/img/step/payment/payment3.png";
import payment4 from "assets/img/step/payment/payment4.png";

//interactions
import interaction1 from "assets/img/step/interagetion/interage1.png";
import interaction2 from "assets/img/step/interagetion/interage2.png";
import interaction3 from "assets/img/step/interagetion/interage3.png";
import interaction4 from "assets/img/step/interagetion/interage4.png";
import interaction5 from "assets/img/step/interagetion/interage5.png";
import interaction6 from "assets/img/step/interagetion/interage6.png";

// account
import account1 from "assets/img/step/account/account1.png";
import account2 from "assets/img/step/account/account2.png";
import account3 from "assets/img/step/account/account3.png";
import account4 from "assets/img/step/account/account4.png";

// password
import password1 from "assets/img/step/password/password1.png";
import password2 from "assets/img/step/password/password2.png";
import password3 from "assets/img/step/password/password3.png";

import { HeaderTitle } from "../integrate/vam";

const Overview = () => {
  const [active, setActive] = useState(0);
  const [currentTab, setCurrentTab] = useState(1);

  const data = [
    {
      id: 1,
      tab: "Đăng ký lần đầu",
      step: [
        {
          title: "Truy cập VAM",
          content: (
            <span className="text-[12px] font-medium leading-[20px]">
              Truy cập vào liên kết{" "}
              <a href={process.env.REACT_APP_REDIRECT_REGISTER} className="text-[#1890FF] underline">
                {process.env.REACT_APP_REDIRECT_REGISTER}
              </a>{" "}
              và chọn{" "}
              <p className="font-semibold">“Đăng ký trải nghiệm VAM".</p>
            </span>
          ),
          img: register1,
        },
        {
          title: "Nhập thông tin đăng ký",
          content: (
            <div className="flex flex-col text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Hệ thống sẽ hiển thị màn hình{" "}
                <span className="font-bold">Đăng ký</span>, nhập các thông tin
                tài khoản để đăng ký, bao gồm:
              </p>
              <ul className="list-decimal pl-[30px]">
                <li> Họ tên: Nhập họ tên.</li>
                <li> Mã số thuế: Nhập mã số thuế.</li>
                <li> Email: Nhập địa chỉ Email.</li>
                <li>
                  {" "}
                  Đối tượng: Chọn đối tượng trong danh sách (Cá nhân/Hộ gia
                  đình/Doanh nghiệp).
                </li>
                <li>
                  {" "}
                  Ngành nghề kinh doanh: Chọn đối tượng trong danh sách nghề
                  kinh doanh (Ví dụ: Sửa chữa điều hòa, Điện lạnh, …).
                </li>
                <li>
                  {" "}
                  Mật khẩu: Nhập mật khẩu. Mật khẩu phải có ít nhất 6 ký tự.
                </li>
                <li> Tên người đại diện: Nhập tên người đại diện.</li>
                <li>
                  {" "}
                  Số điện thoại người đại điện: Nhập số điện thoại người đại
                  diện.
                </li>
                <li> Mã giới thiệu: Nhập mã giới thiệu.</li>
              </ul>
              <p>
                Sau khi nhập hoàn tất thông tin, chọn nút{" "}
                <span className="font-bold">“Gửi”</span> để hoàn tất đăng ký.
              </p>
            </div>
          ),
          img: register2,
        },
        {
          title: "Nhập thông tin tài khoản ngân hàng",
          content: (
            <div className="flex flex-col text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Hoàn tất đăng ký, hệ thống hiển thị màn hình{" "}
                <span className="font-bold">
                  Nhập thông tin tài khoản ngân hàng
                </span>
                . Nhập các thông tin tài khoản ngân hàng, bao gồm:
              </p>
              <ul className="list-decimal pl-[30px]">
                <li>Ngân hàng: Chọn ngân hàng trong danh sách ngân hàng.</li>
                <li>Số tài khoản: Nhập số tài khoản ngân hàng.</li>
                <li>
                  Họ tên chủ tài khoản: Hệ thống tự động xác thực tài khoản và
                  hiển thị tên chủ tài khoản ngân hàng.
                </li>
              </ul>
              <p>
                Sau khi nhập hoàn tất thông tin tài khoản ngân hàng, chọn nút{" "}
                <span className="font-bold">“Kết nối”</span> để hoàn tất nhập
                thông tin tài khoản ngân hàng.
              </p>
            </div>
          ),
          img: register3,
        },
        // {
        //   title: 'Kiểm tra Email nhận mã OTP',
        //   content: <p className="text-[12px] leading-[20px] font-medium">Hoàn tất nhập thông tin tài khoản ngân hàng, Email nhập ở bước đăng ký sẽ nhận được mã OTP gồm 6 số</p>,
        //   img: step4
        // },
        {
          title: "Tạo kênh thanh toán",
          content: (
            <div className="flex flex-col text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Hoàn tất kết nối tài khoản ngân hàng, hệ thống hiển thị màn hình{" "}
                <span className="font-bold">Tạo kênh thanh toán</span>. Nhập các
                thông tin kênh thanh toán để hoàn tất tạo kênh thanh toán, bao
                gồm:
              </p>
              <ul className="list-decimal pl-[30px]">
                <li>
                  Tên: Nhập tên kênh thanh toán. Có thể nhập tên cửa hàng, tên
                  người người dùng (Ví dụ: Bean Bakery, Nguyễn Hoàng Anh, …).
                </li>
                <li>
                  Logo: Tải lên logo cho kênh thanh toán. Mặc định hệ thống sẽ
                  hiển thị logo ATOM.
                </li>
                <li>
                  Tiền tố đơn hàng: Nhập tiền tố đơn hàng. Nhập được tối đa 3 ký
                  tự: ký tự chữ và ký tự số, không nhập được ký tự đặc biệt.
                </li>
                <li>
                  Thông tin ngân hàng: Chọn tài khoản ngân hàng trong danh sách
                  thông tin ngân hàng hiển thị.
                </li>
              </ul>
              <p>
                Sau khi nhập hoàn tất tạo kênh thanh toán, chọn nút{" "}
                <span className="font-bold">“Tiếp tục”</span> để xác thực tạo
                kênh thanh toán.
              </p>
            </div>
          ),
          img: register4,
        },
        {
          title: "Nhập mã xác thực OTP",
          content: (
            <div className="flex flex-col text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Hoàn tất nhập thông tin kênh thanh toán, Email nhập ở bước đăng
                ký sẽ nhận được mã OTP gồm 6 số.
              </p>
              <p>Nhập mã OTP gồm có 6 số đã được gửi vào Email.</p>
              <p>
                Sau khi hoàn tất nhập mã OTP, chọn nút{" "}
                <span className="font-bold">“Xác nhận OTP”</span> để hoàn tất
                xác thực OTP.
              </p>
              <p className="font-normal italic text-primary">
                {" "}
                <span className="font-bold">Lưu ý:</span> Mã OTP có hiệu lực
                trong vòng 4 phút. Sau 4 phút chọn{" "}
                <span className="font-semibold">“Gửi lại OTP”</span> để nhận mã
                OTP mới.
              </p>
            </div>
          ),
          img: register5,
        },
        {
          title: "Hoàn tất đăng ký lần đầu",
          content: (
            <div className="flex flex-col text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Hoàn tất tạo kênh thanh toán, hệ thống hiển thị màn hình 
                <span className="font-bold">Chi tiết kênh thanh toán</span>, bao
                gồm:
              </p>
              <ul className="indent-5">
                <li>1. Tên kênh thanh toán.</li>
                <li>2. Số tài khoản ngân hàng.</li>
                <li>3. Mã VA.</li>
              </ul>
              <p>
                Có thể tải xuống mã QR bằng cách chọn nút{" "}
                <span className="font-bold">“Tải xuống QR”.</span>
              </p>
              <p>
                Chọn nút <span className="font-bold">“Kết thúc”</span> để hoàn
                tất đăng ký lần đầu.
              </p>
            </div>
          ),
          img: register6,
        },
      ],
    },
    {
      id: 2,
      tab: "Đăng nhập",
      step: [
        {
          title: "Truy cập UNIPAYS",
          content: (
            <div className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Truy cập vào trang{" "}
                <a
                  href="https://vam.unipays.vn/auth/signin"
                  className="text-[#1890FF]"
                >
                  https://vam.unipays.vn/auth/signin
                </a>
                , nhập thông tin tài khoản đã đăng ký. Thông tin đăng nhập gồm:
              </p>
              <ul className="list-decimal pl-[30px]">
                <li>Tên đăng nhập: Nhập tên đăng nhập là Email đã đăng ký.</li>
                <li>Mật khẩu: Nhập mật khẩu.</li>
              </ul>
              <p>
                Sau khi nhập hoàn tất thông tin, chọn nút{" "}
                <span className="font-bold">“Đăng nhập”</span> để hoàn tất đăng
                nhập.
              </p>
            </div>
          ),
          img: login1,
        },
        {
          title: "Đăng nhập thành công",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Sau khi đăng nhập thành công vào UNIPAYS hệ thống hiển thị màn
              hình <span className="font-bold">Tổng quan.</span>{" "}
            </p>
          ),
          img: login2,
        },
      ],
    },
    {
      id: 3,
      tab: "Quên mật khẩu",
      description:
        "Trong trường hợp quên mật khẩu tài khoản truy cập vào UNIPAYS.",
      step: [
        {
          title: "Chọn Quên mật khẩu",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Ở màn hình <span className="font-bold">Đăng nhập</span>, chọn{" "}
              <span className="font-bold">“Quên mật khẩu”</span> để lấy lại mật
              khẩu.
            </p>
          ),
          img: forgot1,
        },
        {
          title: "Nhập mã số thuế đã đăng ký ",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Tại màn hình <span className="font-bold">Lấy lại mật khẩu</span>
                , nhập thông tin Mã số thuế đã đăng ký.
              </p>
              <p>
                Sau đó, chọn nút{" "}
                <span className="font-semibold">“Tiếp tục”</span> để tiến hành
                lấy lại mật khẩu.
              </p>
            </p>
          ),
          img: forgot2,
        },
        {
          title: "Xác nhận danh tính",
          content: (
            <div className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Sau khi hoàn tất nhập Mã số thuế, hệ thống hiển thị màn hình{" "}
                <span className="font-bold">Xác nhận danh tính.</span>
              </p>
              <p>
                Email xác nhận hiển thị dưới dạng{" "}
                <span className="font-bold">{`{2 ký tự đầu}…{2 ký tự cuối}@gmail.com`}</span>{" "}
                (Ví dụ: at..on@gmail.com).
              </p>
              <p>
                Chọn nút <span className="font-bold">“Vâng, đúng là tôi”</span>{" "}
                để xác nhận danh tính.
              </p>
            </div>
          ),
          img: forgot3,
        },
        {
          title: "Hoàn tất Xác nhận danh tính",
          content: (
            <div className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Sau khi hoàn tất xác nhận danh tính, hệ thống hiển thị màn hình
                thông báo{" "}
                <span className="font-bold">Vui lòng kiểm tra Email.</span>
              </p>
              <p>
                Chọn nút <span className="font-bold">“Đóng”</span> hệ thống hiển
                thị màn hình <span className="font-bold">Đăng nhập.</span>
              </p>
            </div>
          ),
          img: forgot4,
        },
        // {
        //   title: 'Kiểm tra Email',
        //   content: <div className="text-[12px] leading-[20px] font-medium text-[#0B1126]">
        //     <p>Kiểm tra Email, chọn <span className="font-bold">“Liên kết”</span> gửi trong Email hệ thống sẽ hiển thị màn hình <span className="font-bold">Thiết lập mật khẩu mới</span></p>
        //     <p className="italic">Lưu ý: liên kết chỉ hiệu lực trong 4 phút</p>
        //   </div>,
        //   img: forgot5
        // },
        {
          title: "Thiết lập mật khẩu mới",
          content: (
            <div className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Kiểm tra Email, truy cập{" "}
                <span className="font-semibold">“Liên kết”</span> được gửi trong
                Email hệ thống sẽ hiển thị màn hình{" "}
                <span className="font-semibold">Thiết lập mật khẩu mới.</span>
              </p>
              <p>
                Tại màn hình{" "}
                <span className="font-bold">Thiết lập mật khẩu mới</span>, nhập
                mật khẩu và xác nhận mật khẩu mới.
              </p>
              <p>
                Chọn nút <span className="font-bold">“Tiếp tục”</span> để hoàn
                tất thiết lập mật khẩu mới.
              </p>
              <p className="font-normal italic text-primary">
                {" "}
                <span className="font-bold">Lưu ý:</span> Liên kết chỉ hiệu lực
                trong vòng 4 phút. Mật khẩu phải có ít nhất 6 ký tự. Nhập khẩu
                và xác nhận mật khẩu phải trùng khớp với nhau.
              </p>
            </div>
          ),
          img: forgot5,
        },
        {
          title: "Hoàn tất thiết lập mật khẩu mới",
          content: (
            <div className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Sau khi hoàn tất thiết lập mật khẩu mới, hệ thống hiển thị màn
                hình thông báo{" "}
                <span className="font-bold">
                  Thiết lập mật khẩu mới thành công.
                </span>
              </p>
              <p>
                Chọn nút <span className="font-bold">“Đóng”</span> hệ thống hiển
                thị màn hình <span className="font-bold">Đăng nhập.</span>
              </p>
            </div>
          ),
          img: forgot6,
        },
      ],
    },
    {
      id: 4,
      tab: "Giao dịch",
      tabs: [
        {
          id: 1,
          title: "Thêm giao dịch",
          desc: "Để thêm giao dịch, thực hiện các bước sau:",
        },
        {
          id: 2,
          title: "Xem giao dịch theo kênh thanh toán",
          desc: "Để xem giao dịch theo kênh thanh toán, thực hiện các bước sau:",
        },
      ],
      information: [
        "1. Tài khoản: Số tài khoản nhận tiền.",
        "3. Nội dung chuyển tiền: Nội dung chuyển tiền của giao dịch.",
        "2. Số tiền: Số tiền giao dịch.",
        "4. Thời gian nhận: Thời gian nhận được giao dịch.",
      ],
      description:
        "Hiển thị lịch sử các giao dịch thành công. Có thể thực hiện lọc các giao dịch theo kênh thanh toán.",
      step: {
        1: [
          {
            title: "Sử dụng camera quét mã QR",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                Sử dụng camera quét mã QR VA của kênh thanh toán để thực hiện
                giao dịch. Thiết bị quét sẽ tự động chuyển tới trang thanh toán.
              </p>
            ),
            img: transaction1,
          },
          {
            title: "Nhập thông tin giao dịch",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                Ở trang thanh toán, nhập các thông tin giao dịch như: Số tiền,
                nội dung chuyển tiền, ... và chọn nút{" "}
                <span className="font-bold">“Thanh toán”.</span>
              </p>
            ),
            img: transaction2,
          },
          {
            title: "Giao dịch thành công",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                Sau khi giao dịch thành công, thông tin giao dịch sẽ được thêm
                vào danh sách ở màn hình{" "}
                <span className="font-bold">Giao dịch.</span>
              </p>
            ),
            img: transaction3,
          },
        ],
        2: [
          {
            title: "Chọn kênh thanh toán",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                Ở màn hình <span className="font-bold"> Giao dịch,</span>chọn ô
                chọn <span className="font-bold">“Chọn kênh thanh toán”</span>{" "}
                để lựa chọn kênh thanh toán muốn xem giao dịch.
              </p>
            ),
            img: transaction4,
          },
          {
            title: "Hoàn tất xem giao dịch theo kênh thanh toán",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                Thông tin các giao dịch của kênh thanh toán sẽ được hiển thị ở
                màn hình <span className="font-bold">Giao dịch</span>.
              </p>
            ),
            img: transaction5,
          },
        ],
      },
    },
    {
      id: 5,
      tab: "Ngân hàng",
      information: [
        "1. Tên ngân hàng: Tên ngân hàng.",
        "2. Tài khoản: Số tài khoản ngân hàng.",
        "3. Tên chủ tài khoản: Tên chủ tài khoản ngân hàng.",
      ],
      description: "Hỗ trợ người dùng quản lý ngân hàng.",
      step: [
        {
          title: "Thêm ngân hàng",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Tại màn hình <strong>Ngân hàng,</strong> chọn nút{" "}
              <strong>"Thêm"</strong> ở trên góc phải màn hình.
            </p>
          ),
          img: bank1,
        },
        {
          title: "Nhập thông tin ngân hàng",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Hệ thống hiển thị màn hình <strong>Thêm ngân hàng</strong>. Nhập
              các thông tin tài khoản ngân hàng, bao gồm:
              <ul className="list-decimal pl-[30px]">
                <li>Ngân hàng: Chọn ngân hàng trong danh sách ngân hàng.</li>
                <li>Số tài khoản: Nhập số tài khoản ngân hàng.</li>
                <li>
                  Họ tên chủ tài khoản: Hệ thống tự động xác thực tài khoản và
                  hiển thị tên chủ tài khoản ngân hàng.
                </li>
              </ul>
              <p>
                Sau khi nhập hoàn tất thông tin tài khoản ngân hàng, chọn nút{" "}
                <strong>“Kết nối”</strong> để hoàn tất nhập thông tin tài khoản
                ngân hàng.
              </p>
            </p>
          ),
          img: bank2,
        },
        {
          title: "Hoàn tất thêm ngân hàng",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Hoàn tất thêm ngân hàng, hệ thống sẽ bắn thông báo{" "}
              <strong>“Thêm ngân hàng thành công”</strong> và được hiển thị vào
              danh sách ngân hàng.
            </p>
          ),
          img: bank3,
        },
      ],
    },

    {
      id: 6,
      tab: "Kênh thanh toán",
      description: "Hỗ trợ người dùng quản lý kênh thanh toán.",
      information: [
        "1. Kênh thanh toán: Tên kênh thanh toán đã được tạo.",
        "4. Tiền tố: Tiền tố đơn hàng đã được tạo.",
        "2. Logo: Logo ngân hàng đã được tạo.",
        "5. Thiết bị tích hợp: Chọn Thiết bị tích hợp hệ thống hiển thị màn hình danh sách các thiết bị đã được tích hợp.",
        "3. Tài khoản kênh: Tên chủ tài khoản ngân hàng đã được tạo.",
      ],
      step: [
        {
          title: "Thêm kênh thanh toán",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Tại màn hình <strong>Kênh thanh toán,</strong> chọn nút{" "}
              <strong>“Thêm”</strong> ở trên góc phải màn hình.
            </p>
          ),
          img: payment1,
        },
        {
          title: "Nhập thông tin kênh thanh toán",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Hệ thống hiển thị màn hình <strong>Thêm kênh thanh toán.</strong>{" "}
              Nhập các thông tin kênh thanh toán để hoàn tất tạo kênh thanh
              toán, bao gồm:
              <ul className="list-decimal pl-[30px]">
                <li>
                  Tên: Nhập tên kênh thanh toán. Có thể nhập tên cửa hàng, tên
                  người người dùng (Ví dụ: Bean Bakery, Nguyễn Hoàng Anh, …).{" "}
                </li>
                <li>
                  Logo: Tải lên logo cho kênh thanh toán. Mặc định hệ thống sẽ
                  hiển thị logo UNIPAYS.
                </li>
                <li>
                  Tiền tố đơn hàng: Nhập tiền tố đơn hàng. Nhập được tối đa 3 ký
                  tự: ký tự chữ và ký tự số.
                </li>
                <li>
                  Thông tin ngân hàng: Chọn tài khoản ngân hàng trong danh sách
                  thông tin ngân hàng hiển thị.
                </li>
              </ul>
              <p>
                Sau khi nhập hoàn tất tạo kênh thanh toán, chọn nút{" "}
                <strong>"Tiếp tục"</strong> để hoàn tất tạo kênh thanh toán.
              </p>
            </p>
          ),
          img: payment2,
        },
        {
          title: "Nhập mã xác thực OTP",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Hoàn tất nhập thông tin kênh thanh toán, Email nhập ở bước đăng
                ký sẽ nhận được mã OTP gồm 6 số.
              </p>
              <p>Nhập mã OTP gồm có 6 số đã được gửi vào Email.</p>
              <p>
                Sau khi hoàn tất nhập mã OTP, chọn nút{" "}
                <span className="font-semibold">“Xác nhận OTP”</span> để hoàn
                tất xác thực OTP.
              </p>
              <p className="italic text-primary">
                <span className="font-bold">Lưu ý:</span> Mã OTP có hiệu lực
                trong vòng 4 phút. Sau 4 phút chọn{" "}
                <span className="font-semibold">“Gửi lại OTP”</span> để nhận mã
                OTP mới.
              </p>
            </p>
          ),
          img: payment3,
        },
        {
          title: "Hoàn tất tạo kênh thanh toán",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Hoàn tất tạo kênh thanh toán, hệ thống sẽ bắn thông báo{" "}
              <strong>“Thêm kênh thanh toán thành công”</strong> và được hiển
              thị vào danh sách kênh thanh toán.
            </p>
          ),
          img: payment4,
        },
      ],
    },
    {
      id: 7,
      tab: "Tích hợp thiết bị",
      description:
        "Hỗ trợ người dùng quản lý các thiết bị tích hợp nhận chia sẻ thông báo.",
      information: [
        "1. Tên thiết bị: Tên thiết bị tích hợp (Ví dụ: Android, IOS, Mac, ...).",
        "4. Phiên bản: Phiên bản của thiết bị tích hợp (Ví dụ: 118.0.0.0, ...).",
        "2. Hệ điều hành: Phiên bản hệ điều hành của thiết bị tích hợp (Ví dụ: Windown 10, ...).",
        "5. IP: Địa chỉ IP của thiết bị tích hợp (Ví dụ: 113.161.93.242, ...).",
        "3. Browser: Trình duyệt thiết bị tích hợp sử dụng (Ví dụ: Chrome, Edge, ...).",
        "6. Kênh thanh toán: Tên kênh thanh toán tích hợp thiết bị.",
      ],
      step: [
        {
          title: "Thêm thiết bị tích hợp",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Tại màn hình <strong>Tích hợp thiết bị</strong>, chọn nút{" "}
              <strong>“Thêm”</strong> ở trên góc phải màn hình.
            </p>
          ),
          img: interaction1,
        },
        {
          title: "Chọn kênh thanh toán",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Hệ thống hiển thị màn hình{" "}
                <strong>Chọn kênh thanh toán.</strong>
              </p>
              <p>
                Chọn kênh thanh toán từ danh sách kênh thánh toán để tích hợp
                thiết bị.
              </p>
              <p>
                Sau đó, chọn nút <strong>“Tích hợp”</strong> để tiếp tục tích
                hợp thiết bị.
              </p>
            </p>
          ),
          img: interaction2,
        },
        {
          title: "Tích hợp thiết bị",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Sau khi hoàn tất chọn kênh thanh toán tích hợp, hệ thống hiển thị
              màn hình <strong>Tích hợp thiết bị,</strong> bao gồm:
              <ul className="list-decimal pl-4">
                <li>
                  Liên kết: Nhập liên kết trên thiết bị muốn nhận thông báo.
                </li>
                <li>Mã chia sẻ: Mã chia sẽ của kênh thanh toán.</li>
                <li>Mã pin: Mã pin để xác nhận chia sẻ thông báo.</li>
              </ul>
              <span className="italic text-primary">
                <span className="font-semibold">Lưu ý:</span> Mã pin chỉ có hiệu
                lực trong 5 phút.
              </span>
            </p>
          ),
          img: interaction3,
        },
        {
          title: "Xác nhận chia sẻ thông báo",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Sao chép liên kết được cung cấp ở màn hình{" "}
                <span className="font-semibold">Tích hợp thiết bị</span> để truy
                cập trên thiết bị muốn nhận thông báo. Hệ thống hiển thị màn
                hình{" "}
                <span className="font-semibold">
                  Xác nhận chia sẻ thông báo.
                </span>
              </p>
              <ul>
                <li>
                  Sau đó, sao chép mã chia sẻ và mã pin từ màn hình{" "}
                  <span className="font-semibold">Tích hợp thiết bị</span>, nhập
                  vào màn hình{" "}
                  <span className="font-semibold">
                    Xác nhận chia sẻ thông báo
                  </span>{" "}
                  của thiết bị.
                </li>
                <li>
                  Chọn nút <span className="font-semibold">“Xác thực”</span> để
                  hoàn tất tích hợp thiết bị.
                </li>
              </ul>
            </p>
          ),
          img: interaction4,
        },
        {
          title: "Hoàn tất tích hợp thiết bị",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              <p>
                Hoàn tất xác nhận chia sẻ thông báo thành công, hệ thông hiển
                thị màn hình{" "}
                <span className="font-semibold"> Tích hợp thành công.</span>
              </p>
              <p>
                Chọn nút <span className="font-semibold">“Đóng”</span> hệ thống
                hiển thị màn hình{" "}
                <span className="font-semibold">
                  Xác nhận chia sẻ thông báo.
                </span>
              </p>
            </p>
          ),
          img: interaction5,
        },
        {
          title: "Hoàn tất chia sẻ thông báo giao dịch thành công",
          content: (
            <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
              Tại màn hình <strong>Xác nhận chia sẻ thông báo,</strong>hiển thị
              thông tin bao gồm:
              <ul className="list-decimal pl-[14px]">
                <li>Kênh thanh toán: Tên đã tích hợp với thiết bị.</li>
                <li>
                  Lịch sử các giao dịch: Danh sách lịch sử các giao dịch thành
                  công.
                </li>
                <li>
                  Thiết bị tích hợp sẽ nhận được thông báo các giao dịch mới ở
                  góc phải màn hình.
                </li>
                <li>
                  Thiết bị tích hợp sẽ nhận được thông báo bằng giọng nói khi
                  đang truy cập ở màn hình{" "}
                  <span className="font-bold">Xác nhận chia sẻ thông báo.</span>
                </li>
              </ul>
            </p>
          ),
          // content: <p className="text-[12px] leading-[20px] font-medium text-[#0B1126]">Sau khi giao dịch thành công, thông tin giao dịch sẽ được thêm vào danh sách ở màn hình Giao dịch</p>,
          img: interaction6,
        },
      ],
    },
    {
      id: 8,
      tab: "Thiết lập",
      description:
        "Chọn Thiết lập để truy cập thông tin tài khoản và đổi mật khẩu.",
      tabs: [
        {
          id: 1,
          title: "Thông tin tài khoản",
        },
        {
          id: 2,
          title: "Đổi mật khẩu",
        },
      ],
      step: {
        1: [
          {
            title: "Chọn thiết lập",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                Chọn <span className="font-semibold">Thiết lập</span> trên thanh
                menu, chọn{" "}
                <span className="font-semibold">Thông tin tài khoản.</span>
              </p>
            ),
            img: account1,
          },
          {
            title: "Thông tin tài khoản",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                Tại màn hình{" "}
                <span className="font-semibold">Thông tin tài khoản,</span> hệ
                thống hiển thị các thông tin:
                <ul className="list-decimal pl-6">
                  <li>Tên: Tên của chủ tài khoản đã đăng ký.</li>
                  <li>Mã số thuế: Mã số thuế đã đăng ký.</li>
                  <li>Email: Email đã đăng ký.</li>
                  <li>
                    Ngành nghề kinh doanh: Ngành nghề kinh doanh đã đăng ký.
                  </li>
                </ul>
              </p>
            ),
            img: account2,
          },
          {
            title: "Cập nhật thông tin",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                <p>Chọn vào ô Email để chỉnh sửa.</p>
                <p>
                  Sau đó, chọn nút{" "}
                  <span className="font-semibold">“Cập nhật”</span> để cập nhật
                  thông tin.
                </p>
                <p className="font-normal italic text-primary">
                  {" "}
                  <strong>Lưu ý:</strong> Chỉ có thể cập nhật địa chỉ Email. Địa
                  chỉ Email phải đúng đinh dạng (Ví dụ: atom@gmail.com).
                </p>
              </p>
            ),
            img: account3,
          },
          {
            title: "Hoàn tất cập nhật thông tin",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                <p>
                  Sau khi cập nhật Email thành công, hệ thống hiển thị thông báo{" "}
                  <span className="font-semibold">
                    Cập nhật thông tin tài khoản thành công.
                  </span>
                </p>
                <p>
                  Chọn nút <span className="font-semibold">“Đóng”</span> hệ
                  thống hiển thị màn hình{" "}
                  <span className="font-semibold">Tổng quan.</span>
                </p>
              </p>
            ),
            img: account4,
          },
        ],
        2: [
          {
            title: "Chọn thiết lập",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                Chọn <span className="font-semibold">Thiết lập</span> trên thanh
                menu, chọn{" "}
                <span className="font-semibold">Thông tin tài khoản.</span>
              </p>
            ),
            img: password1,
          },
          {
            title: "Đổi mật khẩu",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                Tại màn hình{" "}
                <span className="font-semibold">Đổi mật khẩu,</span> hệ thống
                hiển thị các thông tin:
                <ul className="list-decimal pl-6">
                  <li>Mật khẩu hiện tại: Nhập đúng mật khẩu hiện tại.</li>
                  <li>Mật khẩu mới: Nhập mật khẩu mới.</li>
                  <li>Xác nhận lại mật khẩu mới: Nhập lại mật khẩu mới.</li>
                </ul>
                <p>
                  Sau đó, chọn nút{" "}
                  <span className="font-semibold">“Cập nhật”</span> để đổi mật
                  khẩu.
                </p>
                <p className="font-normal italic text-primary">
                  {" "}
                  <strong>Lưu ý:</strong> Mật khẩu mới phải có ít nhất 6 ký tự.
                  Mật khẩu hiện tại phải đúng. Mật khẩu mới và Xác nhận mật khẩu
                  mới phải trùng khớp với nhau.
                </p>
              </p>
            ),
            img: password1,
          },
          {
            title: "Hoàn tất đổi mật khẩu",
            content: (
              <p className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                <p>
                  Sau khi đổi mật khẩu thành công, hệ thống hiển thị thông báo{" "}
                  <span className="font-semibold">
                    Cập nhật mật khẩu thành công.
                  </span>{" "}
                </p>
                <p>
                  Chọn nút <span className="font-semibold">“Đóng” </span>hệ
                  thống hiển thị màn hình{" "}
                  <span className="font-semibold">Tổng quan.</span>
                </p>
              </p>
            ),
            img: password3,
          },
        ],
      },
    },
  ];

  return (
    <div className="flex w-full flex-col gap-[24px] md:mx-auto">
      <HeaderTitle subTitle="Tổng quan" />
      <div className="h-full min-h-[760px] w-full rounded-[16px] bg-[#fff] p-6 shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)]">
        <h2 className="text-center text-[24px] font-semibold leading-[32px] text-[#0F1D40]">
          Hướng dẫn sử dụng VAM
        </h2>
        <Label active={active} setActive={setActive} disabled />
        <Step
          data={data[active]}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          active={active}
          disabled
        />
      </div>
    </div>
  );
};

export default Overview;
