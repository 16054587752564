import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ReactComponent as CancelIcon } from "assets/svg/cancel.svg";
import uploadIcon from "assets/svg/upload.svg";
import { MultipleImgUploadBtnRef } from "views/admin/support/components/ErrorReportForm";

const MultipleImgUploadBtn = forwardRef<MultipleImgUploadBtnRef, {}>(
  (props, uploadImgBtnRef) => {
    const [uploadImg, setUploadImg] = useState<File[]>([]);
    const { setValue } = useFormContext();
    const ref = useRef<HTMLInputElement>(null);

    useImperativeHandle(uploadImgBtnRef, () => ({
      onReset: () => setUploadImg([]),
    }));

    const handleRemoveImg = (index: number) => {
      const newUploadImg = uploadImg.filter((_, i) => i !== index);
      setUploadImg(newUploadImg);
      setValue("error_image", newUploadImg);
    };

    const handleUploadImg = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const newUploadImg = [...uploadImg, event.target.files[0]];
        setUploadImg(newUploadImg);
        setValue("error_image", newUploadImg);
      }
      if (ref.current) ref.current.value = "";
    };

    return (
      <div className="flex flex-col space-y-2">
        <div className="text-sm font-semibold">
          Hình ảnh
          <span className="text-red-error">*</span>
        </div>
        <div className="no-scrollbar flex w-full items-center gap-3 overflow-x-auto rounded-xl border border-[#E2E2E2] p-3">
          <label
            htmlFor="product-image"
            className="flex h-[116px] w-[116px] min-w-[116px] cursor-pointer items-center justify-center rounded border border-dashed border-[#E8E8E8]"
          >
            <img src={uploadIcon} alt="Upload icon" className="h-6 w-6" />
          </label>
          <input
            id="product-image"
            ref={ref}
            onChange={handleUploadImg}
            className="hidden"
            accept="image/*"
            type="file"
          />
          {uploadImg.map((img: File, index) => (
            <div
              key={index}
              className="relative min-w-[116px] rounded border border-[#E2E2E2] transition"
            >
              <button
                type="button"
                onClick={() => handleRemoveImg(index)}
                className="absolute right-[-8px] top-[-5px] flex items-center justify-center rounded-full bg-primary p-[2px]"
              >
                <CancelIcon className="h-4 w-4" stroke="white" />
              </button>
              <img
                src={URL.createObjectURL(img)}
                alt="Product"
                className="h-[116px] w-[116px] rounded"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export default MultipleImgUploadBtn;
