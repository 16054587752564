import { createAsyncThunk } from "@reduxjs/toolkit";
import { verifyBankService } from "store/services/verifyBankService";

export const verifyBank = createAsyncThunk(
  "bank/verifyBank",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await verifyBankService.validateBank(params);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
