import { createAsyncThunk } from "@reduxjs/toolkit";
import { transactionService } from "store/services/transactionService";

export const getTransacions = createAsyncThunk(
    "portal/dashboard/transaction-history",
    async (params: any, { dispatch, getState, rejectWithValue }) => { 
        try {
            const response = await transactionService.getTransacions(params);
            return response;
        } catch (err) { 
            return rejectWithValue(err);
        }
    }
)