import HeaderTitle from "components/header-title/HeaderTitle";
import { useNavigate, useParams } from "react-router-dom";
import FormTelegram from "../integrate/telegram/components/FormTelegram";
import { useAppDispatch } from "hooks/appHook";
import {
  getDetailIntegrateTelegram,
  updateIntegrateTelegram,
} from "store/actions/telegramAction";
import { useEffect, useState } from "react";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";

const UpdatePaymentTele = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [oldName, setOldName] = useState("");
  const [defaultValues, setDefaultValues] = useState(null);
  const { id } = useParams();

  const handleGetDetail = async () => {
    const res: any = await dispatch(getDetailIntegrateTelegram(id));
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data?.data) {
      const data = res.payload.data.data[0].config;
      setDefaultValues({
        ...data,
        transaction_type_notice: String(data.transaction_type_notice?.id),
        is_active: String(data.is_active)
      });
      setOldName(data.name);
    }
  };

  const onSubmit = async (data: any, config: any) => {
    const res: any = await dispatch(
      updateIntegrateTelegram({
        payment_notice_id: id,
        config,
      })
    );
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      CustomToastMessage.success(
        `Chỉnh sửa tích hợp thành công`,
        `Bạn vừa chỉnh sửa thành công tích hợp ${
          oldName !== data.name ? oldName + " thành " : ""
        } ${data.name}.`
      );
      navigate(-1);
    } else
      CustomToastMessage.error(
        `Chỉnh sửa tích hợp thất bại`,
        ERROR_CODE[res?.payload?.error_code]
      );
  };

  useEffect(() => {
    if (id) handleGetDetail();
  }, [id]);

  return (
    <div className="flex flex-col items-center gap-6 font-inter">
      <HeaderTitle
        onClickTitle={() => navigate("/admin/payment")}
        title="Kênh thanh toán"
        subTitle="Chỉnh sửa tích hợp Telegram"
      />
      <FormTelegram defaultValues={defaultValues} onSubmit={onSubmit} submitBtnTitle="Cập nhật"/>
    </div>
  );
};

export default UpdatePaymentTele;
