import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { BtnClose } from "components/button/actionButton";
import SelectField from "components/fields/SelectField";
import { useAppDispatch } from "hooks/appHook";
import { CustomToastMessage } from "lib/CustomToastMessage";
import React from "react";
import { useForm } from "react-hook-form";
import { generateBinCode } from "store/actions/deviceAction";
import { getListPaymentChannel } from "store/actions/paymentChannelAction";
import { setCode } from "store/reducers/codeSlice";
import { Button } from "../vam";

export default function SelectChannel(props: {
  isOpen?: boolean;
  onClose?: any;
  openShare?: any;
}) {
  const dispatch = useAppDispatch();
  const [data, setData] = React.useState([]);
  const { isOpen, onClose, openShare } = props;

  const { handleSubmit, setValue, watch } = useForm();

  const onSubmits = async (data: any) => {
    const res = await dispatch(generateBinCode(data));
    if (res.meta.requestStatus === "fulfilled" && res.payload) {
      dispatch(setCode((res as any)?.payload.data));
      openShare();
      onClose();
      setValue("id", "");
    } else {
      dispatch(setCode({}));
      CustomToastMessage.error(
        "Tạo mã chia sẻ và mã pin thất bại",
        "Bạn vui lòng thử lại."
      );
    }
  };
  const onErrors = (errors: any) => console.error(errors);

  const handleClose = () => {
    onClose();
    setValue("id", "");
  };

  const getPaymentChannel = async () => {
    const res: any = await dispatch(getListPaymentChannel({}));
    setData(
      res.payload?.data.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      })
    );
  };
  React.useEffect(() => {
    getPaymentChannel();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size={"xl"}
      isCentered
    >
      <ModalOverlay>
        <ModalContent
          style={{
            borderRadius: 12,
            width: "92.27%",
            maxWidth: 635,
            position: "relative",
          }}
        >
          <div className="flex w-full flex-col gap-6 rounded-2xl bg-white p-4 font-inter md:gap-8 md:p-8">
            <BtnClose onClick={() => handleClose()} />
            <h1 className="text text-2xl font-bold text-[#0F1D40] lg:text-[32px] lg:leading-[44px]">
              Chọn kênh thanh toán
            </h1>
            <form
              className="flex flex-col gap-8"
              onSubmit={handleSubmit(onSubmits, onErrors)}
            >
              <div className="flex flex-col gap-[16px]">
                <p className="text-sm font-medium text-[#262626]">
                  Vui lòng chọn kênh thanh toán để tích hợp thiết bị!
                </p>
                <SelectField
                  formField="id"
                  title="Kênh thanh toán"
                  required
                  data={data}
                  setValue={setValue}
                  placeHolder="Chọn kênh thanh toán"
                  watch={watch}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Button
                  title="Hủy"
                  outline
                  type="button"
                  onClick={handleClose}
                />
                <Button title="Tích hợp" disabled={!watch("id")} />
              </div>
            </form>
          </div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
