import { ReactComponent as CancelIcon } from "assets/svg/cancel.svg";
import { ReactComponent as ZoomInIcon } from "assets/svg/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "assets/svg/zoom-out.svg";

type ViewImgBtnProps = {
  curImg: string;
  width: number;
  handleCloseViewImg: () => void;
  setWidth: (width: number) => void;
};

const ViewImgBtn = (props: ViewImgBtnProps) => {
  const { curImg, width, handleCloseViewImg, setWidth } = props;

  const increaseWidth = () => {
    setWidth(width + 10);
  };

  const decreaseWidth = () => {
    setWidth(width - 10 > 0 ? width - 10 : 0);
  };

  return (
    <div className="fixed inset-0 z-[1] flex items-center justify-center bg-[#000000]/30">
      <div className="mx-auto flex items-center justify-center">
        <img src={curImg} alt="Details" style={{ width: `${width}vw` }} />
      </div>
      <div className="absolute bottom-0 right-0 m-4 flex flex-col items-center justify-center gap-1">
        <button
          onClick={handleCloseViewImg}
          className="rounded-full bg-white p-2 shadow-md"
        >
          <CancelIcon className="h-6 w-6 text-gray-800" stroke="black" />
        </button>
        <button
          onClick={increaseWidth}
          className="rounded-full bg-white p-2 shadow-md"
        >
          <ZoomInIcon className="h-6 w-6 text-gray-800" />
        </button>
        <button
          onClick={decreaseWidth}
          className="rounded-full bg-white p-2 shadow-md"
        >
          <ZoomOutIcon className="h-6 w-6 text-gray-800" />
        </button>
      </div>
    </div>
  );
};

export default ViewImgBtn;
