import Footer from "components/footer";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar/Sidebar";
import useWindowSize from "hooks/useWindowSize";
import { useState } from "react";
import { Outlet } from "react-router-dom";

const Admin = () => {
  const { isMd } = useWindowSize();
  const [openSidebar, setOpenSidebar] = useState(isMd);

  return (
    <div className="flex h-screen w-full overflow-hidden font-inter">
      <Sidebar open={openSidebar} setOpen={setOpenSidebar} />
      <div
        className={`flex flex-[1_0_0] flex-col overflow-hidden bg-[#F9FAFB]`}
      >
        <Navbar setOpenSidebar={setOpenSidebar} />
        <div className="no-scrollbar flex w-full flex-[1_0_0] flex-col overflow-y-auto">
          <div className="flex-[1_0_0] px-4 py-4 md:px-[30px] md:py-6">
            <Outlet />
          </div>
          <div className="sticky bottom-0 z-10">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
