import { useState, useEffect } from "react";
import { getListPaymentChannel } from "store/actions/paymentChannelAction";
import { useAppDispatch } from "hooks/appHook";
import { setLoading } from "store/reducers/loadingSlice";
import { deletePaymentChannel } from "store/actions/paymentChannelAction";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";

import PChannelTable from "./components/PChannelTable";
import DeleteForm from "components/deleteform";
import FrmUpdatePChannel from "./components/FrmUpdatePChannel";
import ShowQR from "./components/ShowQR";
import DetailChannel from "./components/DetailChannel";

const Payment = () => {
  const dispatch = useAppDispatch();
  const { success, error } = CustomToastMessage;
  const [listPaymentChannel, setListPaymentChannel] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<any>();
  const [isDeleted, setIsDeleted] = useState(false)

  const handleListChannel = async (is_deleted: boolean = false) => {
    setIsDeleted(is_deleted)
    dispatch(setLoading(true));
    const res: any = await dispatch(getListPaymentChannel({ is_deleted }));
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) setListPaymentChannel(res.payload.data);
    dispatch(setLoading(false));
  };

  const handleDeleteChannel = async (channel_id: string) => {
    try {
      const res: any = await dispatch(deletePaymentChannel({ data: { channel_id: channel_id, } }));
      if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
        handleListChannel();
        success(
          "Xoá kênh thanh toán thành công",
          `Bạn vừa xoá thành công kênh thanh toán ${selectedChannel.name}.`
        );
      } else
        error(
          "Xoá kênh thanh toán thất bại",
          ERROR_CODE[res?.payload?.error_code] || res?.payload?.msg
        );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <PChannelTable
        data={listPaymentChannel}
        setShowQR={setShowQR}
        setShowDeleteModal={setShowDeleteModal}
        setShowEditModal={setShowEditModal}
        setShowDetailModal={setShowDetailModal}
        setSelectedChannel={setSelectedChannel}
        handleListChannel={handleListChannel}
        isDeleted={isDeleted}
      />
      {showQR && (
        <div className="no-scrollbar fixed inset-0 z-50 overflow-auto bg-[#000000B2]">
          <ShowQR setShowQR={setShowQR} selectedChannel={selectedChannel} />
        </div>
      )}
      {showDetailModal && (
        <div className="no-scrollbar fixed inset-0 z-50 overflow-auto bg-[#000000B2]">
          <DetailChannel setShow={setShowDetailModal} selectedChannel={selectedChannel} />
        </div>
      )}
      {showEditModal && (
        <FrmUpdatePChannel
          handleListChannel={handleListChannel}
          selectedChannel={selectedChannel}
          setShowEditModal={setShowEditModal}
        />
      )}
      {showDeleteModal && (
        <DeleteForm
          isOpen={showDeleteModal}
          onClose={setShowDeleteModal}
          handleAction={handleDeleteChannel}
          title="Xoá kênh thanh toán"
          desc={`Bạn có chắc chắn muốn xoá kênh thanh toán ${selectedChannel.name}?`}
          item={selectedChannel}
        />
      )}
    </>
  );
};

export default Payment;
