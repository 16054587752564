import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "hooks/appHook";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { toastAddBankSuccess } from "lib/constants";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyChannel } from "store/actions/paymentChannelAction";
import { verifyBankWithOtp } from "store/actions/verifyBankWithOtpAction";
import { selectBankAccount } from "store/reducers/verifyBankSlice";
import {
  selectIsOtpTextFail,
  selectOtpLoading,
  setIsTextOtpFail,
} from "store/reducers/verifyBankWithOtpSlice";
import InputOtp from "views/admin/bank/step2/ocb/InputOtp";
import "../../../../../assets/css/ModalCustom.css";
import BtnSend from "./BtnSend";

interface IProps {
  onClose: any;
  isOpen: boolean;
  channel?: any;
}
const ModalOTP: React.FC<IProps> = ({ onClose, isOpen, channel }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectOtpLoading);
  const bankAccount = useAppSelector(selectBankAccount);
  const isTextFail = useAppSelector(selectIsOtpTextFail);
  const [channelId, setChannelId] = useState(channel?.id);
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [valueOTP, setValueOTP] = useState("");
  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const isOnboard = useLocation().pathname.split('/')[1] == 'register'

  const handleVerifyBank = async () => {
    try {
      const res: any = await dispatch(verifyBankWithOtp({ ...bankAccount, otp: valueOTP }))
      if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
        handleClose();
        toastAddBankSuccess({
          bank_code: bankAccount?.bank_code,
          bank_account_no: bankAccount?.bank_account_no,
        })
        if (isOnboard) navigate('/register/add-va');
        else navigate('/admin/bank')
      }
    } catch (err) { console.log(err) }
  };

  const handleVerifyChannel = async () => {
    try {
      const res: any = await dispatch(verifyChannel({ channel_id: channelId, otp: valueOTP }))
      if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
        handleClose();
        CustomToastMessage.success(
          "Thêm kênh thanh toán thành công",
          `Bạn vừa thêm thành công kênh thanh toán ${channel?.payload?.name}.`
        );
        if (isOnboard) navigate(`/register/transfer-va/${channel.id}`)
        else navigate(`/admin/payment/qr/${channel.id}`);
      } else dispatch(setIsTextOtpFail(true))
    } catch (err) { console.log(err) }
  };

  const handleClose = () => {
    setIsTimeout(false);
    dispatch(setIsTextOtpFail(false));
    setValueOTP("");
    onClose();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent style={{ margin: 0, minWidth: 'min-content' }}>
        <ModalCloseButton />
        <div className="p-[40px] font-inter md:p-12 space-y-8">
          <h4 className="font-bold text-[#0F1D40] text-2xl">Xác thực</h4>
          <div style={{ gridTemplateColumns: '1.1fr 1fr' }} className="grid gap-[70px] p-6 rounded-2xl border border-[#D9D9D9] w-[900px]">
            <div className="space-y-4">
              <p className="text-base leading-5 font-bold">SMS OTP</p>
              <p className="">Nhập mã OTP gồm có 6 số đã được gửi tới số điện thoại
                <span className="font-semibold"> 090807096095. </span>
                Vui lòng kiểm tra để lấy mã OTP.
              </p>
              <InputOtp setValueOTP={setValueOTP} disabled={isLoading} />
              <div className="space-y-2">
                <div className="flex space-x-2 text-[14px] font-normal leading-5">
                  {isTextFail
                    ? <span className='text-[#ED1C24]'>Mã OTP không chính xác.</span>
                    : <span className="text-[#262626]">Bạn chưa nhận được mã?</span>
                  }
                  <BtnSend
                    setIsTimeout={setIsTimeout}
                    channel={channel}
                    setChannelId={setChannelId}
                  />
                </div>
                <p className="text-xs leading-5 italic text-primary font-normal">
                  <span className="font-semibold">Lưu ý: </span>
                  mã OTP có hiệu lực trong vòng 2 phút. Sau 2 phút chọn
                  <span className="font-semibold"> "Gửi lại OTP" </span>
                  để nhận mã OTP mới
                </p>
              </div>
              <button
                onClick={() => channel ? handleVerifyChannel() : handleVerifyBank()}
                disabled={valueOTP.length < 6 || isLoading || isTimeout}
                className={`
                ${valueOTP.length < 6 || isLoading || isTimeout
                    ? "opacity-50"
                    : ""
                  } !mt-8 w-full rounded-[4px] bg-primary px-8 py-3 text-[14px] font-semibold text-[#fff] transition`}
              >
                {isLoading ? "Đang xác nhận OTP..." : "Xác nhận OTP"}
              </button>
            </div>

            <div className="space-y-4">
              <h3 className="text-base leading-5 font-bold">Hướng dẫn</h3>
              <div className="space-y-2">
                <p className="text-sm font-normal">
                  <span className="font-semibold">Bước 1: </span>
                  Điền các thông tin cần thiết để tạo VA đầu tiên trên UNIPAYS. Sau đó, chọn nút
                  <span className="font-semibold"> “Lấy mã OTP”</span>
                </p>
                <p className="text-sm font-normal">
                  <span className="font-semibold">Bước 2: </span>
                  Mã OTP sẽ được gửi về số điện thoại đã nhập. Kiểm tra và nhập mã OTP. Sau đó, chọn nút
                  <span className="font-semibold"> “Xác thực OTP”</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ModalOTP;
