import { LocalStorage } from "lib/LocalStorage";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRouter = () => {
  const location = useLocation();
  const queryToken = new URLSearchParams(location.search).get("authorization");
  const queryRefreshToken = new URLSearchParams(location.search).get(
    "refresh_token"
  );
  const access_token = LocalStorage.getAccessToken();

  function checkRouteFromRegister() {
    const routeRegister = document.referrer;
    // console.log('>>> check host: ', routeRegister)
    return routeRegister?.includes(process.env.REACT_APP_REDIRECT_REGISTER);
  }

  const checkAccount = () => {
    if (
      location.pathname === "/register/add-bank" &&
      queryToken &&
      queryRefreshToken &&
      checkRouteFromRegister()
    ) {
      LocalStorage.setToken(queryToken);
      LocalStorage.setRefreshToken(queryRefreshToken);
      return true;
    } else return Boolean(access_token)
  };

  return checkAccount() ? <Outlet /> : <Navigate to="/auth/signin" />;
};

export default ProtectedRouter;
