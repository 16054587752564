import { cn } from "lib/utils";

interface Props {
  title: string;
  type?: "submit" | "reset" | "button";
  className?: string;
  small?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  outline?: boolean;
  icon?: any;
}

const Button = (props: Props) => {
  const { title, outline, type, onClick, className, small, disabled, icon } =
    props;

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={cn(
        "flex min-h-[44px] items-center justify-center gap-[10px] whitespace-nowrap rounded border border-primary text-center text-[14px]",
        disabled ? "opacity-50" : "hover:opacity-75",
        outline ? "bg-white text-primary" : "bg-primary text-white",
        small ? "px-4 py-3 font-medium" : "px-4 py-3 font-semibold md:px-8",
        className
      )}
    >
      {icon && <img src={icon} alt="icon" width={16} />}
      {title}
    </button>
  );
};

export default Button;
