// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-tippy-root] {
  width: 100% !important;
}

.chakra-modal__content.css-pv22qu {
  /* max-width: 453px !important; */
  margin: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/ModalCustom.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,iCAAiC;EACjC,oBAAoB;AACtB","sourcesContent":["[data-tippy-root] {\n  width: 100% !important;\n}\n\n.chakra-modal__content.css-pv22qu {\n  /* max-width: 453px !important; */\n  margin: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
