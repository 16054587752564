import authImg from "assets/img/auth/auth.png";
import { Outlet } from "react-router-dom";
import atomVam from "../../assets/img/auth/atom-vam-login.png";
import atomLogin from "../../assets/img/auth/atom-login.png";
import SelectLanguage from "components/dropdown/SelectLanguage";
import useWindowSize from "hooks/useWindowSize";
import Footer from "components/footer";

export default function Auth() {
  const { width } = useWindowSize();
  return (
    <div
      style={{
        backgroundImage: `url(${authImg})`,
        backgroundSize: `calc(100% - ${width >= 992 ? "606px" : "0px"}) 100%`,
      }}
      className="relative flex h-screen w-full justify-center bg-no-repeat font-inter lg:justify-end"
    >
      <div className="absolute inset-0 flex flex-col items-center justify-between px-0 py-6 lg:py-10 lg:pl-[60px] lg:pr-8">
        <div className="flex w-[92.27%] max-w-[606px] items-center justify-between lg:w-full lg:max-w-full">
          <img className="h-12 w-auto md:h-16" src={atomVam} alt="unipay" />
          <SelectLanguage disabled />
        </div>
        {/* <div className="hidden w-full items-center gap-1 lg:flex">
          <p className="text-[13.23px] font-medium leading-[17.64px] text-[#0F1D40]">
            Powered by
          </p>
          <img
            className="relative z-[1] w-[59px] object-cover"
            src={atomLogin}
            alt="atom"
          />
        </div> */}
      </div>
      <div
        className="mt-[200px] flex h-[min-content] w-[92.27%] max-w-[606px] rounded-lg border border-[#FFFFFF99] 
        bg-[#FFFFFF3D] p-4 backdrop-blur-[100px] lg:mt-0 lg:h-full lg:items-center lg:bg-white lg:px-[100px]"
      >
        <Outlet />
      </div>
      <div className="absolute bottom-0 right-0 flex w-full justify-center bg-white lg:w-[606px]">
        <Footer hideBorder />
      </div>
    </div>
  );
}
