// themes/stepper.ts

const baseStyle = {
    // select the indicator part
    stepper: {
        // change the default border radius to 0
        borderRadius: 0,
        
    },
    separator: {
        backgroundColor: '#D9D9D9',
        "&[data-status=complete]": {
            background: "#6666FF"
        }
    },
    indicator: {
        backgroundColor: '#D9D9D9',
        border: 'none',
        color: '#fafafa',
        "&[data-status=complete]": {
            background: "#6666FF"
        }
    }
}

export const stepperTheme = {
    baseStyle,
}
