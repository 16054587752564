type Props = {
  subTitle?: string;
  title?: string;
  onClickTitle?: () => void
};

const HeaderTitle = ({ subTitle, title, onClickTitle }: Props) => {
  return (
    <div className="w-full flex flex-col">
      {title && (
        <div onClick={onClickTitle} className={`font-inter text-base font-medium text-primary w-fit ${onClickTitle && 'cursor-pointer hover:opacity-60'}`}>
          {title}
        </div>
      )}
      {subTitle && (
        <div className="font-inter text-2xl font-bold text-black-highlight">
          {subTitle}
        </div>
      )}
    </div>
  );
};

export default HeaderTitle;
