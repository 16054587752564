import HeaderTitle from "components/header-title/HeaderTitle";
import Search from "components/search";
import { useState } from "react";
import SupportDropdown from "./SupportDropdown";
import { useProfile } from "hooks/useProfile";
import DigibizQnA from "./qna/DigibizQnA";
import DigimiQnA from "./qna/DigimiQnA";
import useDebounce from "hooks/useDebounce";

const Support = () => {
  const [searchText, setSearchText] = useState("");
  const { data } = useProfile();
  const debounceSearchText = useDebounce(searchText, 500);

  return (
    <div className="flex w-full flex-col gap-y-5 md:mx-auto">
      <HeaderTitle subTitle="Hỗ trợ" />
      <Search
        id="support_search"
        searchValue={searchText}
        setSearchValue={setSearchText}
        extraClass="bg-white border border-[#E8E8E8] rounded-xl p-4 shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)]"
        iconClass="!h-5 !w-5"
        placeholder="Chúng tôi có thể hỗ trợ gì cho bạn"
        inputClass="!placeholder:text-[#8C8C8C] font-medium py-0"
      />
      {data?.data?.account_type === "BUSINESS" ? (
        <DigibizQnA searchText={debounceSearchText} />
      ) : (
        <DigimiQnA searchText={debounceSearchText} />
      )}
    </div>
  );
};

export default Support;
