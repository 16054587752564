import { Dispatch } from "@reduxjs/toolkit";
import {
  getIntegrationFailed,
  getIntegrationStart,
  getIntegrationSuccess,
  initIntegrationFailed,
  initIntegrationStart,
  initIntegrationSuccess,
  submitIntegrationFailed,
  submitIntegrationStart,
  submitIntegrationSuccess,
} from "store/reducers/integrationSlice";
import { integrationService } from "store/services/intergrationService";

export const initKeyIntegration = async (dispatch: Dispatch) => {
  dispatch(initIntegrationStart());
  try {
    const rs = await integrationService.initKey();
    dispatch(initIntegrationSuccess(rs.data));
  } catch (error) {
    dispatch(initIntegrationFailed());
  }
};

export const getKeyIntegration = async (dispatch: Dispatch) => {
  dispatch(getIntegrationStart());
  try {
    const rs = await integrationService.getKey();
    dispatch(getIntegrationSuccess(rs.data));
  } catch (error) {
    dispatch(getIntegrationFailed());
  }
};

export const submitIntegration = async (
  dispatch: Dispatch,
  param: { callback_url: string }
) => {
  dispatch(submitIntegrationStart());
  try {
    await integrationService.submitIntegration(param);
    dispatch(submitIntegrationSuccess());
  } catch (error) {
    dispatch(submitIntegrationFailed());
  }
};
