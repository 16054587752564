// Custom components
import { WrapItem } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import { LocalStorage } from "lib/LocalStorage";
import { handleToastErr } from "lib/utils";
import { useState } from "react";
import { LuCopy } from "react-icons/lu";
import IconInfo from "assets/icon/icon-info.svg";

function InputField(props: {
  id: string;
  label?: string;
  extra?: string;
  placeholder?: string;
  variant?: string;
  state?: string;
  readOnly?: boolean;
  disabled?: boolean;
  checked?: boolean;
  name: string;
  type?: string;
  copy?: boolean;
  register?: any;
  isRequired?: boolean;
  errors?: any;
  value?: string;
  maxLength?: number;
  getValues?: any;
  onClick?: () => void;
  onChange?: any;
  defaultValue?: string;
  info?: string;
  tooltip?: string;
}) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    readOnly,
    disabled,
    checked,
    copy,
    name,
    register,
    isRequired,
    errors,
    value,
    maxLength,
    getValues,
    onClick,
    onChange,
    defaultValue = "",
    info,
    tooltip,
  } = props;

  const [copyStatus, setCopyStatus] = useState("Copy");

  const handleCopy = () => {
    if (name === "link") {
      navigator.clipboard.writeText(
        // `${getValues("link")}?share=${getValues(
        //   "sharecode"
        // )}&access_token=${accessToken}`
        getValues("link")
      );
      setCopyStatus("Copied");
    } else {
      navigator.clipboard.writeText(getValues(name));
      setCopyStatus("Copied");
    }
  };

  return (
    <div className={`${extra} font-inter`}>
      {label && (
        <div
          className={`flex ${
            variant === "auth" || "telegram" ? "" : "space-x-1"
          } mb-2 `}
        >
          <div className="flex items-center text-sm font-semibold">
            <label
              htmlFor={id}
              className={`${
                ["auth", "account", "telegram"].includes(variant)
                  ? "text-[#262626]"
                  : "text-[#505B76]"
              }`}
            >
              {label}
            </label>
            {isRequired && <span className="text-red-error">*</span>}
            {info && (
              <Tooltip
                hasArrow
                label={info}
                placement="top"
                bg={"#262626"}
                color={"#FFFFFF"}
                padding={"6px 12px"}
                rounded={"8px"}
                maxWidth={280}
                textAlign={"center"}
                fontSize={12}
                fontWeight={400}
                fontFamily={"Montserrat"}
              >
                <img src={IconInfo} alt="icon" className="ml-1 size-4" />
              </Tooltip>
            )}
          </div>
          {variant === "telegram" && !isRequired && (
            <span className="ml-1 text-[14px] leading-[20px] text-[#262626]">
              {"  "}
              (Không bắt buộc)
            </span>
          )}
        </div>
      )}
      <div className="relative">
        <Tooltip
          hasArrow
          label={tooltip}
          placement="top"
          bg={"#262626"}
          color={"#FFFFFF"}
          padding={"6px 12px"}
          rounded={"8px"}
          maxWidth={280}
          textAlign={"center"}
          fontSize={12}
          fontWeight={400}
          fontFamily={"Montserrat"}
        >
          <input
            defaultValue={defaultValue}
            checked={checked}
            onClick={onClick}
            onChange={(e) => onChange(e.target.value)}
            maxLength={maxLength}
            readOnly={readOnly}
            disabled={disabled}
            {...register(name, {
              required: {
                value: isRequired,
                message: handleToastErr(id),
              },
            })}
            name={name}
            type={type}
            value={value}
            id={id}
            placeholder={placeholder}
            onKeyDown={(evt) => {
              type === "number" &&
                ["e", "E", "+", ".", ",", "-"].includes(evt.key) &&
                evt.preventDefault();
              if (type == "prefix" && !/^[a-zA-Z0-9]+$/.test(evt.key))
                evt.preventDefault();
            }}
            onPaste={(e) => {
              const pastedContent = e.clipboardData.getData("Text");
              type == "prefix" &&
                !/^[a-zA-Z0-9]+$/.test(pastedContent) &&
                e.preventDefault();
            }}
            onWheel={(event) => type === "number" && event.currentTarget.blur()}
            className={`flex w-full items-center justify-center rounded-lg border border-[#E8E8E8] p-3 text-[14px] text-sm leading-[20px] text-[#0F1D40] outline-none md:bg-white ${
              disabled === true
                ? "!border-none !bg-gray-100"
                : errors[name] || state == "error"
                ? "border-red-error text-red-error placeholder:text-red-error"
                : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500"
                : "border-gray-200"
            } ${type === "radio" ? "h-auto cursor-pointer" : "h-11"} truncate`}
          />
        </Tooltip>
        {copy && (
          <WrapItem>
            <Tooltip
              label={copyStatus}
              placement="top"
              hasArrow
              closeOnClick={false}
            >
              <button
                onClick={() => {
                  handleCopy();
                }}
                type="button"
                className="absolute right-[16px] top-[35%] h-[16px] w-[16px] text-[#505B76] active:text-[#182035]"
              >
                <LuCopy />
              </button>
            </Tooltip>
          </WrapItem>
        )}
      </div>
      {errors[name] && errors[name].message && (
        //Shows if error exist
        <p className="mt-2 text-xs font-normal text-red-error">
          {errors[name].message}
        </p>
      )}
    </div>
  );
}

export default InputField;
