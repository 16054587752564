import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

interface ISocket {
    endpoint?: string
    event: string
    start: boolean
    handleEvent?: (data: any) => void
}

const useWebSocket = ({ endpoint = '', event, start, handleEvent }: ISocket) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (start) {
            const socket = io(process.env.REACT_APP_SOCKET);
            socket.on("connect", () => {
                console.log("Connected to the Socket server with event: ", event);
                // Remove previous event listener when re-focus the website
                socket.off(event);
                // Get notification
                socket.on(event, async (data: any) => {
                    setData(data)
                    handleEvent(data)
                });
            });
            // Event handler for disconnection
            socket.on("disconnect", () =>
                console.log("Disconnected from the Socket server")
            );
            // Event handler for errors
            socket.on("error", (e) => console.log("Socket error:", e));
            // Clean up the socket connection when the component is unmounted
            return () => {
                socket.disconnect();
            };
        }
    }, [endpoint, event, start]);

    return { data };
};

export default useWebSocket;
