import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import QrDigimi from "assets/img/bank/qr-download-digimi.png";
import Button from "components/button/button";
import { useProfile } from "hooks/useProfile";
import React, { useState } from "react";

import "assets/css/ModalCustom.css";

import ImgDigibiz from "assets/img/bank/digibiz.png";
import ImgDigimi from "assets/img/bank/digimi.png";
import vccb from "assets/img/bank/vccb.png";
import { BtnClose } from "components/button/actionButton";
import { checkRegister } from "store/actions/bankAction";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";
import { BANK_NAME_SHORT } from "lib/constants";
import { useAppDispatch } from "hooks/appHook";
import { useNavigate } from "react-router-dom";

interface IProps {
  onClose: any;
  isOpen: boolean;
  setOpenQr: any
}

const CreateAccountBVB: React.FC<IProps> = ({ onClose, isOpen, setOpenQr }) => {
  const bank_code = "VCCB";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { data: profile } = useProfile();
  const accountType = profile?.data?.account_type;
  const isBusiness = accountType == "BUSINESS";
  const [isClickLink, setIsClickLink] = useState(false);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent style={{ width: 390, borderRadius: 16 }}>
        <BtnClose onClick={() => onClose()} />
        <div className="flex w-[390px] flex-col items-center rounded-2xl bg-white px-6 py-8 font-inter text-[#0A203D]">
          {/* Title + Desc */}
          <div className="space-y-4 text-center">
            <h1 className="text-xl font-bold">
              {isClickLink ? (
                "Đợi xét duyệt"
              ) : (
                <div className="flex flex-col items-center gap-1">
                  Mở tài khoản khách hàng tại
                  <img src={vccb} alt="bvbank" className="h-6 w-auto" />
                </div>
              )}
            </h1>
            {isClickLink ? (
              <p className="text-sm font-bold text-primary">
                Hồ sơ mở tài khoản doanh nghiệp của bạn đang được xét duyệt.
                BVBank - Ngân hàng Bản Việt sẽ liên hệ để hoàn thành các thủ tục hậu kiểm
                trong thời gian 1-2 tiếng sắp tới.
              </p>
            ) : (
              <p className="text-sm font-normal">
                {isBusiness
                  ? "Truy cập vào liên kết phía dưới để mở tài khoản Doanh nghiệp."
                  : "Chọn Quét mã QR. Di chuyển camera đến vùng chứa mã QR bên dưới."}
              </p>
            )}
          </div>

          {/* Body */}
          {isBusiness ? (
            <div className="mt-10 flex flex-col gap-2">
              {!isClickLink && (
                <a
                  onClick={() => setIsClickLink(true)}
                  target="_blank"
                  href="https://bvbank.net.vn/tkdn/register-online-corp/step1"
                  className="text-xl font-bold text-primary underline"
                >
                  Liên kết
                </a>
              )}
              <img src={ImgDigibiz} alt="digibiz" className="h-8 w-auto" />
            </div>
          ) : (
            <div className="mt-8 flex w-full flex-col items-center gap-8">
              {/* <Skeleton height={220} width={220}>
              </Skeleton> */}
              <img
                src={QrDigimi}
                alt="qr"
                className="h-[220px] w-auto"
              />
              <img src={ImgDigimi} alt="digimi" className="h-8 w-auto" />
            </div>
          )}

          {/* Button */}
          {(!isBusiness || isClickLink) && (
            <Button
              className="mt-8 w-full !px-0"
              title="Đã hoàn tất mở tài khoản"
              onClick={async () => {
                const res: any = await dispatch(checkRegister({ bank_code }));
                if (res?.payload?.data) {
                  navigate(`step2?type=${BANK_NAME_SHORT[bank_code]}`);
                } else
                  CustomToastMessage.error(
                    "Thất bại",
                    ERROR_CODE[res?.payload?.error_code]
                  );
                if (
                  [
                    "CUSTOMER_NOT_FOUND_VCCB",
                    "ACCOUNT_CLOSED_UNREGISTERED",
                  ].includes(res?.payload?.error_code)
                )
                  setOpenQr("download");
              }}
            />
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CreateAccountBVB;
