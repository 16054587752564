import { useMemo, useState } from "react";
import SupportDropdown from "../SupportDropdown";
import questions from "../../../../../assets/json/digibiz.json";

type DigibizQnAProps = {
  searchText: string;
};

const DigibizQnA = ({ searchText }: DigibizQnAProps) => {
  const [curDropdown, setCurDropdown] = useState<number | null>(null);
  const onToggle = (idx: number) => {
    if (idx === curDropdown) setCurDropdown(null);
    else setCurDropdown(idx);
  };
  const filteredQuestions = useMemo(() => {
    return questions.question.filter((ques) => {
      return ques.title.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [searchText]);

  return (
    <div className="rounded-xl border border-[#E8E8E8] bg-white p-6 shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)]">
      <div className="custom-scrollbar flex max-h-[400px] flex-col gap-y-4 overflow-auto">
        {filteredQuestions.map((ques, index) => (
          <SupportDropdown
            idx={index}
            title={ques.title}
            noti={ques.noti}
            children={ques.answer}
            showDropdown={curDropdown === index}
            onToggle={onToggle}
          />
        ))}
      </div>
    </div>
  );
};

export default DigibizQnA;
