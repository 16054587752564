import SwitchTab from "components/switch-tab";
import Table from "components/table";
import { useAppDispatch } from "hooks/appHook";
import { formatDate } from "lib/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getTransacions } from "store/actions/transactionAction";
import { setLoading } from "store/reducers/loadingSlice";
import { Button, HeaderTitle } from "../../integrate/vam";
import { Tooltip } from "@chakra-ui/tooltip";
import icWallet from "assets/icon/icon-wallet.png";
import Filter from "components/filter";
import icCashInflow from "../../../../assets/icon/icon-cash-inflow.svg";
import icCashOutflow from "../../../../assets/icon/icon-cash-outflow.svg";
import AccountInfoModal from "../components/AccountInfoModal";
import { TAccountInfo, TFilter } from "types/txn";
import { getListPaymentChannel } from "store/actions/paymentChannelAction";
import { useControlQueryParams } from "hooks/useControlQueryParams";
import { defaultFilter } from "constants/txn.constant";
import { convertCsvToXlsx } from "lib/csvToXlsx";
import { transactionService } from "store/services/transactionService";
import { CustomToastMessage } from "lib/CustomToastMessage";

export const BankCell = ({ logo, text }: { logo: string; text: string }) => {
  return (
    <div className="flex w-[max-content] items-center justify-end gap-2 md:justify-start">
      <img src={logo} alt="bank_logo" className="h-6 w-auto" />
      <div className="h-[14px] w-px bg-[#8991A3]" />
      <p className="text-sm text-[#0F1D40] ">{text}</p>
    </div>
  );
};

const ActiveChannel = () => {
  const { setValue, watch } = useForm();

  const [filters, setFilters] = useState<TFilter>(defaultFilter);

  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);

  const page = useSelector((state: any) => state.page.page);
  const pageSize = useSelector((state: any) => state.page.page_size);
  const [totalPage, setTotalPage] = useState(0);
  const [totalAmount, setTotalAmount] = useState();
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [accountList, setAccountList] = useState<TAccountInfo[]>([]);

  const { setParams } = useControlQueryParams();

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const drcr = params.get("drcr");

  const getPaymentChannel = async () => {
    const res: any = await dispatch(
      getListPaymentChannel({ is_deleted: false })
    );
    setAccountList(
      res?.payload?.data?.map((item: any) => {
        return {
          bank_logo: item?.bank_logo,
          bank_account_no: item?.bank_account_no,
          account_balance: "100000",
        };
      })
    );
  };

  React.useEffect(() => {
    getPaymentChannel();
  }, []);

  const getTransaction = async (params?: any) => {
    dispatch(setLoading(true));
    const res: any = await dispatch(
      getTransacions({
        page: page,
        limit: pageSize,
        drcr: watch("drcr"),
        bank_account_no: params?.bank_account_no,
        va_account_no: params?.va_account_no,
        start_time: params?.start_time,
        end_time: params?.end_time,
        is_export: params?.is_export,
      })
    );
    if (res.meta.requestStatus === "fulfilled" && res.payload) {
      setData(res.payload.data.data);
      setTotalPage(res?.payload.data.max_page);
      setTotalAmount(res.payload.data.total_amount);
    } else setData([]);
    dispatch(setLoading(false));
  };

  const handleResetFilter = () => {
    getTransaction({
      bank_account_no: defaultFilter?.bank_account_no || null,
      va_account_no: defaultFilter?.channel || null,
      start_time: defaultFilter?.start_time || null,
      end_time: defaultFilter?.end_time || null,
    });
  };

  const handleApplyFilter = (filters: TFilter) => {
    getTransaction({
      bank_account_no: filters?.bank_account_no || null,
      va_account_no: filters?.channel || null,
      start_time: filters?.start_time || null,
      end_time: filters?.end_time || null,
    });
  };

  const handleExport = async () => {
    try {
      const response = await transactionService.getTransacions({
        drcr: watch("drcr"),
        bank_account_no: filters?.bank_account_no || null,
        va_account_no: filters?.channel || null,
        start_time: filters?.start_time || null,
        end_time: filters?.end_time || null,
        is_export: true,
      });
      convertCsvToXlsx(response);
      CustomToastMessage.success(
        "Xuất báo cáo thành công",
        "Bạn vừa thực hiện xuất báo cáo giao dịch thành công."
      );
    } catch (err) {
      CustomToastMessage.error("Xuất báo cáo thất bại", "Lỗi không xác định.");
    }
  };

  useEffect(() => {
    getTransaction({
      bank_account_no: filters?.bank_account_no || null,
      va_account_no: filters?.channel || null,
      start_time: filters?.start_time || null,
      end_time: filters?.end_time || null,
    });
  }, [pageSize, page, watch("drcr")]);

  const columns = useMemo(
    () => [
      {
        Header: "Số tài khoản",
        accessor: "bank_account_no",
        Cell: ({ row }: any) => (
          <BankCell
            logo={row.original.bank_logo}
            text={row.original.bank_account_no}
          />
        ),
      },
      {
        Header: "Tên kênh thanh toán",
        accessor: "channel_name",
        Cell: ({ value }: any) => (
          <Tooltip
            hasArrow
            label={value}
            placement="top"
            bg={"#262626"}
            color={"#FFFFFF"}
            padding={"6px 12px"}
            rounded={"8px"}
            maxWidth={280}
            textAlign={"center"}
            fontSize={12}
            fontWeight={400}
            fontFamily={"Montserrat"}
          >
            <span className="line-clamp-1 max-w-[184px] text-sm text-[#0F1D40]">
              {value}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: "Tài khoản QR",
        accessor: "va_account_receiver",
        Cell: ({ row, value }: any) => (
          <p className="text-sm text-[#0F1D40] ">
            <span className="font-semibold text-primary">
              {row.original.prefix_va}
            </span>
            {value && value.slice(4)}
          </p>
        ),
      },
      {
        Header: "Số tiền (VND)",
        accessor: "amount",
        Cell: ({ value }: any) => {
          const moneyIn = watch("drcr") === "C";
          return (
            <div
              className={`flex items-center gap-4 text-sm font-medium ${
                moneyIn ? "text-[#2EB553]" : "text-red-error"
              }`}
            >
              {value.toLocaleString()}
            </div>
          );
        },
      },
      {
        Header: "Nội dung chuyển tiền",
        accessor: "description",
        Cell: ({ value }: any) => (
          <Tooltip
            hasArrow
            label={value}
            placement="top"
            bg={"#262626"}
            color={"#FFFFFF"}
            padding={"6px 12px"}
            rounded={"8px"}
            maxWidth={280}
            textAlign={"center"}
            fontSize={12}
            fontWeight={400}
            fontFamily={"Montserrat"}
          >
            <span className="line-clamp-1 max-w-[184px] text-sm text-[#0F1D40]">
              {value}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: "Thời gian giao dịch",
        accessor: "created_at",
        Cell: ({ value }: any) => (
          <span className="whitespace-nowrap text-sm text-[#0F1D40]">
            {formatDate(value)}
          </span>
        ),
      },
    ],
    []
  );

  return (
    <div className="flex flex-col gap-[24px] font-inter">
      <HeaderTitle subTitle="Kênh hoạt động" />
      <div className="flex flex-col justify-between gap-6 lg:flex-row lg:items-center">
        <SwitchTab
          options={[
            {
              label: "Báo có",
              value: "C",
            },
            {
              label: "Báo nợ",
              value: "D",
            },
          ]}
          defaultOption={
            drcr === "D"
              ? {
                  label: "Báo nợ",
                  value: "D",
                }
              : {
                  label: "Báo có",
                  value: "C",
                }
          }
          handleAction={(value: string) => {
            setValue("drcr", value);
            setParams({ drcr: value });
          }}
        />
        <Button
          icon={icWallet}
          title="Xem số dư"
          outline
          onClick={() => setShowAccountInfo(true)}
        />
      </div>
      <Filter
        handleResetFilter={handleResetFilter}
        handleApplyFilter={handleApplyFilter}
        statusOptions={[]}
        getTransaction={getTransaction}
        setValue={setValue}
        watch={watch}
        filters={filters}
        setFilters={setFilters}
        isDeletedChannel={false}
      />

      <Table
        columns={
          watch("drcr") === "C"
            ? columns
            : columns.filter(
                (col) =>
                  !["Tên kênh thanh toán", "Tài khoản QR"].includes(col.Header)
              )
        }
        data={data}
        totalPage={totalPage}
        row={true}
        totalAmount={totalAmount}
        handleExport={handleExport}
      />

      <AccountInfoModal
        isOpen={showAccountInfo}
        onClose={() => setShowAccountInfo(false)}
      />
    </div>
  );
};

export default ActiveChannel;
