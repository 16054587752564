import { Skeleton } from "@chakra-ui/react";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { useEffect } from "react";
import {
  initKeyIntegration,
  submitIntegration,
} from "store/actions/integrationAction";
import * as yup from "yup";
import {
  BoxForm,
  Button,
  IconCopy,
  IconInfo,
  Tooltip,
  getKeyIntegration,
  useAppDispatch,
  useAppSelector,
  useForm,
  yupResolver,
} from "./index";

type TypeFormField = {
  ipn_url?: string;
  private_key?: string;
  public_key?: string;
  aes_key?: string;
};

const schema = yup.object({
  ipn_url: yup.string().required("Vui lòng nhập IPN URL").url('Vui lòng nhập IPN URL hợp lệ').trim(),
  private_key: yup.string().required(),
  public_key: yup.string().required(),
  aes_key: yup.string().required('Vui lòng Gen Key'),
});

const Label = ({
  label,
  required,
  info,
}: {
  label: string;
  required?: boolean;
  info?: string;
}) => {
  return (
    <p className="flex font-inter text-sm font-semibold leading-tight text-black-highlight">
      <span className="flex items-center gap-1 text-black-highlight">
        {label}{" "}
        {info && (
          <Tooltip
            hasArrow
            label={info}
            placement="top"
            bg={"#262626"}
            color={"#FFFFFF"}
            padding={"6px 12px"}
            rounded={"8px"}
            maxWidth={230}
            textAlign={"center"}
            fontSize={12}
            fontWeight={400}
            fontFamily={"Montserrat"}
          >
            <img src={IconInfo} alt="icon" className="size-4" />
          </Tooltip>
        )}
      </span>
      {required && <span className="text-[#F5222D]">*</span>}
    </p>
  );
};

const InputField = ({
  propsInput,
  label,
  info,
  onCopy,
  error,
  isLoading,
}: {
  label?: string;
  info?: string;
  error?: string;
  onCopy?: () => void;
  propsInput: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  isLoading?: boolean;
}) => {
  return (
    <label className="flex w-full flex-col gap-2" htmlFor={propsInput.id}>
      {label && (
        <Label label={label} info={info} required={propsInput.required} />
      )}
      <Skeleton className="h-[min-content]" isLoaded={!isLoading}>
        <div className="relative">
          <input
            id={propsInput.id}
            {...propsInput}
            style={{
              backgroundColor: propsInput.disabled ? "#E8E8E8" : "#fff",
              paddingRight: onCopy && "44px",
            }}
            className="w-full rounded-lg border border-[#E8E8E8] bg-white p-3 font-inter text-sm font-normal leading-tight text-[#0F1D40] placeholder:text-Text-Color-Text-Disabled"
          />
          {error && (
            <span className="absolute left-0 top-full text-sm text-red-600">
              {error}
            </span>
          )}
          {onCopy && (
            <div
              onClick={onCopy}
              className="absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer rounded-lg p-3 transition-colors hover:bg-gray-300"
            >
              <img src={IconCopy} alt="icon" className="size-4" />
            </div>
          )}
        </div>
      </Skeleton>
    </label>
  );
};

const FormContent = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(
    (state) => state.integration.getIntegration
  );
  const { data: dataInit, isLoading: loadingInit } = useAppSelector(
    (state) => state.integration.initIntegration
  );
  const { isLoading: isLoadingSubmit } = useAppSelector(
    (state) => state.integration.submitIntegration
  );
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<TypeFormField>({
    values: {
      ipn_url: dataInit?.callback_url,
      // private_key: dataInit?.private_key,
      // public_key: dataInit?.public_key,
      aes_key: dataInit?.aes_key,
    },
    mode: "all",
    resolver: yupResolver(schema), // yup, joi and even your own.
  });

  const handleGetKey = async () => {
    await getKeyIntegration(dispatch);
  };

  const handleCopyValue = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  useEffect(() => {
    if (data) {
      setValue("private_key", data.private_key);
      setValue("public_key", data.public_key);
      setValue("aes_key", data.aes_key);
    }
  }, [data]);

  useEffect(() => {
    initKeyIntegration(dispatch);
  }, []);

  return (
    <form
      onSubmit={handleSubmit(async (d: any) => {
        try {
          await submitIntegration(dispatch, {
            callback_url: d.ipn_url,
          }).then(() => {
            CustomToastMessage.success(
              "Tích hợp thành công",
              "Bạn vừa tích hợp thành công tích hợp API VAM"
            );
          });
        } catch (error) {
          CustomToastMessage.error("Submit failed", "");
        }
      })}
      className="max-sm:gap-4 flex w-full max-w-[996px] flex-col gap-6 rounded-2xl bg-Text-Color-white p-8 shadow-vam-box-layout sm-max:p-4"
    >
      <BoxForm>
        <InputField
          label="IPN URL"
          propsInput={{
            ...register("ipn_url"),
            placeholder: "https://api.example.com/ipn/vam",
          }}
          isLoading={loadingInit}
          error={errors?.ipn_url?.message}
        />

        <div className="flex flex-col gap-4">
          <div className="flex gap-3">
            <div className="flex flex-1 flex-col gap-1">
              <Label label="Security Key" />
              <p className="font-inter text-sm font-normal leading-tight text-Text-Color-Text-Medium">
                Bấm vào nút “Gen Key” để tạo key mã hóa.
              </p>
            </div>

            <button
              disabled={isLoading}
              onClick={handleGetKey}
              className={
                "flex h-9 w-[107px] items-center justify-center rounded border border-primary text-center font-inter text-sm font-semibold leading-tight text-primary transition-colors hover:bg-primary hover:text-white" +
                (isLoading ? " cursor-progress" : " cursor-pointer")
              }
            >
              {isLoading ? "Loading..." : "Gen key"}
            </button>
          </div>

          <div className="flex gap-4 sm-max:flex-col">
            <InputField
              label="Key"
              info="Dùng để giải mã thông tin giao dịch nhận thông qua IPN URL."
              propsInput={{
                ...register("aes_key"),
                placeholder: "Key",
                disabled: true,
              }}
              error={errors?.aes_key?.message}
              onCopy={() => handleCopyValue(getValues("aes_key"))}
              isLoading={loadingInit || isLoading}
            />
            {/* <InputField
              label="Public Key"
              info="Dùng để giải mã thông tin giao dịch nhận thông qua IPN URL."
              propsInput={{
                ...register("public_key"),
                placeholder: "Public Key",
                disabled: true,
              }}
              error={errors?.private_key?.message}
              onCopy={() => handleCopyValue(getValues("private_key"))}
              isLoading={loadingInit || isLoading}
            /> */}
          </div>
        </div>
      </BoxForm>

      <BoxForm>
        <div className="font-inter text-lg font-bold text-black-highlight">
          Hướng dẫn
        </div>

        <div className="font-inter text-sm font-normal leading-tight">
          <span className="text-black-highlight ">
            Để chuẩn bị cho tích hợp Open API bạn cần điền “IPN URL” và nhấn nút
            “Gen Key” để lấy Key.
            <br />
          </span>
          <a href={process.env.REACT_APP_OPEN_API_ENDPOINT} target="_blank" className="cursor-pointer italic text-primary underline hover:font-medium">
            API tích hợp VAM.
          </a>
        </div>
      </BoxForm>
      <Button
        disabled={isLoadingSubmit}
        title={isLoadingSubmit ? "Loading..." : "Tích hợp"}
      />
    </form>
  );
};

export default FormContent;
