import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/fields/InputField";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "components/button/button";
import ViewImgBtn from "./ViewImgBtn";
import { errorReportSchema } from "./ErrorReportForm";
import StatusCell from "./StatusCell";
import ImgList from "./ImgList";
import { TErrorReport } from "types/support";

type ErrorDetailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  reportDetail: TErrorReport;
};

const ErrorDetailModal = ({
  isOpen,
  onClose,
  reportDetail,
}: ErrorDetailModalProps) => {
  const [isViewingImage, setIsViewingImage] = useState(false);
  const [curImg, setCurImg] = useState<string | null>(null);
  const [width, setWidth] = useState(20);
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(errorReportSchema),
    defaultValues: {
      payment_channel: reportDetail?.payment_channel_name || "",
      error_type: reportDetail?.error_type || "",
      desc: reportDetail?.description || "",
      error_image:
        typeof reportDetail?.images === "string"
          ? [reportDetail?.images]
          : reportDetail?.images || [],
      in_progress_desc: "Đang thực hiện kiểm tra giao dịch",
    },
  });
  const imgArr = watch("error_image") as string[];

  const handelViewImg = (e: React.MouseEvent<HTMLElement>, idx: number) => {
    e.preventDefault();
    setCurImg(imgArr[idx]);
    setIsViewingImage(true);
  };
  const handleCloseViewImg = () => {
    setIsViewingImage(false);
    setCurImg(null);
    setWidth(20);
  };

  useEffect(() => {
    setValue("payment_channel", reportDetail?.payment_channel_name || "");
    setValue("error_type", reportDetail?.error_type || "");
    setValue(
      "error_image",
      typeof reportDetail?.images === "string"
        ? [reportDetail?.images]
        : reportDetail?.images || []
    );
    // setValue("in_progress_desc", reportDetail?.in_progress_desc || "");
    setValue("desc", reportDetail?.description || "");
  }, [reportDetail]);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />

      <ModalContent
        style={{ margin: 0, minWidth: "min-content", borderRadius: "16px" }}
      >
        {isViewingImage && curImg && (
          <ViewImgBtn
            curImg={curImg}
            width={width}
            handleCloseViewImg={handleCloseViewImg}
            setWidth={setWidth}
          />
        )}
        <ModalCloseButton />
        <div className="flex w-[800px] flex-col gap-y-8 p-6">
          <h4 className="text-2xl font-bold text-[#0F1D40]">
            Chi tiết báo lỗi
          </h4>
          <div className="flex flex-col gap-y-4">
            <div className="flex items-center gap-x-6">
              <div className="flex-1">
                <InputField
                  name="payment_channel"
                  id="payment_channel"
                  label="Tên kênh thanh toán"
                  isRequired
                  readOnly
                  register={register}
                  errors={errors}
                  variant="account"
                />
              </div>
              <div className="flex-1">
                <InputField
                  name="error_type"
                  id="error_type"
                  label="Loại báo lỗi"
                  isRequired
                  readOnly
                  register={register}
                  errors={errors}
                  variant="account"
                />
              </div>
            </div>
            <InputField
              name="desc"
              id="desc"
              label="Mô tả"
              readOnly
              register={register}
              errors={errors}
              variant="account"
            />
            <ImgList imgArr={imgArr} handelViewImg={handelViewImg} />
            <div className="flex items-center gap-x-10">
              <p className="font-inter text-sm font-semibold text-[#262626]">
                Trạng thái
              </p>
              <StatusCell status={reportDetail?.status || "PENDING"} />
            </div>
            <InputField
              name="in_progress_desc"
              id="in_progress_desc"
              label="Mô tả đang xử lý"
              readOnly
              register={register}
              errors={errors}
              variant="account"
            />
          </div>
          <Button
            onClick={onClose}
            title="Đóng"
            outline
            type="button"
            className="w-full"
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ErrorDetailModal;
