import React, { useState } from 'react'
import { HeaderTitle, useAppDispatch } from '../vam'
import SwitchTab from 'components/switch-tab'
import add from "assets/svg/add-red.svg";
import AllDevices from '../device';
import IntegrateTelegram from '../telegram';
import { useDisclosure } from '@chakra-ui/hooks';
import DeviceForm from '../device/DeviceForm';
import { generateBinCode } from 'store/actions/deviceAction';
import { setCode } from 'store/reducers/codeSlice';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const IntegratePerChannel = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { va_account_no, bank_account } = useParams()
    const [searchParams] = useSearchParams()
    const [activeTab, setActiveTab] = useState(searchParams.get('redirect'))
    const {
        isOpen: isShareOpen,
        onOpen: openShareForm,
        onClose: closeShareForm,
    } = useDisclosure();
    const options = [
        {
            label: 'Tích hợp thiết bị',
            value: 'device'
        },
        {
            label: 'Tích hợp Telegram',
            value: 'telegram'
        },
    ]
    
    return (
        <div className="flex w-full flex-col gap-6 font-inter">
            <div className='flex items-center justify-between'>
                <HeaderTitle title="Kênh thanh toán" subTitle='Xem tích hợp' onClickTitle={() => navigate('/admin/payment')} />
                <button
                    onClick={() => {
                        if (activeTab == 'device') {
                            (async () => {
                                const res = await dispatch(generateBinCode({ va_account_no }));
                                if (res.meta.requestStatus === "fulfilled" && res.payload) {
                                    dispatch(setCode((res as any)?.payload.data));
                                } else {
                                    dispatch(setCode({}));
                                }
                                openShareForm()
                            })()
                        } else navigate(`/admin/payment/integrate-telegram/${va_account_no}/${bank_account}`)
                    }}
                    className="flex items-center gap-[8px] rounded border border-primary px-[16px] py-[8px] h-fit md:hidden justify-center"
                >
                    <img src={add} alt="add" />
                    <span className="text-sm font-semibold text-primary">Thêm</span>
                </button>
            </div>
           

            <div className="flex items-center justify-between">
                <SwitchTab options={options} handleAction={setActiveTab} defaultOption={searchParams.get('redirect') && options[1]}/>
                <button
                    onClick={() => {
                        if (activeTab == 'device') {
                            (async () => {
                                const res = await dispatch(generateBinCode({ va_account_no }));
                                if (res.meta.requestStatus === "fulfilled" && res.payload) {
                                    dispatch(setCode((res as any)?.payload.data));
                                } else {
                                    dispatch(setCode({}));
                                }
                                openShareForm()
                            })()
                        } else navigate(`/admin/payment/integrate-telegram/${va_account_no}/${bank_account}`)
                    }}
                    className="md:flex hidden items-center gap-[8px] rounded border border-primary px-[16px] py-[8px] h-fit"
                >
                    <img src={add} alt="add" />
                    <span className="text-sm font-semibold text-primary">Thêm</span>
                </button>
            </div>
            {activeTab == 'device'
                ? <AllDevices onlyTable />
                : <IntegrateTelegram onlyTable />
            }
            <DeviceForm isOpen={isShareOpen} onClose={closeShareForm} />

        </div>
    )
}

export default IntegratePerChannel