import { createAsyncThunk } from "@reduxjs/toolkit";
import { verifyBankWithOtpService } from "store/services/verifyBankWithOtpService";

export const verifyBankWithOtp = createAsyncThunk(
  "bank/verifyBankWithOtp",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await verifyBankWithOtpService.validateBankWithOtp(
        params
      );
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
