import React, { memo, useEffect, useRef } from "react";

const Step = ({
  data,
  currentTab,
  setCurrentTab,
  active,
  disabled,
}: {
  data: any;
  currentTab: any;
  setCurrentTab: any;
  active: number;
  disabled?: boolean;
}) => {
  const [currentStep, setCurrentStep] = React.useState(0);

  useEffect(() => {
    setCurrentStep(0);
  }, [data]);

  const handleNext = () => {
    if (
      currentStep < data?.step.length - 1 ||
      currentStep < data.step[currentTab].length - 1
    ) {
      setCurrentStep(currentStep + 1);
    } else {
      return;
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleChangeStep = (id: number) => {
    setCurrentStep(id);
  };

  const handleSetTab = (tab: any) => {
    setCurrentTab(tab.id);
  };

  const renderData = (list: any) => {
    return list.map((item: any, index: number) => {
      return (
        <div
          key={index}
          className={`relative z-10 flex gap-4 ${
            currentStep === index ? "items-start" : "items-center"
          } ${disabled && "pointer-events-none"}`}
        >
          <div
            onClick={() => handleChangeStep(index)}
            className={`${
              currentStep === index ? "bg-primary" : "bg-[#fbd2d3]"
            } relative z-10 flex size-10 cursor-pointer items-center justify-center rounded-full p-[10px]`}
          >
            <span
              className={`text-sm font-bold ${
                currentStep === index ? "text-white" : "text-primary"
              }`}
            >
              {index + 1}
            </span>
          </div>
          <div className="flex flex-col gap-3 flex-[1_0_0]">
            <h2
              onClick={() => handleChangeStep(index)}
              className={`${
                currentStep === index ? "text-primary" : "text-[#505B76]"
              }  cursor-pointer text-sm font-semibold`}
            >
              {item.title}
            </h2>
            {currentStep === index && <div>{item.content}</div>}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col items-center gap-[36px]">
      <span className="text-[12px] font-medium italic leading-[20px] text-[#0B1126]">
        {data.description}
      </span>
      {data.information && (
        <article className={`flex flex-col items-center gap-[10px]`}>
          <div className="flex flex-col items-center gap-1">
            <span className="text-[14px] font-semibold leading-[20px] text-primary">
              Thông tin danh sách <span className="lowercase">{data.tab}</span>{" "}
              hiển thị bao gồm:
            </span>
            <ul
              className={` ${
                data.information?.length > 3
                  ? "mx-auto grid w-[941px] grid-cols-2"
                  : "mr-auto flex w-fit flex-col items-center justify-start"
              }   `}
            >
              {data.information.map((item: any, index: number) => {
                return (
                  <li
                    key={index}
                    className={`flex ${
                      data.information?.length > 3 ? "w-fit" : "w-full"
                    } items-center gap-x-[10px]`}
                  >
                    <span className="text-[12px] font-medium leading-[20px] text-[#0B1126]">
                      {item}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          {!data.tabs ? (
            <h3 className="text-[16px] font-semibold leading-[20px] text-[#25239F]">
              Để thêm <span className="lowercase">{data.tab}</span>, thực hiện
              các bước như sau:
            </h3>
          ) : (
            <h3 className="text-[16px] font-semibold leading-[20px] text-[#25239F]">
              {data.tabs[currentTab - 1].desc}
            </h3>
          )}
        </article>
      )}
      {data.tabs && (
        <div
          style={{
            boxShadow:
              "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
          }}
          className="flex h-[64px] min-w-[764px] items-center rounded-lg bg-[#fff] px-[10px]"
        >
          {data.tabs.map((tab: any, index: number) => {
            return (
              <div
                onClick={() => {
                  handleSetTab(tab);
                }}
                key={index}
                className={`${
                  currentTab === tab.id
                    ? "bg-primary text-[#fff]"
                    : "bg-[#fff] text-primary"
                } flex flex-1  cursor-pointer items-center justify-center space-x-1 rounded-[7px] px-4 py-3 text-base font-semibold leading-[20px]`}
              >
                <span>{tab.title}</span>
              </div>
            );
          })}
        </div>
      )}
      <div className="flex flex-col gap-[48px] font-inter xl:flex-row">
        <div className="flex max-w-[517.75px] flex-col items-center space-y-4">
          {!(active === 7 || active === 3) ? (
            <img src={data?.step[currentStep]?.img} alt="step" />
          ) : (
            <img src={data?.step[currentTab][currentStep]?.img} alt="step" />
          )}
          <div className="flex items-center gap-[16px]">
            <button
              disabled={true}
              onClick={handlePrev}
              className={`${
                currentStep === 0 ? "cursor-not-allowed" : "cursor-pointer"
              } flex w-[100px] min-w-[140px] items-center justify-center rounded-lg border border-primary bg-[#fff] p-[10px] text-primary`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M13.1372 15L8.13721 10L13.1372 5"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="whitespace-nowrap text-base font-normal capitalize">
                Quay lại
              </span>
            </button>
            <button
              disabled={true}
              onClick={handleNext}
              className={`${
                currentStep === data?.step?.length - 1 ||
                currentStep === data.step[currentTab]?.length - 1
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              } flex w-[140px] items-center justify-center rounded-lg border border-primary bg-primary p-[10px]`}
            >
              <span className="text-[#fff]">Tiếp theo</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M8.13721 15L13.1372 10L8.13721 5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-[24px] md:w-[471.4px]">
          <div className="relative flex flex-col gap-[18px]">
            <div
              className={`absolute left-5 z-0 h-full border-l border-dashed border-primary`}
            />
            {/* <div className="relative z-10">
            </div> */}
            {!(active === 7 || active === 3)
              ? renderData(data.step)
              : renderData(data.step[currentTab])}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Step);
