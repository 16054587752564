import React, { useEffect } from "react";

type Props = {
  minutes: number;
  seconds: number;
  setMinutes: (value: number) => void;
  setSeconds: (value: number) => void;
  setIsTimeout: React.Dispatch<React.SetStateAction<boolean>>;
};

const Timer = ({
  minutes,
  seconds,
  setMinutes,
  setSeconds,
  setIsTimeout,
}: Props) => {
  useEffect(() => {
    const timer = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        // Timer has reached 0, you can add your logic here
        clearInterval(timer);
        setIsTimeout(true);
      } else if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Clean up the interval when the component unmounts
    };
  }, [minutes, seconds]);

  return (
    <span className="text-[#262626]">
      ({String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")})
    </span>
  );
};

export default React.memo(Timer);
