import { useState, useEffect } from 'react';

const useValidatePw = (password: string) => {
    const [condition1, setCondition1] = useState(false)
    const [condition2, setCondition2] = useState(false)

    useEffect(() => {
        // if (password) {
        // }
        if (password?.length >= 7 && password?.length <= 20) {
            setCondition1(true)
        } else setCondition1(false)
        if (/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{4,}$/.test(password)) {
            setCondition2(true)
        } else setCondition2(false)
    }, [password])

    return { condition1, condition2 }
};

export default useValidatePw;
