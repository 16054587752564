import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ChakraProvider, extendTheme, Stepper } from "@chakra-ui/react";
import { StepsTheme as Steps } from "chakra-ui-steps";
import { Provider } from "react-redux";
import { store } from "../src/hooks/store";

import App from "./App";
import { stepperTheme } from "themes/stepper";
import FirebaseMsg from "firebaeMsg";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = extendTheme({
  components: {
    Steps,
    Stepper: stepperTheme,
  },
});
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
        <FirebaseMsg />
      </ChakraProvider>
    </Provider>
  </BrowserRouter>
);
