import { useEffect, useState } from "react";

const SwitchTab = ({ handleAction, options, defaultOption }: any) => {
    const listTab = [
        {
            label: 'Kênh hoạt động',
            value: false,
        },
        {
            label: 'Kênh đã xóa',
            value: true,
        },
    ]
    const [activeTab, setActiveTab] = useState(options ? (defaultOption ? defaultOption : options[0]) : listTab[0])

    useEffect(() => {
        handleAction && handleAction(activeTab.value);
    }, [activeTab]);

    return (
        <div className="grid w-[382px] grid-cols-2 gap-[10px] rounded-xl bg-white p-[10px] shadow-md">
            {[].concat(options ? options : listTab)?.map((tab: any) => {
                return (
                    <button
                        key={tab.label}
                        onClick={() => setActiveTab(tab)}
                        className={`${tab.label === activeTab.label
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            } h-11 w-full rounded-lg text-center text-[16px] font-semibold leading-5`}
                    >
                        {tab.label}
                    </button>
                );
            })}
        </div>
    );
};

export default SwitchTab;
