import { createSlice } from "@reduxjs/toolkit"


const pageSlice = createSlice({
  name: "page",
  initialState: {
    page: 1,
    page_size: 10,
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload.page
      state.page_size = action.payload.page_size
    },
  },
})

export const { setPage } = pageSlice.actions
export default pageSlice.reducer