import { createSlice } from "@reduxjs/toolkit"
import { generateBinCode } from "store/actions/deviceAction"

const codeSlice = createSlice({
  name: "code",
  initialState: {
    bin_code: "",
    bin_code_expire: 0,
    share_code: ""
  },
  reducers: {
    setCode: (state, action) => {
      state.bin_code = action.payload.bin_code
      state.bin_code_expire = action.payload.bin_code_expire
      state.share_code = action.payload.share_code
    },
  },
})

export const { setCode } = codeSlice.actions
export default codeSlice.reducer
