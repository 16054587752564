import { useDisclosure } from "@chakra-ui/hooks";
import { useAppDispatch } from "hooks/appHook";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";
import { formatDate } from "lib/utils";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteDevice, getDeviceList } from "store/actions/deviceAction";
import { setDevice } from "store/reducers/deviceSlice";
import { setLoading } from "store/reducers/loadingSlice";

import DeleteForm from "components/deleteform";
import HeaderTitle from "components/header-title/HeaderTitle";
import Table from "components/table";
import SelectChannel from "./SelectChannel";
import DeviceForm from "./DeviceForm";

import add from "assets/svg/add-red.svg";
import { BtnDelete } from "components/button/actionButton";
import { Tooltip } from "@chakra-ui/tooltip";

export default function AllDevices({
  onlyTable = false,
}: {
  onlyTable?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { success, error } = CustomToastMessage;
  const { va_account_no } = useParams();
  const page = useSelector((state: any) => state.page.page);
  const pageSize = useSelector((state: any) => state.page.page_size);
  const [totalPage, setTotalPage] = useState(0);
  const device = useSelector((state: any) => state.device);
  const [data, setData] = useState([]);
  const [item, setItem] = useState<any>({});

  const {
    isOpen: isChannelOpen,
    onOpen: openChannelForm,
    onClose: closeChannelForm,
  } = useDisclosure();
  const {
    isOpen: isShareOpen,
    onOpen: openShareForm,
    onClose: closeShareForm,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: openDeleteForm,
    onClose: closeDeleteForm,
  } = useDisclosure();

  const getDevices = async () => {
    dispatch(setLoading(true));
    const res: any = await dispatch(
      getDeviceList({ page, page_size: pageSize, va_account_no })
    );
    if (res.meta.requestStatus === "fulfilled" && res.payload) {
      setData(res?.payload.data.data);
      setTotalPage(res?.payload.data.total_page);
      dispatch(setDevice(false));
      dispatch(setLoading(false));
    } else {
      setData([]);
      dispatch(setLoading(false));
    }
  };
  const handleDeleteDevice = async (data: any) => {
    const res: any = await dispatch(deleteDevice({ data: { id: data } }));
    if (res.meta.requestStatus === "fulfilled" && res.payload) {
      closeDeleteForm();
      dispatch(setDevice(true));
      success(
        "Xóa tích hợp thành công",
        "Bạn vừa xóa thành công tích hợp " + item.channel_name + "."
      );
    } else error("Xóa thiết bị thất bại", ERROR_CODE[res.payload.error_code]);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Tên thiết bị",
        accessor: "name_device",
        Cell: ({ row }: any) => (
          <span className="text-sm text-[#0F1D40]">
            {row.original.name_device}
          </span>
        ),
      },
      {
        Header: "Hệ điều hành",
        accessor: "os",
        Cell: ({ row }: any) => (
          <span className="text-sm text-[#0F1D40] ">{row.original.os}</span>
        ),
      },
      {
        Header: "Browser",
        accessor: "browser",
        Cell: ({ row }: any) => (
          <img
            src={row.original.logo_browser}
            alt="logo_browser"
            className="h-6 w-6"
          />
        ),
      },
      {
        Header: "Phiên bản",
        accessor: "version",
        Cell: ({ row }: any) => (
          <span className="text-sm text-[#0F1D40]">{row.original.version}</span>
        ),
      },
      {
        Header: "IP",
        accessor: "ip",
        Cell: ({ row }: any) => (
          <span className="text-sm text-[#0F1D40]">{row.original.ip}</span>
        ),
      },
      {
        Header: "Kênh thanh toán",
        accessor: "channel_name",
        Cell: ({ row }: any) => (
          <Tooltip
            hasArrow
            label={row.original.channel_name}
            placement="top"
            bg={"#262626"}
            color={"#FFFFFF"}
            padding={"6px 12px"}
            rounded={"8px"}
            maxWidth={280}
            textAlign={"center"}
            fontSize={12}
            fontWeight={400}
            fontFamily={"Montserrat"}
          >
            <span className="line-clamp-1 max-w-[184px] text-sm text-[#0F1D40]">
              {row.original.channel_name}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: "Thời gian",
        accessor: "created_at",
        Cell: ({ row }: any) => (
          <span className="whitespace-nowrap text-sm text-[#0F1D40]">
            {formatDate(row.original.created_at)}
          </span>
        ),
      },
      {
        Header: "Hành động",
        Cell: ({ row }: any) => (
          <BtnDelete
            onClick={() => {
              setItem(row.original);
              openDeleteForm();
            }}
          />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getDevices();
  }, [device, page, pageSize]);

  return (
    <div className="flex flex-col gap-6 font-inter">
      {!onlyTable && (
        <div className="flex items-end justify-between">
          <HeaderTitle title="Tích hợp" subTitle="Tích hợp thiết bị" />
          <button
            className="flex items-center justify-center gap-[8px] rounded border border-primary px-[16px] py-[8px]"
            onClick={openChannelForm}
          >
            <img src={add} alt="add" />
            <span className="text-sm font-semibold text-primary">Thêm</span>
          </button>
        </div>
      )}
      <Table columns={columns} data={data} totalPage={totalPage} row={true} />
      {isChannelOpen && (
        <SelectChannel
          isOpen={isChannelOpen}
          onClose={closeChannelForm}
          openShare={openShareForm}
        />
      )}
      <DeviceForm isOpen={isShareOpen} onClose={closeShareForm} />
      <DeleteForm
        isOpen={isDeleteOpen}
        onClose={closeDeleteForm}
        handleAction={handleDeleteDevice}
        desc={`Bạn có chắc chắn muốn xóa thiết bị ${item.name_device}?`}
        title="Xóa thiết bị tích hợp"
        item={item}
      />
    </div>
  );
}
