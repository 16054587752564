import { createAsyncThunk } from "@reduxjs/toolkit";
import { deviceService } from "store/services/deviceService";

export const generateBinCode = createAsyncThunk(
  "device/generateBinCode",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await deviceService.generateBinCode(params);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const registerDevice = createAsyncThunk(
  "device/registerDevice",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await deviceService.registerDevice(params);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getDeviceListPerChannel = createAsyncThunk(
  "device/getDeviceList",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await deviceService.getDeviceListPerChannel(params);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getDeviceList = createAsyncThunk(
  "device/getDeviceList",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await deviceService.getDeviceList(params);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteDevice = createAsyncThunk(
  "device/deleteDevice",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await deviceService.deleteDevice(params);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);