import { useAppDispatch } from "hooks/appHook";
import { useEffect, useState } from "react";
import { getTicketList } from "store/actions/supportAction";
import { setLoading } from "store/reducers/loadingSlice";
import { TErrorReport } from "types/support";
import ErrorList from "./components/ErrorList";
import ErrorReportForm from "./components/ErrorReportForm";
import Support from "./components/Support";

const SupportPage = () => {
  const [reportList, setReportList] = useState<TErrorReport[]>([]);
  const dispatch = useAppDispatch();

  const handleGetTickets = async () => {
    dispatch(setLoading(true));
    const res: any = await dispatch(getTicketList({}));
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      const returnedReportList: TErrorReport[] = res?.payload?.data?.map(
        (el: TErrorReport, idx: number) => {
          return {
            ...el,
            idx: idx,
          };
        }
      );
      setReportList(returnedReportList);
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    handleGetTickets();
  }, []);

  return (
    <div className="flex w-full flex-col gap-y-8">
      <Support />
      <ErrorList data={reportList} />
      <ErrorReportForm handleGetTickets={handleGetTickets} />
    </div>
  );
};

export default SupportPage;
