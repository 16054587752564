import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import DefaultLogin from "components/route/DefaultLogin";
import ProtectedRouter from "components/route/ProtectedRouter";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import AdminFirstLogin from "layouts/register";

import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";
import SignIn from "views/auth/SignIn";

import Bank from "views/admin/bank";
import FormBankStep1 from "views/admin/bank/step1";
import FormBankStep2 from "views/admin/bank/step2";

import AllDevices from "views/admin/integrate/device";
import IntegratePerChannel from "views/admin/integrate/integrate-per-channel";
import IntegrateTelegram from "views/admin/integrate/telegram";
import IntegrateVAM from "views/admin/integrate/vam/IntegrateVAM";

import AddTelegram from "views/admin/integrate/telegram/AddTelegram";
import UpdateTelegram from "views/admin/integrate/telegram/UpdateTelegram";
import Overview from "views/admin/overview";
import Payment from "views/admin/payment";
import AddPaymentTele from "views/admin/payment/AddPaymentTele";
import DetailChannel from "views/admin/payment/components/DetailChannel";
import FrmCreatePChannel from "views/admin/payment/components/FrmCreatePChannel";
import UpdatePaymentTele from "views/admin/payment/UpdatePaymentTele";
import AccountInfo from "views/admin/setting/AccountInfo";
import ChangePassword from "views/admin/setting/ChangePassword";
import Support from "views/admin/support";
import ActiveChannel from "views/admin/transaction/active-channel";
import DeletedChannel from "views/admin/transaction/deleted-channel";

const App = () => {
  return (
    <>
      <Routes>
        <Route element={<ProtectedRouter />}>
          <Route path="/" element={<DefaultLogin />} />
          <Route path="/register" element={<AdminFirstLogin />}>
            <Route index path="add-bank/step2" element={<FormBankStep2 />} />
            <Route index path="add-bank" element={<FormBankStep1 />} />
            <Route path="add-va" element={<FrmCreatePChannel />} />
            <Route path="transfer-va/:channelId" element={<DetailChannel />} />
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="" element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<Overview />} />
            <Route path="active-channel" element={<ActiveChannel />} />
            <Route path="deleted-channel" element={<DeletedChannel />} />
            <Route path="bank" element={<Bank />} />
            <Route path="bank/create/step2" element={<FormBankStep2 />} />
            <Route path="bank/create" element={<FormBankStep1 />} />
            <Route
              path="payment/integrate-telegram/:va_account_no/:bank_account"
              element={<AddPaymentTele />}
            />
            <Route
              path="payment/edit-telegram/:id"
              element={<UpdatePaymentTele />}
            />
            <Route path="payment/qr/:channelId" element={<DetailChannel />} />
            <Route path="payment" element={<Payment />} />
            <Route path="payment/create" element={<FrmCreatePChannel />} />
            <Route
              path="payment/:va_account_no/:bank_account"
              element={<IntegratePerChannel />}
            />
            <Route path="integrate-vam" element={<IntegrateVAM />} />
            <Route path="integrate-device" element={<AllDevices />} />
            <Route path="integrate-telegram" element={<IntegrateTelegram />} />
            <Route
              path="integrate-telegram/create"
              element={<AddTelegram />}
            />
            <Route
              path="integrate-telegram/edit/:id"
              element={<UpdateTelegram />}
            />
            <Route path="setting-update" element={<AccountInfo />} />
            <Route
              path="setting-change-password"
              element={<ChangePassword />}
            />
            <Route path="support" element={<Support />} />
          </Route>
        </Route>
        <Route path="/auth/" element={<AuthLayout />}>
          <Route path="signin" element={<SignIn />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>

      <ToastContainer
        hideProgressBar
        closeButton={false}
        position="bottom-right"
        autoClose={2400}
      />
    </>
  );
};

export default App;
