import { useEffect, useState } from "react";
import { useAppDispatch } from "./appHook";
import { getBankAccount, getNoticeType } from "store/actions/bankAction";
import { getListPaymentChannel } from "store/actions/paymentChannelAction";
import { toOptionsData } from "lib/utils";
import { BANK_NAME_SHORT } from "lib/constants";

const useTelegram = ({
  bank_account,
  bank_code,
}: {
  bank_account: string;
  bank_code: string;
}) => {
  const [listBank, setListBank] = useState([]);
  const [listVa, setListVa] = useState([]);
  const [listNotice, setListNotice] = useState([]);
  const dispatch = useAppDispatch();

  const handleGetListBank = async () => {
    const res: any = await dispatch(getBankAccount({}));
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data?.items)
      setListBank(
        toOptionsData(
          res.payload.data.items,
          (item: any) => (
            <div className="flex items-center gap-2">
              <img src={item.logo_url} alt="logo" className="h-5 w-auto" />
              <p className="text-xs font-semibold text-[#0F1D40]">
                {BANK_NAME_SHORT[item.bank_code]}
              </p>
              <p className="text-sm text-[#03131B]">{item.account_no}</p>
            </div>
          ),
          "account_no",
          "bank_code"
        )
      );
  };

  const handleGetListVa = async () => {
    const res: any = await dispatch(
      getListPaymentChannel({
        bank_account_no: bank_account === "all" ? "" : bank_account,
      })
    );
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data)
      setListVa(
        toOptionsData(
          res.payload.data,
          (item: any) => item.name + " - " + item.va_account_no,
          "va_account_no"
        )
      );
  };

  const handleGetListNotice = async () => {
    const res: any = await dispatch(
      getNoticeType({
        bank_account_no: bank_account,
        bank_code: bank_code,
      })
    );
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data)
      setListNotice(toOptionsData(res.payload.data, "name", "id"));
  };

  useEffect(() => {
    handleGetListBank();
  }, []);

  useEffect(() => {
    if (bank_account && bank_code) {
      handleGetListVa();
      handleGetListNotice();
    }
  }, [bank_account, bank_code]);

  return { listBank, listVa, listNotice };
};

export default useTelegram;
