import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "hooks/appHook";
import { useProfile } from "hooks/useProfile";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { LuRefreshCw } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import { createPChannel, getVA } from "store/actions/paymentChannelAction";
import * as yup from "yup";

import Button from "components/button/button";
import InputField from "components/fields/InputField";
import HeaderTitle from "components/header-title/HeaderTitle";
import ModalOTP from "views/admin/bank/step2/ocb/ModalOTP";
import ListBankAccount from "./ListBankAccount";

const FrmCreatePChannel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedBankAcc, setSelectedBankAcc] = useState<any>({
    bank_code: "",
    account_no: "",
    prefix_va: "",
  });
  const isOnboard = useLocation().pathname.split("/")[1] == "register";
  const { bank_code, account_no: bank_account_no, prefix_va } = selectedBankAcc;

  const checkHasIdNumber = () => {
    if (["uat", "pilot"].includes(process.env.REACT_APP_ENV)) return false;
    else return bank_code != "VCCB";
  };

  const schema = useMemo(
    () =>
      yup.object({
        identification_no:
          bank_code == "OCB"
            ? yup
                .string()
                .required("Vui lòng nhập CCCD")
                .length(12, "Vui lòng nhập CCCD phải có 12 số")
            : yup.string(),
        va_account_no: yup.string().required(""),
        checkbox: checkHasIdNumber() ? yup.string().required("") : yup.string(),
        name: yup.string(),
      }),
    [bank_code]
  );
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { success, error } = CustomToastMessage;
  const [isLoading, setIsLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [channel, setChannel] = useState(null);

  const { data: profile } = useProfile();

  const onFormSubmit = async (data: any) => {
    console.log(">>> data", data);
    setIsLoading(true);
    try {
      const payload = {
        ...data,
        va_account_no: prefix_va + data.va_account_no,
        bank_account_no,
      };
      const checkType = profile.data.account_type;

      const res: any = await dispatch(createPChannel(payload));
      if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
        if (checkType == "BUSINESS" || bank_code == "VCCB") {
          success(
            "Thêm kênh thanh toán thành công",
            `Bạn vừa thêm thành công kênh thanh toán ${data.name}.`
          );
          if (isOnboard)
            navigate(`/register/transfer-va/${res.payload.data?.id}`);
          else navigate(`/admin/payment/qr/${res.payload.data?.id}`);
          return;
        } else {
          setChannel({
            id: res.payload.data?.id,
            payload,
          });
          setIsVerify(true);
        }
      } else
        error(
          "Thêm kênh thanh toán thất bại",
          ERROR_CODE[res?.payload?.error_code]
        );
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetVA = async () => {
    setValue("va_account_no", "");
    const res: any = await dispatch(
      getVA({ bank_account_no, bank_code, prefix_va })
    );
    if (res?.meta?.requestStatus == "fulfilled" && res?.payload?.data)
      setValue("va_account_no", res.payload.data.replace(prefix_va, ""));
  };

  useEffect(() => {
    if (bank_account_no) handleGetVA();
  }, [selectedBankAcc]);

  const disabled =
    bank_code != "VCCB" && (!watch("identification_no") || !watch("checkbox"));

  return (
    <div className="flex h-full flex-col gap-6">
      <HeaderTitle
        title="Kênh thanh toán"
        onClickTitle={() => !isOnboard && navigate("/admin/payment")}
        subTitle={`${isOnboard ? "Tạo" : "Thêm"} kênh thanh toán`}
      />
      {/* Form */}
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="mx-auto w-full max-w-[996px] rounded-2xl bg-white p-4 shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)] md:w-[84.55%] md:p-8"
      >
        {/* Data Fields */}
        <div className="flex flex-col items-start gap-7 self-stretch rounded-2xl border border-[#D9D9D9] p-6 lg:gap-4">
          <div className="flex w-full flex-col gap-2">
            <h3 className="text-sm font-semibold text-black-highlight">
              Tài khoản QR<span className="text-red-error">*</span>
            </h3>
            <div className="flex w-full items-end gap-2">
              <Button title={prefix_va} outline type="button" className="min-w-[70px] md:min-w-[102px] max-h-11" />
              <InputField
                readOnly
                isRequired
                variant="auth"
                errors={errors}
                register={register}
                type="text"
                id="va_account_no"
                name="va_account_no"
                extra="flex-[1_0_0]"
              />
              <button
                type="button"
                disabled={!watch("va_account_no")}
                onClick={handleGetVA}
                className="rounded bg-[#ED1C241A] p-[10px] text-primary disabled:opacity-50"
              >
                <LuRefreshCw
                  size={24}
                  color="currentColor"
                  className={`${!watch("va_account_no") && "animate-spin"}`}
                />
              </button>
            </div>
          </div>
          {/* Name */}
          {!(isOnboard && bank_code == "VCCB") && (
            <InputField
              errors={errors}
              register={register}
              type="text"
              id="channel-name"
              name="name"
              placeholder="Nhập tên kênh"
              label="Tên kênh"
              extra="w-full"
              variant="auth"
            />
          )}
          {/* Identification Number */}
          {checkHasIdNumber() && (
            <div className="w-full space-y-2">
              <InputField
                isRequired
                errors={errors}
                register={register}
                type="number"
                id="identification_no"
                name="identification_no"
                placeholder="Nhập CCCD"
                label="CCCD"
                variant="auth"
              />
              <label
                htmlFor="checkbox"
                className="flex cursor-pointer items-start gap-2 text-sm font-normal text-[#262626]"
              >
                <input
                  id="checkbox"
                  type="checkbox"
                  className="h-[18px] w-[18px] cursor-pointer accent-primary"
                />
                Tôi đồng ý sử dụng CCCD trên để tạo kênh thanh toán (tạo VA).
              </label>
            </div>
          )}
          {/* List Bank Account */}
          <ListBankAccount
            selectedBankAcc={selectedBankAcc}
            setSelectedBankAcc={setSelectedBankAcc}
          />
        </div>

        {/* Buttons */}
        <div className="mt-8 grid grid-cols-2 gap-4">
          {!isOnboard ? (
            <Button
              onClick={() => navigate("/admin/payment")}
              type="button"
              title="Hủy"
              outline
            />
          ) : (
            <div></div>
          )}
          <Button disabled={isLoading || disabled} title="Tạo VA" />
        </div>
      </form>

      {isVerify && (
        <ModalOTP onClose={setIsVerify} isOpen={isVerify} channel={channel} />
      )}
    </div>
  );
};

export default FrmCreatePChannel;
