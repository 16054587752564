import { useProfile } from "hooks/useProfile";
import React from "react";

import { Modal, ModalContent, ModalOverlay, Skeleton } from "@chakra-ui/react";

import "assets/css/ModalCustom.css";

import ImgDigibiz from "assets/img/bank/digibiz.png";
import ImgDigimi from "assets/img/bank/digimi.png";
import { BtnClose } from "components/button/actionButton";
import vccb from "assets/img/bank/vccb.png";

interface IProps {
  onClose: any;
  isOpen: boolean;
  data: string;
}
const QrVerify: React.FC<IProps> = ({ onClose, isOpen, data }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { data: profile } = useProfile();
  const accountType =
    profile?.data?.account_type === "BUSINESS"
      ? {
          title: "Digibiz",
          image: ImgDigibiz,
        }
      : {
          title: "Digimi",
          image: ImgDigimi,
        };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: 16,
          width: "92.27%",
          maxWidth: 390,
          padding: "32px 24px",
          position: "relative",
        }}
      >
        <div className="w-full rounded-2xl bg-white font-inter">
          <BtnClose onClick={() => onClose()} />
          <div className="flex w-full flex-col items-center ">
            <div className="text-xl font-bold text-[#0A203D] flex flex-col items-center gap-1">
              Xác thực với ngân hàng
              <img src={vccb} alt="bvbank" className="h-6 w-auto" />
            </div>
            <p className="mt-2 text-center text-sm text-[#0A203D]">
              Chọn Quét mã QR. Di chuyển camera đến vùng chứa mã QR bên dưới.
            </p>

            <Skeleton isLoaded={!!data} height={220} className="z-0 mb-4 mt-8">
              <img
                src={`data:image/png;base64,${data}`}
                alt="qr"
                className="h-[220px] w-[220px] rounded-xl object-contain"
              />
            </Skeleton>
            <img
              src={accountType.image}
              alt="image"
              className="h-8 w-auto object-contain"
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default QrVerify;
