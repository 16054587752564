import HeadlessTippy from "@tippyjs/react/headless";
import { useAppDispatch } from "hooks/appHook";
import { handleToastErr } from "lib/utils";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { getListBankNotice } from "store/actions/bankAction";
import "tippy.js/dist/tippy.css";
import "assets/css/ModalCustom.css";
import { BANK_NAME_CODE } from "lib/constants";

interface ReportIProps {
  id: number;
  name: string;
}

interface IProps {
  register: any;
  name: string;
  setValue: any;
  state?: string;
  extra?: string;
  errors?: any;
  isRequired?: boolean;
}

const SelectReportField: React.FC<IProps> = ({
  register,
  name,
  setValue,
  errors,
  extra,
  state,
  isRequired,
}) => {
  const [listReport, setListBank] = useState<ReportIProps[]>([]);
  const [currentReport, setCurrentReport] = useState<any | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  const location = useLocation();
  const queryToken = new URLSearchParams(location.search);
  const type = BANK_NAME_CODE[queryToken.get("type")];

  const handleSelectBox = (data: ReportIProps) => {
    setCurrentReport(data);
    setValue(name, String(data?.id));
    setShow(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res: any = await dispatch(getListBankNotice(type));
        if (res.meta.requestStatus === "fulfilled") {
          const data: ReportIProps[] = res?.payload?.data;

          setListBank(data);
        }
      } catch (err) {}
    };
    getData();
  }, []);

  const renderReview = () => {
    return (
      <div
        tabIndex={-1}
        className={`inset-x-0 flex w-full flex-col overflow-hidden rounded-2xl border border-[#D9DBE9] bg-white shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)]`}
      >
        {listReport.length > 0 ? (
          <>
            <div className="flex max-h-[200px] flex-col gap-1 overflow-y-auto ">
              {listReport.map((item, index) => {
                const active = currentReport?.id === item?.id;
                return (
                  <div
                    key={`${item?.id}-${index}`}
                    onClick={() => handleSelectBox(item)}
                    className={`font-inter ${
                      active
                        ? "bg-[rgba(39,144,195,0.1)] text-primary"
                        : "text-[#1C274C]"
                    } flex items-center gap-[10px] px-4 py-2 font-inter text-sm font-semibold transition-all duration-300 hover:bg-[rgba(39,144,195,0.1)] hover:text-primary`}
                  >
                    {item?.name}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="flex w-full items-center justify-center p-3">
            Không có dữ liệu
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`${extra} relative w-full cursor-pointer font-inter text-[#262626] transition`}
    >
      <div className="mb-2 text-[14px] font-semibold leading-[20px] ">
        Loại báo cáo giao dịch
        {isRequired && <span className="text-red-error">*</span>}
      </div>
      <div>
        <HeadlessTippy
          interactive
          visible={show}
          placement="bottom-start"
          offset={[0, 8]}
          render={() => renderReview()}
          onClickOutside={() => setShow(false)}
        >
          <div
            {...register(name, {
              required: {
                value: isRequired,
                message: handleToastErr(name),
              },
            })}
            onClick={() => setShow(!show)}
            className={` ${
              errors[name]
                ? "border-red-error text-red-error "
                : " border-[#D9DBE9] text-[#0F1D40]"
            } flex w-full items-center justify-between rounded-lg border p-3`}
          >
            <span className="text-[14px] leading-[20px] ">
              {currentReport
                ? currentReport?.name
                : "Chọn loại báo cáo giao dịch"}
            </span>

            <div className="flex size-4 items-center justify-center">
              <IoIosArrowDown
                width={8}
                className={`${
                  show && "-rotate-180"
                } text-primary transition-all`}
              />
            </div>
          </div>
        </HeadlessTippy>
      </div>

      <p className="text-xs text-red-error">
        {errors[name] && errors[name].message}
      </p>
    </div>
  );
};

export default SelectReportField;
