import axiosClient from "./axiosClient"

const PAYMENT_CHANNEL_ENDPOINT = "/channel-payment"

export const paymentChannelService = {
    getListPaymentChannel: (params: any) => {
        return axiosClient.get(`${PAYMENT_CHANNEL_ENDPOINT}`, { params })
    },
    getVA: (params: any) => {
        return axiosClient.get(`va`, { params })
    },
    createPChannel: (formData: any) => {
        return axiosClient.post(`${PAYMENT_CHANNEL_ENDPOINT}`, formData)
    },
    updatePaymentChannel: (params: any) => {
        return axiosClient.put(`${PAYMENT_CHANNEL_ENDPOINT}`, params)
    },
    deletePaymentChannel: (params: any) => {
        return axiosClient.delete(`${PAYMENT_CHANNEL_ENDPOINT}`, params)
    },
    verifyChannel: (params: any) => {
        return axiosClient.post('/verify-va', params)
    },
}