import { formatDate } from "lib/utils";
import { useNavigate } from "react-router-dom";

import HeaderTitle from "components/header-title/HeaderTitle";
import SwitchTab from "components/switch-tab";
import Table from "components/table";

import { Tooltip } from "@chakra-ui/tooltip";
import qr from "assets/img/payment/qr.png";
import { BtnDelete, BtnEdit, BtnView } from "components/button/actionButton";
import { BankCell } from "views/admin/transaction/active-channel";
import add from "../../../../assets/svg/add-red.svg";

export default function PChannelTable({
  data,
  setShowQR,
  setShowEditModal,
  setShowDeleteModal,
  setShowDetailModal,
  setSelectedChannel,
  handleListChannel,
  isDeleted,
}: any) {
  const navigate = useNavigate();
  const columns = [
    {
      Header: "Kênh thanh toán",
      accessor: "name",
      Cell: ({ row }: any) => (
        <Tooltip
          label={row.original.name}
          hasArrow
          fontSize={12}
          fontWeight={400}
          lineHeight={5}
          fontFamily={"Montserrat"}
          bg="#262626"
          px={3}
          py={1.5}
          borderRadius={8}
          color="white"
          placement="top"
        >
          <p className="max-w-[200px] cursor-pointer truncate text-sm text-[#0F1D40]">
            {row.original.name}
          </p>
        </Tooltip>
      ),
    },
    {
      Header: "Tài khoản QR",
      accessor: "va_account_no",
      Cell: ({ row }: any) => (
        <button
          disabled={isDeleted}
          onClick={() => {
            setSelectedChannel(row.original);
            setShowQR(true);
          }}
          className="flex items-center gap-2"
        >
          <img
            src={qr}
            alt="qr"
            className={`h-5 w-auto ${isDeleted && "opacity-50"}`}
          />
          <p className="text-sm text-[#0F1D40] ">
            <span className="font-semibold text-primary">
              {row.original.prefix_va}
            </span>
            {row.original.va_account_no.replace(row.original.prefix_va, '')}
          </p>
        </button>
      ),
    },
    {
      Header: "Số tài khoản",
      accessor: "",
      Cell: ({ row }: any) => (
        <BankCell
          logo={row.original.bank_logo}
          text={row.original.bank_account_no}
        />
      ),
    },
    {
      Header: "Thời gian tạo",
      accessor: "",
      Cell: ({ row }: any) => (
        <span className="text-sm text-[#0F1D40] ">
          {formatDate(row.original.created_at)}
        </span>
      ),
    },
    {
      Header: "Tên viết tắt",
      accessor: "prefix",
      Cell: ({ row }: any) => (
        <span className="text-sm uppercase text-[#0F1D40]">
          {row.original.prefix}
        </span>
      ),
    },
    {
      Header: "Tích hợp",
      accessor: "device",
      Cell: ({ row }: any) => (
        <p
          onClick={() =>
            navigate(
              `${row.original.va_account_no}/${row.original.bank_account_no}`
            )
          }
          className={`${isDeleted ? "pointer-events-none opacity-50" : "cursor-pointer"
            } text-sm text-primary underline`}
        >
          Xem
        </p>
      ),
    },
    {
      Header: "Hành động",
      Cell: ({ row }: any) => (
        <div
          className={`flex justify-end gap-3 md:justify-start w-[max-content] ${isDeleted && "pointer-events-none opacity-50"
            }`}
        >
          <BtnView
            onClick={() => {
              setSelectedChannel(row.original);
              setShowDetailModal(true);
            }}
          />
          <BtnEdit
            onClick={() => {
              setSelectedChannel(row.original);
              setShowEditModal(true);
            }}
          />
          <BtnDelete
            onClick={() => {
              setSelectedChannel(row.original);
              setShowDeleteModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="flex w-full flex-col gap-6 font-inter">
      <div className="flex items-center justify-between">
        <HeaderTitle subTitle="Kênh thanh toán" />
        {!isDeleted && (
          <button
            onClick={() => navigate("create")}
            className="flex h-fit items-center justify-center gap-[8px] rounded border border-primary px-[16px] py-[8px] md:hidden"
          >
            <img src={add} alt="add" />
            <span className="text-sm font-semibold text-primary">Thêm</span>
          </button>
        )}
      </div>

      <div className="flex items-center justify-between">
        <SwitchTab handleAction={handleListChannel} />
        {!isDeleted && (
          <button
            onClick={() => navigate("create")}
            className=" hidden h-fit items-center gap-[8px] rounded border border-primary px-[16px] py-[8px] md:flex"
          >
            <img src={add} alt="add" />
            <span className="text-sm font-semibold text-primary">Thêm</span>
          </button>
        )}
      </div>
      <Table columns={columns} data={data || []} row />
    </div>
  );
}
