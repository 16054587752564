import { createSlice } from "@reduxjs/toolkit";

const transactionSlice = createSlice({
    name: 'transaction',
    initialState: { device: false },
    reducers: {
      setModalTransaction(state, action) {
        state.device = action.payload;
      },
    },
  });

  export const { setModalTransaction } = transactionSlice.actions;
  export default transactionSlice.reducer;
