import { createSlice } from "@reduxjs/toolkit";
import { verifyBankWithOtp } from "store/actions/verifyBankWithOtpAction";

const initialState = {
  isLoading: false,
  isTextFail: false,
  error: "Bad request",
};

const verifyBankWithOtpSlice = createSlice({
  name: "verifyBankWithOtp",
  initialState: initialState,
  reducers: {
    setIsTextOtpFail: (state, action) => {
      state.isTextFail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyBankWithOtp.pending, (state, action) => {
      state.isLoading = true;
      state.isTextFail = false;
    });
    builder.addCase(verifyBankWithOtp.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(verifyBankWithOtp.rejected, (state, action) => {
      state.isLoading = false;
      state.isTextFail = true;

      const msg = (action?.payload as { errors: { errors: { msg: string } } })
        ?.errors?.errors?.msg;
      if (msg) {
        state.error = msg;
      }
    });
  },
});

export const selectOtpLoading = (state: any) =>
  state.verifyBankWithOtp.isLoading;
export const selectOtpError = (state: any) => state.verifyBankWithOtp.error;
export const selectIsOtpTextFail = (state: any) =>
  state.verifyBankWithOtp.isTextFail;

export const { setIsTextOtpFail } = verifyBankWithOtpSlice.actions;

export default verifyBankWithOtpSlice.reducer;
