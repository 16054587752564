import React from "react";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import success from "assets/img/customToast/success.svg";
import error from "assets/img/customToast/error.svg";

const customToast = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  maxWidth: 416,
  height: 90,
  padding: "20px 20px 20px 16px",
  margin: 24,
  border: "1px solid #E6E8EC",
  boxShadow: "0px 12px 32px 0px rgba(6, 28, 61, 0.08)",
  borderRadius: 10,
};

const Message = ({ title, desc }: { title: string; desc: string }) => {
  return (
    <div className="space-y-[6px] font-inter">
      <h3 className="text-sm font-semibold tracking-[-0.192px] text-[#0F1D40] md:text-base">
        {title}
      </h3>
      <p className="text-xs font-medium text-[#8991A3] md:text-sm">{desc}</p>
    </div>
  );
};

export const CustomToastMessage = {
  success: (title: string, desc: string) => {
    toast.success(<Message title={title} desc={desc} />, {
      icon: <img src={success} />,
      style: {
        ...customToast,
        borderLeft: "4px solid #0F9918",
      },
    });
  },
  warning: (title: string, desc: string) => {
    toast.warning(<Message title={title} desc={desc} />, {
      style: {
        ...customToast,
        borderLeft: "4px solid yellow",
      },
    });
  },
  error: (title: string, desc: string) => {
    toast.error(<Message title={title} desc={desc} />, {
      icon: <img src={error} />,
      style: {
        ...customToast,
        borderLeft: "4px solid #E54545",
      },
    });
  },
};
