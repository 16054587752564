import { createSlice } from "@reduxjs/toolkit";
import { emailRecovery, getProfile } from "store/actions/userAction";

const initialState = {
  profile: {
    tax_no: "",
    email: "",
  },
  isLoading: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(emailRecovery.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(emailRecovery.fulfilled, (state, action) => {
      state.profile.tax_no = action.meta?.arg;
      state.profile.email = action.payload?.data?.email;
      state.isLoading = false;
    });
    builder.addCase(emailRecovery.rejected, (state, action) => {
      state.profile.email = "";
      state.isLoading = false;
    });
  },
});

export const selectProfile = (state: any) => state.profile.profile;
export const selectProfileIsLoading = (state: any) => state.profile.isLoading;
export default profileSlice.reducer;
