import { FormContent, HeaderTitle } from "./index";

const IntegrateVAM = () => {
  return (
    <section className="flex flex-col items-center space-y-6">
      <HeaderTitle title="Tích hợp" subTitle="Tích hợp Open API" />

      <FormContent />
    </section>
  );
};

export default IntegrateVAM;
