import axiosClient from "./axiosClient";

export const settingService = {
  updateInfoAccount: (params: any) => {
    return axiosClient.put("/user/info", params);
  },

  settingPassword: (params: any) => {
    return axiosClient.put(`/user/reset-password`, params);
  },
};
