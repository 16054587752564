import React from "react";

import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { BtnClose } from "components/button/actionButton";
import Button from "components/button/button";

import "assets/css/ModalCustom.css";
import Sections from "./sections";

interface IProps {
  onClose: any;
  isOpen: boolean;
  onClickBtn?: any;
}

const Consent: React.FC<IProps> = ({ onClose, isOpen, onClickBtn }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [disabled, setDisabled] = React.useState(true);

  const checkScrollBottom = (e: any) => {
    const isAtBottom =
      Math.abs(e.scrollHeight - e.clientHeight - e.scrollTop) < 1;
    if (isAtBottom) {
      setDisabled(false);
      e.removeEventListener("scroll", checkScrollBottom);
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: 16,
          width: "92.27%",
          maxWidth: 782,
          padding: "24px",
          position: "relative",
        }}
      >
        <div className="w-full rounded-2xl bg-white font-inter">
          <BtnClose
            onClick={() => {
              onClose();
              setDisabled(true);
            }}
          />
          <div className="flex w-full flex-col items-center gap-4">
            <p className="text-center text-xl font-bold leading-7 text-[#0A203D]">
              ĐIỀU KHOẢN VÀ ĐIỀU KIỆN SỬ DỤNG DỊCH VỤ <br /> THANH TOÁN MÃ QR
            </p>
            <div
              onScroll={(e: any) => checkScrollBottom(e.target)}
              className="custom-scrollbar max-h-[408px] w-full space-y-6 overflow-y-scroll text-sm text-[#262626]"
            >
              <Sections />
            </div>
            <Button
              title="Tôi đồng ý"
              className="mt-4 w-full"
              disabled={disabled}
              onClick={() => {
                onClose();
                setDisabled(true);
                onClickBtn();
              }}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default Consent;
