import React, { useEffect, useMemo } from "react";
import { useTable } from "react-table";
import Pagination from "components/pagination";
import { useSelector } from "react-redux";
import "../../assets/css/table.css";
import { Skeleton } from "@chakra-ui/react";
import { useAppDispatch } from "hooks/appHook";
import { setPage } from "store/reducers/pageSlice";
import useWindowSize from "hooks/useWindowSize";
import Button from "components/button/button";
import icExport from "../../assets/icon/icon-export.svg";

interface TableProps {
  columns: any;
  data: Array<any>;
  totalPage?: number;
  row?: boolean;
  noPagination?: boolean;
  totalAmount?: number;
  noRowDivider?: boolean;
  disableMinWidth?: boolean;
  handleExport?: () => void;
}
export default function Table({
  columns,
  data,
  totalPage,
  row,
  noPagination,
  totalAmount,
  noRowDivider = false,
  disableMinWidth = false,
  handleExport,
}: TableProps) {
  // const [PageSize, setPageSize] = useState(5);
  // const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useAppDispatch();
  const page = useSelector((state: any) => state.page.page);
  const pageSize = useSelector((state: any) => state.page.page_size);
  const loading = useSelector((state: any) => state.loading.loading);
  const totalCount = totalPage * pageSize;

  useEffect(() => {
    dispatch(setPage({ page: 1, page_size: 10 }));
  }, []);

  const handleChange = (e: any) =>
    dispatch(setPage({ page: 1, page_size: Number(e.target.value) }));

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [page, pageSize, data]);

  const currentTableDataForPaginate = useMemo(() => {
    const firstPageIndex = 0;
    const lastPageIndex = pageSize;
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [page, pageSize, data]);

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data: totalCount ? currentTableDataForPaginate : currentTableData,
  });

  const { width } = useWindowSize();

  return (
    <Skeleton className="h-[min-content]" isLoaded={!loading}>
      <div
        className={`overflow-auto rounded-lg ${
          width >= 600 && "bg-white shadow-[0px_6px_7px_-2px_rgba(0,0,0,0.08)]"
        }`}
      >
        <div className="no-scrollbar max-h-[calc(100vh-296px)] overflow-y-scroll">
          <table {...getTableProps()} className="w-full">
            <thead className="sticky top-0 z-10 bg-[#FFF] shadow-[0px_2px_2px_-2px_rgba(0,0,0,0.08)]">
              {headerGroups.map((headerGroup, index) => {
                // console.log(headerGroup)
                return (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, columnIndex) => (
                      <td
                        scope="col"
                        key={columnIndex}
                        {...column.getHeaderProps()}
                        className="whitespace-nowrap px-4 py-3 text-left text-sm font-medium text-[#8C8C8C]"
                      >
                        {column.render("Header")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {!loading && rows.length === 0 && (
                <tr className="h-[200px] w-full border-t text-center hover:bg-gray-50">
                  <td colSpan={columns.length}>Không có dữ liệu</td>
                </tr>
              )}
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr
                    key={rowIndex}
                    {...row.getRowProps()}
                    className={`first: border-gray-200 first:border-t ${
                      width >= 600 && "hover:bg-gray-50"
                    } ${!noRowDivider && "border-t border-gray-200"}`}
                  >
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          data-label={columns[cellIndex].Header}
                          key={cellIndex}
                          {...cell.getCellProps()}
                          className={`${
                            cellIndex === 0 && !disableMinWidth
                              ? "min-w-[200px]"
                              : cellIndex === 3 && !disableMinWidth
                              ? "min-w-[160px] "
                              : ""
                          } h-[56px] px-4 py-[10px] text-sm`}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {totalAmount > 0 && (
          <div
            className={`flex h-14 items-center justify-between  ${
              width >= 600 && "border-t"
            } border-gray-200 px-4 py-4 hover:bg-gray-50`}
          >
            <Button
              icon={icExport}
              title="Xuất báo cáo"
              className="rounded-lg border-0 bg-[#1890FF1A] text-[#1890FF]/[99]"
              onClick={handleExport}
            />
            <p className="text-lg font-semibold text-[#005AA9]">
              Tổng cộng: {totalAmount.toLocaleString()} VND
            </p>
          </div>
        )}

        {/* Pagination */}
        {!noPagination && (
          <div
            className={`flex ${
              width < 600 ? "flex-col" : "flex-row border-t bg-white"
            } items-center justify-between gap-y-2 border-[#E8E8E8] px-5 pb-5 pt-8`}
          >
            {data.length > 0 && (
              <>
                <Pagination
                  currentPage={page}
                  totalCount={totalCount ? totalCount : data.length}
                  pageSize={pageSize}
                  onPageChange={(page: any) => {
                    dispatch(
                      setPage({
                        page: page,
                        page_size: pageSize,
                      })
                    );
                  }}
                />
                {row && (
                  <div className="flex items-center gap-[8px]">
                    <span className="hidden text-sm font-medium text-[#0F1D40] md:block">
                      Số dòng:
                    </span>
                    <div>
                      <select
                        className="flex items-center gap-[8px] rounded-lg border border-[#E8E8E8] py-[8px] pl-[16px] pr-[28px] text-[#0F1D40]"
                        id="pageSizeSelect"
                        value={pageSize}
                        onChange={handleChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {/* End Pagination */}
      </div>
    </Skeleton>
  );
}
