import { Variants } from "framer-motion";

export const dropdownVariants = {
  closed: {
    display: "none",
    translateY: "-5%",
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
  open: {
    display: "block",
    translateY: "0",
    opacity: 1,
    transition: {
      type: "spring",
      duration: 0.3,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
} satisfies Variants;

export const layoutVariants = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 0.8,
    },
  },
} satisfies Variants;
