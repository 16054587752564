import React, { useState, useEffect, ReactNode } from "react";
import useClickOutSide from "lib/useOnClickOutSide";

import search from "../../assets/svg/search.svg";
import { IoIosArrowDown } from "react-icons/io";

interface Props {
  formField: string;
  title?: string;
  required?: boolean;
  data?: any;
  placeHolder?: string;
  setValue?: any;
  defaultValue?: any;
  watch?: any;
  errors?: any;
  hideSearch?: boolean;
  hasAllOption?: boolean;
  setValue2?: any;
  className?: string;
}

const SelectField = ({
  formField,
  title,
  required,
  data,
  placeHolder,
  setValue,
  defaultValue,
  watch,
  errors,
  hideSearch,
  hasAllOption,
  setValue2,
  className,
}: Props) => {
  const [label, setLabel] = useState();
  const [icon, setIcon] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [formatData, setFormatData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { nodeRef } = useClickOutSide(() => setShowDropdown(false));

  useEffect(() => {
    if (data && data.length > 0) {
      const newData =
        hasAllOption && data?.length > 0
          ? [
              {
                label: "Tất cả",
                value: "all",
                value2: data[0].value2 && "all",
              },
              ...data,
            ]
          : data;
      setFormatData(newData);
    } else setFormatData([]);
  }, [data]);

  useEffect(() => {
    if (defaultValue && formatData.length > 0) {
      const defaultItem = formatData?.find(
        (item: any) => item.value === defaultValue
      );
      if (formField == "") {
        console.log(">>> data: ", data);
        console.log(">>> formatData: ", formatData);
        console.log(">>> value: ", defaultValue);
        console.log(">>> defaultItem: ", defaultItem);
      }
      setValue2 && setValue2(defaultItem?.value2);
      setLabel(defaultItem?.label);
      setIcon(defaultItem?.icon);
      setValue(formField, defaultItem?.value);
    }
  }, [defaultValue, formatData]);

  useEffect(() => {
    if (!label) setValue(formField, "");
  }, [label]);

  const getTextFromReactNode = (node: ReactNode): string => {
    if (typeof node === 'string' || typeof node === 'number') {
      return node.toString();
    }
  
    if (React.isValidElement(node)) {
      return getTextFromReactNode(node.props.children);
    }
  
    if (Array.isArray(node)) {
      return node.map(getTextFromReactNode).join('');
    }
  
    return '';
  };

  useEffect(() => {
    const newData = formatData.filter((item: any) => {
      const label = getTextFromReactNode(item.label)
      return label.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredData(newData);
  }, [searchTerm, formatData]);

  return (
    <div className="space-y-2 text-[#0F1D40]">
      {title && (
        <p className="text-sm font-semibold">
          {title}
          {required && <span className="text-red-error">*</span>}
        </p>
      )}
      <div ref={nodeRef} className="relative">
        <div
          onClick={() => setShowDropdown(!showDropdown)}
          className={`flex cursor-pointer items-center justify-between rounded-lg border border-[#E8E8E8] px-4 py-2 ${className}`}
        >
          {watch(formField) && label ? (
            <div className="flex items-center gap-3">
              {icon && <img src={icon} alt="item-icon" className="h-6 w-6" />}
              <div className="line-clamp-1 text-sm font-normal">{label}</div>
            </div>
          ) : (
            <p className="text-sm font-normal">{placeHolder}</p>
          )}
          <div className="flex size-6 items-center justify-center">
            <IoIosArrowDown
              width={10}
              className={`${
                showDropdown && "-rotate-180"
              } text-primary transition-all`}
            />
          </div>
        </div>
        {showDropdown && (
          <div
            className="absolute top-[46px] z-20 w-full space-y-1 overflow-hidden rounded-2xl border border-[#E8E8E8] bg-white pt-4"
            style={{ boxShadow: "0px 12px 19px -5px #00000014" }}
          >
            {!hideSearch && (
              <label
                htmlFor="dropdown-search"
                className="mx-4 flex items-center gap-[10px] rounded-lg bg-[#F5F5F5] px-3 py-2"
              >
                <img src={search} alt="search" className="h-4 w-4" />
                <input
                  onInput={(e: any) => setSearchTerm(e.target.value)}
                  placeholder="Tìm kiếm"
                  id="dropdown-search"
                  className="w-full flex-[1_0_0] bg-[#F5F5F5] text-sm font-normal text-[#8991A3] outline-none"
                />
              </label>
            )}
            <div className="custom-scrollbar max-h-[216px] space-y-1 overflow-auto">
              {filteredData?.length > 0 ? (
                filteredData?.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setValue(formField, item.value);
                        setLabel(item.label);
                        setIcon(item.icon);
                        setShowDropdown(false);
                        setValue2 && setValue2(item.value2);
                      }}
                      className="group flex cursor-pointer items-center gap-3 px-4 py-2 text-[#0F1D40] transition-all hover:bg-[#ED1C241A] hover:text-primary"
                    >
                      {item.icon && (
                        <img
                          src={item.icon}
                          alt="item-icon"
                          className="h-6 w-6"
                        />
                      )}
                      <p
                        className={`text-sm font-normal leading-6 group-hover:font-medium`}
                      >
                        {item.label}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className="flex w-full items-center justify-center px-4 py-2 text-[#0F1D40]">
                  Không có dữ liệu
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectField;
