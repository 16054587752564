import axiosClient from "./axiosClient";

const USER_ENDPOINT = "/user";

export const userService = {
  login: (params: any) => {
    return axiosClient.post(`${USER_ENDPOINT}/login`, params);
  },
  logout: (params: any) => {
    return axiosClient.post(`${USER_ENDPOINT}/logout`, params);
  },
  profile: (params: any) => {
    return axiosClient.get(`${USER_ENDPOINT}/info`, params);
  },
  qr: (params: any) => {
    return axiosClient.post(`${USER_ENDPOINT}/qr`, params);
  },

  emailRecovery: (tax_no: any) => {
    return axiosClient.get(`/tax-no?tax_no=${tax_no}`);
  },
  emailAuthenticator: (params: any) => {
    return axiosClient.post(`${USER_ENDPOINT}/forgot-password`, params);
  },
  resetPassword: (params: any) => {
    return axiosClient.put(`${USER_ENDPOINT}/forgot-password`, params);
  },
};
