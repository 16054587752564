import {
  GetTransactionRequest,
  GetTransactionResponse,
} from "types/transaction";
import axiosClient from "./axiosClient";

const DASHBOARD_ENDPOINT = "dashboard";
const PORTAL_ENDPOINT = "/portal";

export const transactionService = {
  getTransacions: (params: GetTransactionRequest) => {
    return axiosClient.get<GetTransactionResponse>(`/transaction/history`, {
      params,
    });
  },
};
