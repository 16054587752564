import { createSlice } from "@reduxjs/toolkit";

interface integration {
  public_key: string;
  private_key: string;
  aes_key: string
}

interface IInitIntergationVam extends integration {
  callback_url: string;
  password: string;
  partner_code: string;
}

type TypeIntegrationSlice = {
  initIntegration: {
    data: null | IInitIntergationVam;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  };
  getIntegration: {
    data: null | integration;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  };
  submitIntegration: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  };
};

const initialState: TypeIntegrationSlice = {
  initIntegration: {
    data: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  getIntegration: {
    data: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  submitIntegration: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

const integrationSlice = createSlice({
  name: "integration",
  initialState: initialState,
  reducers: {
    initIntegrationStart: (state) => {
      state.initIntegration.isLoading = true;
      state.initIntegration.isSuccess = false;
      state.initIntegration.isError = false;
    },
    initIntegrationSuccess: (state, actino) => {
      state.initIntegration.data = actino.payload;
      state.initIntegration.isLoading = false;
      state.initIntegration.isSuccess = true;
      state.initIntegration.isError = false;
    },
    initIntegrationFailed: (state) => {
      state.initIntegration.data = null;
      state.initIntegration.isLoading = false;
      state.initIntegration.isSuccess = false;
      state.initIntegration.isError = true;
    },
    initIntegrationReset: (state) => {
      state.initIntegration.data = null;
      state.initIntegration.isLoading = false;
      state.initIntegration.isSuccess = false;
      state.initIntegration.isError = false;
    },
    

    getIntegrationStart: (state) => {
      state.getIntegration.isLoading = true;
      state.getIntegration.isSuccess = false;
      state.getIntegration.isError = false;
    },
    getIntegrationSuccess: (state, action) => {
      state.getIntegration.data = action.payload;
      state.getIntegration.isLoading = false;
      state.getIntegration.isSuccess = true;
      state.getIntegration.isError = false;
    },
    getIntegrationFailed: (state) => {
      state.getIntegration.data = null;
      state.getIntegration.isLoading = false;
      state.getIntegration.isSuccess = true;
      state.getIntegration.isError = false;
    },

    resetGetIntegration: (state) => {
      state.getIntegration.data = null;
      state.getIntegration.isLoading = false;
      state.getIntegration.isSuccess = false;
      state.getIntegration.isError = false;
    },

    submitIntegrationStart: (state) => {
      state.submitIntegration.isLoading = true;
      state.submitIntegration.isSuccess = false;
      state.submitIntegration.isError = false;
    },
    submitIntegrationSuccess: (state) => {
      state.submitIntegration.isLoading = false;
      state.submitIntegration.isSuccess = true;
      state.submitIntegration.isError = false;
    },
    submitIntegrationFailed: (state) => {
      state.submitIntegration.isLoading = false;
      state.submitIntegration.isSuccess = false;
      state.submitIntegration.isError = true;
    },

    resetSubmitIntegration: (state) => {
      state.submitIntegration.isLoading = false;
      state.submitIntegration.isSuccess = false;
      state.submitIntegration.isError = false;
    },
  },
});

export const {
  initIntegrationStart,
  initIntegrationSuccess,
  initIntegrationFailed,
  initIntegrationReset,

  getIntegrationStart,
  getIntegrationSuccess,
  getIntegrationFailed,

  submitIntegrationStart,
  submitIntegrationSuccess,
  submitIntegrationFailed,

  resetGetIntegration,
  resetSubmitIntegration,
} = integrationSlice.actions;
export default integrationSlice.reducer;
