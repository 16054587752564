import { ReactNode } from 'react';
import { cn } from '../../lib/utils';

type SelectItemProps = {
    label: ReactNode;
    value: string;
    onClick: (value: string) => void;
    isActive?: boolean;
};

const SelectItem = ({ label, value, onClick, isActive }: SelectItemProps) => {
    return (
        <div
            className={cn(
                'flex w-full cursor-pointer justify-between px-4 py-2.5 duration-150 hover:bg-select-hover hover:text-primary',
                isActive && 'bg-select-hover text-primary'
            )}
            onClick={(e) => {
                e.stopPropagation();
                onClick(value);
            }}
        >
            <div className="flex items-center gap-[10px]">{label}</div>
        </div>
    );
};

export default SelectItem;
