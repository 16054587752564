import { useAppDispatch } from "hooks/appHook";
import { useEffect, useState } from "react";
import { getBankAccount } from "store/actions/bankAction";

const ListBankAccount = ({ selectedBankAcc, setSelectedBankAcc }: any) => {
  const dispatch = useAppDispatch();
  const [listBankAccount, setListBankAccount] = useState([]);
  const getListBankAccount = async () => {
    const res: any = await dispatch(getBankAccount({ page: 1, limit: 100000 }));
    if (res.meta.requestStatus === "fulfilled" && res.payload) {
      // console.log('>>> check list bank: ',res?.payload.data?.items[0])
      setListBankAccount(res?.payload.data?.items);
      await setSelectedBankAcc(res?.payload.data.items[0]);
    } else setListBankAccount([]);
  };
  useEffect(() => {
    getListBankAccount();
  }, []);

  return (
    <div className="w-[max-content] space-y-4 text-black-highlight">
      <h3 className="text-sm font-semibold">
        Thông tin ngân hàng<span className="text-red-error">*</span>
      </h3>
      <div className="custom-scrollbar flex h-[128px] w-full flex-col gap-4 overflow-y-auto pr-5">
        {listBankAccount.map((bankAccount) => {
          const { id, bank_code, holder_name, account_no, logo_url } =
            bankAccount;
          return (
            <div
              onClick={() => setSelectedBankAcc(bankAccount)}
              className="flex items-center gap-4"
              key={id}
            >
              <input
                readOnly
                checked={selectedBankAcc.account_no === account_no}
                type="radio"
                name="bank_account_no"
                id="bank_account_no"
                className="accent-primary"
              />
              <img src={logo_url} alt="odoo" className="h-8" />
              <div className="text-sm font-semibold uppercase">
                {holder_name} - {account_no}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListBankAccount;
