type ImgListProps = {
  imgArr: string[];
  handelViewImg: (e: React.MouseEvent<HTMLElement>, idx: number) => void;
};

const ImgList = ({ imgArr, handelViewImg }: ImgListProps) => {
  return (
    <div className="flex flex-col space-y-2">
      <p className="font-inter text-sm font-semibold text-[#262626]">
        Hình ảnh
      </p>
      <div className="no-scrollbar flex w-full space-x-3 overflow-x-scroll rounded-xl border-[1px] border-[#E8E8E8] p-3">
        {imgArr.map((image, idx) => (
          <button
            onClick={(e) => handelViewImg(e, idx)}
            key={idx}
            className="w-fit rounded-xl border-[0.6px] border-[#E8E8E8]"
          >
            <img
              src={image}
              alt="Details"
              className="h-[116px] w-[116px] min-w-[116px] rounded-xl"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default ImgList;
