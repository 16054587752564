import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import useWindowSize from "hooks/useWindowSize";

const SidebarItem = ({
    icon,
    title,
    link,
    subItems,
    openSidebar,
    activedItem,
    setActivedItem,
    openGlobalSubItems,
    setOpenGlobalSubItems,
    setOpenSidebar
}: any) => {
    const { isMd } = useWindowSize()
    const navigate = useNavigate();
    const [openSubItems, setOpenSubItems] = useState(false);
    const hasSubItems = subItems?.length > 0;
    const isActivated = () =>
        activedItem == link || ((!openSubItems || !openSidebar) && subItems?.find((item: any) => activedItem == item.link));

    useEffect(() => {
        setOpenSubItems(false);
    }, [openSidebar]);
    useEffect(() => {
        if (openGlobalSubItems != title) setOpenSubItems(false);
    }, [openGlobalSubItems, title]);

    return (
        <div className="relative">
            <div
                onMouseOver={() => {
                    if (!openSidebar) setOpenSubItems(true);
                }}
                onMouseOut={() => {
                    if (!openSidebar) setOpenSubItems(false);
                }}
                onClick={() => {
                    if (hasSubItems) {
                        if (openSidebar) {
                            if (title != openGlobalSubItems) setOpenGlobalSubItems(title);
                            setOpenSubItems(!openSubItems);
                        }
                    } else {
                        navigate(link);
                        setActivedItem(link);
                        !isMd && setOpenSidebar(false)
                    }
                }}
                className={`overflow-hidden max-h-16 group hover:text-white
                ${isActivated() ? "bg-[#0B11261A] text-white" : 'text-white'}
                ${openSidebar ? "py-3 px-[10px]" : "py-[14px] px-5 justify-center"}
                flex gap-[10px] items-center rounded-xl hover:bg-[#0B11261A] cursor-pointer`}
                style={{ transition: "all 0.2s ease-in" }}
            >
                {icon}
                {openSidebar &&
                    <span className={`flex-[1_0_0] text-sm group-hover:text-white ${isActivated() ? "text-white" : 'text-white'} leading-5 font-medium truncate transition`}>
                        {title}
                    </span>
                }
                {hasSubItems && openSidebar &&
                    <IoIosArrowDown
                        color="currentColor"
                        size={16}
                        className={`${openSubItems && 'rotate-180'} transition duration-300`}
                    // style={{ transform: openSubItems && "rotate(180deg)", tran }}
                    />
                }
            </div>

            {/* <div style={{ height: openSubItems ? height : 0, transition: 'all 0.3s linear', overflow: 'hidden' }}> */}
            <div
                onMouseOver={() => {
                    if (!openSidebar) setOpenSubItems(true);
                }}
                onMouseOut={() => {
                    if (!openSidebar) setOpenSubItems(false);
                }}
                className={`top-0 transition
                    ${!openSidebar && hasSubItems
                        ? "rounded-lg z-20 p-3 bg-[#AD1112] absolute left-full w-[max-content] shadow-sm"
                        : "relative left-0 overflow-hidden"}
                    ${!openSubItems ? 'hidden' : hasSubItems && !openSidebar && 'border'}
                    `}
            >
                {subItems?.map((item: any, index: number) => {
                    return (
                        <div
                            key={index}
                            onClick={() => {
                                setActivedItem(item.link);
                                navigate(item.link);
                                !isMd && setOpenSidebar(false)
                            }}
                            style={{
                                padding: "10px 32px",
                                transition: "all 0.2s ease-in",
                                maxHeight: 64,
                            }}
                            className={`text-sm leading-5 font-medium rounded-xl hover:bg-[#0B11261A] hover:text-white cursor-pointer
                            ${activedItem === item.link ? "bg-[#0B11261A] text-white" : 'text-white '} 
                            overflow-hidden flex items-center gap-3`}
                        >
                            {item.icon && <div className="w-4 h-4">{item.icon}</div>}
                            {item.title}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SidebarItem;
