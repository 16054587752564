type TDate = {
  hours: string;
  minutes: string;
  seconds: string;
  day: string;
  month: string;
  year: string;
};

export const formatDateFromDate = (value: string) => {
  if (!value) return "";
  const arr = value.toString().split("-");
  return arr.reverse().join("/");
};

export const formatDateFromMilliseconds = (milliseconds: number) => {
  const d = new Date(milliseconds * Math.pow(10, 3));
  return d.toLocaleDateString("vi-VN", { month: "2-digit", year: "2-digit" });
};

export const formatDateToMilliseconds = (date: string | Date) => {
  return Date.parse(String(date)) / Math.pow(10, 3);
};

export const formatDateTxn = (value: string, hasHHMM = true) => {
  const [date, time] = value.split(" ");
  // const arr = date?.split("-");
  // return arr?.reverse()?.join("/");
  const newDate = date.split("-").reverse().join("/");
  const [h, m] = time.split(":");
  if (hasHHMM) return newDate + " " + h + ":" + m;
  return newDate;
};

export function getDaysDifference(date1: string, date2: string) {
  // Create Date objects for the input dates
  const startDate = new Date(date1).getTime();
  const endDate = new Date(date2).getTime();

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Convert the time difference to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference.toString();
}

export const getToday = () => {
  const currentDate = new Date();
  // Set the start of the day (0:00:00)
  const startOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    0,
    0,
    0,
    0
  );
  // Set the end of the day (23:59:59)
  const endOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    23,
    59,
    59,
    999
  );

  return [startOfDay, endOfDay];
};

export const getYesterday = () => {
  const currentDate = new Date();

  // Set the start of yesterday (0:00:00)
  const startOfYesterday = new Date(currentDate);
  startOfYesterday.setDate(currentDate.getDate() - 1);
  startOfYesterday.setHours(0, 0, 0, 0);

  // Set the end of yesterday (23:59:59)
  const endOfYesterday = new Date(currentDate);
  endOfYesterday.setDate(currentDate.getDate() - 1);
  endOfYesterday.setHours(23, 59, 59, 999);

  return [startOfYesterday, endOfYesterday];
};

export const getSevenDayAgo = () => {
  const currentDate = new Date();

  // Set the start of 7 days ago (0:00:00)
  const startOf7DaysAgo = new Date(currentDate);
  startOf7DaysAgo.setDate(currentDate.getDate() - 7);
  startOf7DaysAgo.setHours(0, 0, 0, 0);

  // Set the start of 7 days ago (0:00:00)
  const endOf7DaysAgo = new Date(currentDate);
  startOf7DaysAgo.setDate(currentDate.getDate() - 7);
  endOf7DaysAgo.setHours(23, 59, 59, 999);

  return [startOf7DaysAgo, endOf7DaysAgo];
};

export const getThisMonth = () => {
  const currentDate = new Date();

  // Set the start of the first day of the current month (0:00:00)
  const firstDayOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  firstDayOfCurrentMonth.setHours(0, 0, 0, 0);

  // Set the end of today (23:59:59)
  const endOfToday = new Date(currentDate);
  endOfToday.setHours(23, 59, 59, 999);
  return [firstDayOfCurrentMonth, endOfToday];
};

export const getLastMonth = () => {
  const currentDate = new Date();

  // Set the start of the first day of the last month (0:00:00)
  const firstDayOfLastMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  firstDayOfLastMonth.setHours(0, 0, 0, 0);

  // Set the end of the last day of the last month (23:59:59)
  const lastDayOfLastMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );
  lastDayOfLastMonth.setHours(23, 59, 59, 999);

  return [firstDayOfLastMonth, lastDayOfLastMonth];
};

export const getThisQuarter = () => {
  const currentDate = new Date();
  // Determine the current quarter
  const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);

  // Set the start of the first day of the current quarter (0:00:00)
  const firstDayOfCurrentQuarter = new Date(
    currentDate.getFullYear(),
    (currentQuarter - 1) * 3,
    1
  );
  firstDayOfCurrentQuarter.setHours(0, 0, 0, 0);

  return [firstDayOfCurrentQuarter, currentDate];
};

export function formatTimestampToDate(time?: number): TDate {
  if (!time)
    return {
      hours: "",
      minutes: "",
      seconds: "",
      day: "",
      month: "",
      year: "",
    };

  if (Math.round(time).toString().length < 13) time *= 1000;

  const date = new Date(time);

  const hours = date
    .toLocaleTimeString("vi-VN", { hour: "2-digit" })
    .padStart(2, "0");
  const minutes = date
    .toLocaleTimeString("vi-VN", { minute: "2-digit" })
    .padStart(2, "0");
  const seconds = date
    .toLocaleTimeString("vi-VN", { second: "2-digit" })
    .padStart(2, "0");
  const day = date
    .toLocaleDateString("vi-VN", { day: "2-digit" })
    .padStart(2, "0");
  const month = date
    .toLocaleDateString("vi-VN", { month: "2-digit" })
    .padStart(2, "0");
  const year = date.toLocaleDateString("vi-VN", { year: "numeric" });

  return {
    hours,
    minutes,
    seconds,
    day,
    month,
    year,
  };
}

// TimeStamp to Date (yyyy-mm-dd hh:mm:ss)
export const formatTimeStampToStr = (timestamp: number, isEnd?: boolean) => {
  const endDay = isEnd ? "23:59:59" : "00:00:00";

  const { year, month, day, hours, minutes, seconds } =
    formatTimestampToDate(timestamp);
  // console.log({ year, month, day, hours, minutes, seconds });
  return `${year}-${month}-${day} ${endDay}`;
};

export const formatCustomDate = (timestamp: number) => {
  const { year, month, day } =
    formatTimestampToDate(timestamp);
  return `${day}/${month}/${year}`;
}
