import atom from "assets/img/auth/atom-login.png";

const ExampleNoti = () => {
  return (
    <div className="flex w-full max-w-[428px] flex-col items-center gap-9 rounded-2xl border border-[#D9D9D9] bg-white px-4 pb-16 pt-6 font-inter">
      <h1 className="text-[18px] leading-[32px] font-bold text-[#0F1D40]">
        Nội dung thông báo Telegram
      </h1>
      <article className="flex w-full flex-col items-center gap-6">
        <div className="flex w-full flex-col items-center gap-2 rounded-xl border border-[#E8E8E8] py-[12px]">
          <h2 className="text-[14px] font-semibold leading-[20px] text-[#0F1D40]">
            Thông báo biến động số dư
          </h2>
          <span className="text-[14px] leading-[20px] text-[#0F1D40]">
            20 thành viên
          </span>
        </div>
        <div className="flex w-full flex-col  gap-6  rounded-2xl bg-[#EFF2F6] pb-[8px] pt-[16px]">
          <div className="flex flex-col gap-5 pl-[8px] pr-[16px]">
            <div className="flex flex-col items-center">
              <div className="flex max-w-[276px] flex-col gap-[14px] text-[14px] leading-5 text-[#81757D]">
                <p className="text-[#F5222D]">ATOM Bot</p>
                <p>---------------------</p>
                <p>Có giao dịch mới:</p>
                <p>
                  ✳️ Tiền vào:{" "}
                  <span className="text-[#F5222D]">10,000 VND</span>
                </p>
                <p>
                  ⛪️ Tài khoản chính:{" "}
                  <span className="text-[#F5222D]">0919634055</span>
                </p>
                <p>
                  ⛪️ Tài khoản QR thụ hưởng:{" "}
                  <span className="text-[#F5222D]">
                    Cửa hàng 1 - 99AT33ABKLEKGRB
                  </span>
                </p>
                <p>
                  ℹ️ Nội dung thanh toán:{" "}
                  <span className="text-[#F5222D]">
                    [Tên tích hợp Telegram] vừa có một giao dịch báo nợ với số
                    tiền 10,000 VND
                  </span>
                </p>
                <p>
                  ⚓️ Mã tham chiếu:{" "}
                  <span className="text-[#F5222D]">1M6Ug</span>
                </p>
                <p>
                  ⏰ Giao dịch lúc:{" "}
                  <span className="text-[#F5222D]">17:12:29 05/07/2024</span>
                </p>
              </div>
            </div>
            <div className="px-6 text-sm text-[#81757D]">
              -------------------------------------
            </div>
          </div>
          <span className="pr-4 text-right text-xs font-medium text-[#505B76]">
            03:10 PM
          </span>
        </div>
      </article>
      <img src={atom} alt="unipays" className="h-[60px] w-auto" />
    </div>
  );
};

export default ExampleNoti;
