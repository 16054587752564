import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { BtnClose } from "components/button/actionButton";
import Button from "components/button/button";
import { useState } from "react";
import warn from "../../assets/svg/warn.svg";

export default function DeleteForm(props: {
  onClose: any;
  isOpen: boolean;
  handleAction?: any;
  title?: string;
  desc?: string;
  item: any;
}) {
  const { onClose, item, isOpen, title, handleAction, desc } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay>
        <ModalContent
          style={{
            borderRadius: 16,
            width: "92.27%",
            maxWidth: 428,
            padding: "32px 24px",
            position: "relative",
          }}
        >
          <div className="flex w-full flex-col gap-8 rounded-2xl bg-white font-inter">
            <BtnClose onClick={() => onClose()} />
            <div className="flex flex-col items-center gap-6">
              <img src={warn} alt="warn" className="h-[48px] w-[48px]" />
              <div className="flex flex-col gap-[12px] text-center font-inter text-[#0A203D]">
                <h1 className="text-xl font-bold">{title}</h1>
                <p className="text-sm font-normal">{desc}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Button title="Huỷ" outline onClick={() => onClose()} />
              <Button
                title="Xóa"
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  await handleAction(item?.id);
                  setIsLoading(false);
                  onClose();
                }}
              />
            </div>
          </div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
