import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import Button from "components/button/button";
import {
  HiddenFieldGroup,
  SingleHiddenField,
} from "components/fields/HiddenField";
import { useAppDispatch } from "hooks/appHook";
import { useEffect, useRef, useState } from "react";
import { getBalance, getBankAccount } from "store/actions/bankAction";
import { TAccountInfo, TGetBalanceRes } from "types/txn";

type AccountInfoModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AccountInfoModal = ({ isOpen, onClose }: AccountInfoModalProps) => {
  const [accountList, setAccountList] = useState<TAccountInfo[]>([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const dispatch = useAppDispatch();

  const handleGetBalance = async () => {
    setIsLoading(true);
    const res: any = await dispatch(getBalance({}));
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      let balance = 0;
      setAccountList(
        res.payload.data.map((item: TGetBalanceRes) => {
          balance += item.balance;
          return {
            bank_logo:
              item.logo_url ||
              "https://s3-hn-2.cloud.cmctelecom.vn/test-s3/logo/bank/VCCB1.png",
            bank_account_no: item.bank_account_no,
            account_balance: item.balance,
          };
        })
      );
      setTotalBalance(balance);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetBalance();
  }, []);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />

      <ModalContent
        style={{ margin: 0, minWidth: "min-content", borderRadius: "16px" }}
      >
        <ModalCloseButton />
        <div className="flex w-[600px] flex-col gap-y-8 p-6">
          <h4 className="mx-auto text-2xl font-bold text-[#0A203D]">
            Thông tin tài khoản
          </h4>
          <SingleHiddenField
            label="Số dư tài khoản"
            fieldName="Tổng số dư"
            fieldValue={totalBalance}
          />
          <HiddenFieldGroup
            label="Danh sách tài khoản"
            firstField="Số tài khoản"
            secondField="Số dư (VND)"
            data={accountList}
            loading={isLoading}
          />
          <p className="font-inter text-sm text-[#ED1C24]">
            <i>
              *Lưu ý: Số dư tài khoản thanh toán trên hệ thống của ATOM, được
              tổng hợp từ các giao dịch báo biến động số dư, nên có thể sẽ không
              hoàn toàn trùng khớp với số dư tài khoản thanh toán của khách hàng
              tại Ngân hàng và ATOM không chịu trách nhiệm về tính chính xác của
              số dư này.
            </i>
          </p>
          <Button
            title="Đóng"
            type="button"
            className="w-full"
            onClick={onClose}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default AccountInfoModal;
