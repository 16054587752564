import Button from "components/button/button";
import { useNavigate } from "react-router-dom";

interface IProps {
  icon: any;
  title: string;
  subTitle?: React.ReactElement | string;
}

export const Notify: React.FC<IProps> = ({ icon, title, subTitle }) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col items-center gap-4">
      <div className="size-12 text-primary">
        {typeof icon == "object" ? (
          icon
        ) : (
          <img src={icon} alt="icon" className="h-12 w-12" />
        )}
      </div>
      <p className="text-2xl font-bold text-[#0F1D40] md:text-3xl">{title}</p>
      <div className="text-sm font-normal text-black-highlight">{subTitle}</div>
      <Button
        title="Đóng"
        outline
        onClick={() => navigate("/auth/signin")}
        className="mt-4 w-full"
      />
    </div>
  );
};

export const HeaderPw = ({ title, desc }: { title: string; desc: string }) => {
  return (
    <div className="flex flex-col items-center gap-2 text-primary">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.8455 18.8961V14.6021C32.8455 9.57609 28.7695 5.50009 23.7435 5.50009C18.7175 5.47809 14.6255 9.53409 14.6035 14.5621V14.6021V18.8961"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.366 42.4999H16.084C11.896 42.4999 8.5 39.1059 8.5 34.9159V26.3379C8.5 22.1479 11.896 18.7539 16.084 18.7539H31.366C35.554 18.7539 38.95 22.1479 38.95 26.3379V34.9159C38.95 39.1059 35.554 42.4999 31.366 42.4999Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7246 28.4062V32.8482"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <h2 className="text-2xl font-bold text-[#0F1D40] md:text-3xl">{title}</h2>
      <p className="text-center text-sm font-normal text-black-highlight">
        {desc}
      </p>
    </div>
  );
};
