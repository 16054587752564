import { CustomToastMessage } from "./CustomToastMessage";

import vi from "../assets/img/auth/vie.svg";
import en from "../assets/img/auth/eng.svg";

const { success, warning, error } = CustomToastMessage;

export const BANK_NAME_SHORT: any = {
  VCCB: 'BVBank',
  OCB: 'OCB',
  VPB: 'VPBank'
}

export const BANK_NAME_CODE: any = {
  BVBank: 'VCCB',
  OCB: 'OCB',
  VPBank: 'VPB'
}

export const toastMessage = {
  error: (text: string) => error("Lỗi", text),
  warning: (text: string) => warning("Cảnh báo", text),
  success: (text: string) => success("Thành công", text),
};

export const toastConfirmFormBankFail = () =>
  error(
    "Xác thực tài khoản thất bại",
    "Vui lòng nhập tài khoản khác để xác thực tài khoản."
  );

export const toastAddBankSuccess = ({ bank_code = "", bank_account_no = "" }) =>
  success(
    "Thêm ngân hàng thành công",
    `Bạn vừa thêm thành công ngân hàng ${BANK_NAME_SHORT[bank_code]} với số tài khoản ${bank_account_no}`
  );

export const toastDeleteBankSuccess = ({
  bank_code = "",
  bank_account_no = "",
}) =>
  success(
    "Xoá ngân hàng thành công",
    `Bạn vừa xoá thành công ngân hàng ${BANK_NAME_SHORT[bank_code]} với số tài khoản ${bank_account_no}.`
  );

export const toastDeleteBankFail = (err: string) =>
  error("Xoá ngân hàng thất bại", err);

export const INIT_MINUTES = 2;
export const INIT_SECONDS = 0;
export const BANK_CODE_DEFAULT = "VCCB";

export const REDUX_THUNK_STATUS = {
  PENDING: "pending",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
};

export enum ELanguage {
  vi = "vi",
  en = "en",
}
export const LIST_LANGUAGE = {
  [ELanguage.en]: {
    icon: en,
    value: "en",
    label_vi: "Tiếng Anh",
    label_en: "english",
  },
  [ELanguage.vi]: {
    icon: vi,
    value: "vi",
    label_vi: "Tiếng Việt",
    label_en: "vietnamese",
  },
};

export const ACCOUNT_TYPE: any = {
  PERSONAL: 'Cá nhân',
  HOUSEHOLD_BUSINESS: 'Hộ kinh doanh',
  BUSINESS: 'Doanh nghiệp'
}

export const PREFIX_VA: any = {
  OCB: 'ATO',
  VCCB: '99AT',
  VPB: 'ATO'
}

// export const ROUTER = {
//   admin: "admin",
//   overview: "overview",
//   transaction: "transaction",
//   bank: "bank",
//   payment: "payment",
//   device: "device",
//   setting: "setting",
//   table: "table",
//   login: "login",
// };
