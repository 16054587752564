import axiosClient from "./axiosClient";

const BANK_ENDPOINT = "/bank";

export const bankService = {
  getListBank: (params: any) => {
    return axiosClient.get("/portal/dashboard/list-bank", params);
  },
  getListBankNotice: (bank_code: string) => {
    return axiosClient.get(
      `${BANK_ENDPOINT}/notice-type?bank_code=${bank_code}`
    );
  },
  getQrDownloadApp: (bank_code: string) => {
    return axiosClient.get(`${BANK_ENDPOINT}/download?bank_code=${bank_code}`);
  },
  addBank: (params: any) => {
    return axiosClient.post(`${BANK_ENDPOINT}/account`, params);
  },
  deleteBank: (id: string) => {
    return axiosClient.delete(`${BANK_ENDPOINT}/account`, { data: { id } });
  },
  getBankAccount: (params: any) => {
    return axiosClient.get(`${BANK_ENDPOINT}/account`, { params });
  },
  getHolderName: ({ bank_account_no = "", bank_code = "" }) => {
    return axiosClient.get(
      `${BANK_ENDPOINT}/holder-name?bank_account_no=${bank_account_no}&bank_code=${bank_code}`
    );
  },
  getNoticeType: (params: any) => {
    return axiosClient.get(`${BANK_ENDPOINT}/transaction-type`, { params });
  },
  checkRegister: (params: any) => {
    return axiosClient.get(`${BANK_ENDPOINT}/check-register`, { params });
  },
  getBalance: () => {
    return axiosClient.get(`${BANK_ENDPOINT}/balance`);
  }
};
