import { createSlice } from "@reduxjs/toolkit";
import { verifyBank } from "store/actions/verifyBankAction";

const initialState = {
  account: {
    bank_account_no: "",
    bank_code: "",
    holder_name: "",
  },
  isLoading: false,
  error: "Bad request",
};

const verifyBankSlice = createSlice({
  name: "verifyBank",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verifyBank.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(verifyBank.fulfilled, (state, action) => {
      state.account = action.meta.arg;
      state.isLoading = false;
    });
    builder.addCase(verifyBank.rejected, (state, action) => {
      state.isLoading = false;

      // Assertion failed, Bank Account incorrect
      const msg = (action?.payload as { errors: [{ msg: string }] })
        ?.errors?.[0]?.msg;

      // Bank is exist
      const msgBankAccountNo = (
        action?.payload as { errors: { bank_account_no: string } }
      )?.errors?.bank_account_no;

      // Not match full name of tax
      const msgBankAccountHolderName = (
        action?.payload as { errors: { holder_name: string } }
      )?.errors?.holder_name;

      // Authentication failed
      const msgAuthenFail = (action?.payload as { error: { message: string } })
        ?.error?.message;

      if (msg) {
        state.error = msg;
      }
      if (msgBankAccountNo) {
        state.error = msgBankAccountNo;
      }
      if (msgBankAccountHolderName) {
        state.error = msgBankAccountHolderName;
      }
      if (msgAuthenFail) {
        state.error = msgAuthenFail;
      }
    });
  },
});

export const selectBankAccount = (state: any) => state.verifyBank.account;
export const selectBankAccountLoading = (state: any) =>
  state.verifyBank.isLoading;
export const selectBankAccountError = (state: any) => state.verifyBank.error;

export default verifyBankSlice.reducer;
