import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/appHook";
import { INIT_MINUTES, INIT_SECONDS, toastMessage } from "lib/constants";
import { verifyBank } from "store/actions/verifyBankAction";
import { selectBankAccount, selectBankAccountError } from "store/reducers/verifyBankSlice";
import { selectIsOtpTextFail, setIsTextOtpFail } from "store/reducers/verifyBankWithOtpSlice";
import { createPChannel } from "store/actions/paymentChannelAction";
import Timer from "./Timer";

type Props = {
  setIsTimeout: React.Dispatch<React.SetStateAction<boolean>>,
  channel?: any,
  setChannelId?: any
};

const BtnSend = ({ setIsTimeout, channel, setChannelId }: Props) => {
  const dispatch = useAppDispatch();
  const bankAccount = useAppSelector(selectBankAccount);
  const [minutes, setMinutes] = useState(INIT_MINUTES);
  const [seconds, setSeconds] = useState(INIT_SECONDS);
  const isTextFail = useAppSelector(selectIsOtpTextFail);
  const [wrongOtp, setWrongOtp] = useState(false)
  const error = useAppSelector(selectBankAccountError);
  const disabled = (!isTextFail) && (minutes !== 0 || seconds !== 0);

  const handleClick = async () => {
    setIsTimeout(false);
    if (!channel) {
      dispatch(setIsTextOtpFail(false));
      try {
        const res: any = await dispatch(verifyBank(bankAccount));
        if (res?.meta?.requestStatus === "fulfilled") {
          setMinutes(INIT_MINUTES);
          setSeconds(INIT_SECONDS);
        } else if (res?.meta?.requestStatus === "rejected") {
          toastMessage.error(error);
          setIsTimeout(true);
        }
      } catch (err) { console.log(err) }
    } else {
      dispatch(setIsTextOtpFail(false))
      setMinutes(INIT_MINUTES);
      setSeconds(INIT_SECONDS);
      const res: any = await dispatch(createPChannel(channel.payload))
      if (res?.meta?.requestStatus === "fulfilled") {
        setChannelId(res?.payload?.data?.id)
      } else if (res?.meta?.requestStatus === "rejected") {
        console.log('>>> resend otp fail')
        dispatch(setIsTextOtpFail(true));
      }
    }
  };

  return (
    <>
      <button
        disabled={disabled}
        onClick={handleClick}
        className={`${disabled
          ? "cursor-default text-[#ABB2C2]"
          : "cursor-pointer font-medium text-primary hover:opacity-80"
          } `}
      >
        Gửi lại OTP
      </button>
      {(!isTextFail || !wrongOtp) && (
        <Timer
          minutes={minutes}
          seconds={seconds}
          setMinutes={setMinutes}
          setSeconds={setSeconds}
          setIsTimeout={setIsTimeout}
        />
      )}
    </>
  );
};

export default React.memo(BtnSend);
