import { ReactNode } from "react";

export const Highlight = ({
  text,
  semibold,
  newLine,
}: {
  text: string;
  semibold?: boolean;
  newLine?: boolean;
}) => {
  return (
    <span className={semibold ? "font-semibold" : "font-medium"}>
      {text}
      {newLine && <br />}
    </span>
  );
};

export const LinkText = ({
  text,
  link,
  newLine,
}: {
  text: string;
  link?: string;
  newLine?: boolean;
}) => {
  return (
    <span
      className={`text-sm font-normal text-[#1890FF] underline cursor-pointer`}
      onClick={() => window.open(link || text, "_blank")}
    >
      {text}
      {newLine && <br />}
    </span>
  );
};

export const SectionWrap = ({
  title,
  children,
  titleSize = "sm",
  tabSpace,
}: {
  title: string;
  children?: ReactNode;
  titleSize?: "sm" | "base";
  tabSpace?: boolean;
}) => {
  return (
    <div className="flex flex-col">
      <p
        className={`${
          titleSize == "sm" ? "text-sm" : "text-base"
        } font-semibold`}
      >
        {title}
      </p>
      <div
        className={`${
          tabSpace && "pl-6"
        } whitespace-pre-line text-sm font-normal`}
      >
        {children}
      </div>
    </div>
  );
};
