import { useDisclosure } from "@chakra-ui/hooks";
import { BtnDelete } from "components/button/actionButton";
import DeleteForm from "components/deleteform";
import Table from "components/table";
import { useAppDispatch } from "hooks/appHook";
import {
  BANK_NAME_SHORT,
  toastDeleteBankFail,
  toastDeleteBankSuccess,
} from "lib/constants";
import { ERROR_CODE } from "lib/errorCode";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteBank, getBankAccount } from "store/actions/bankAction";
import { setLoading } from "store/reducers/loadingSlice";
import add from "../../../assets/svg/add-red.svg";
import { HeaderTitle } from "../integrate/vam";
import { BankCell } from "../transaction/active-channel";
import { formatDate } from "lib/utils";

const Bank = () => {
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [item, setItem] = useState<any>({});
  const [listBank, setListBank] = useState<any>([]);
  const page = useSelector((state: any) => state.page.page);
  const pageSize = useSelector((state: any) => state.page.page_size);
  const [totalPage, setTotalPage] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: "Tên ngân hàng",
        accessor: "bank_code",
        Cell: ({ row }: any) => (
          <BankCell logo={row?.original?.logo_url} text={row?.original?.name} />
        ),
      },
      {
        Header: "Số tài khoản",
        accessor: "account_no",
      },
      {
        Header: "Tên thụ hưởng",
        accessor: "holder_name",
      },
      {
        Header: "Ngày tạo",
        accessor: "created_at",
        Cell: ({ row }: any) => formatDate(row?.original?.created_at),
      },
      {
        Header: "Hành động",
        Cell: ({ row }: any) => (
          <BtnDelete onClick={() => handleOpenDeleteModal(row)} />
        ),
      },
    ],
    []
  );

  const getListBankAccount = async () => {
    dispatch(setLoading(true));
    const res: any = await dispatch(
      getBankAccount({ page: page, limit: pageSize })
    );
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      setListBank(res?.payload?.data?.items);
      setTotalPage(res?.payload?.data?.total_page);
      dispatch(setLoading(false));
    }
  };

  const handleDeleteBank = async (id: string) => {
    try {
      const res: any = await dispatch(deleteBank(id));
      if (res?.meta?.requestStatus === "fulfilled") {
        toastDeleteBankSuccess({
          bank_code: item?.bank_code,
          bank_account_no: item?.account_no,
        });
        getListBankAccount();
        onClose();
      } else {
        let msg = "Bad request";

        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        } else if (res?.payload?.msg) {
          msg = res?.payload?.msg;
        }

        toastDeleteBankFail(msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenDeleteModal = (row: any) => {
    setItem(row.original);
    onOpen();
  };

  useEffect(() => {
    getListBankAccount();
  }, [page, pageSize]);

  return (
    <div className="flex flex-col gap-6 font-inter">
      <div className="flex justify-between">
        <HeaderTitle subTitle="Ngân hàng" />
        <Link
          to={`create`}
          className="flex items-center justify-center gap-2 rounded border border-primary px-4 py-2 text-primary"
        >
          <img src={add} alt="ic_add" />
          <span className="text-sm font-semibold text-primary">Thêm</span>
        </Link>
      </div>
      <Table
        columns={columns}
        data={listBank}
        row={true}
        totalPage={totalPage}
      />
      <DeleteForm
        isOpen={isOpen}
        onClose={onClose}
        title="Xoá ngân hàng"
        desc={`Bạn có chắc chắn muốn xoá số tài khoản ${item?.account_no} ${item?.holder_name
          } ngân hàng ${BANK_NAME_SHORT[item?.bank_code]}?`}
        item={item}
        handleAction={handleDeleteBank}
      />
    </div>
  );
};

export default Bank;
