import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/button/button";
import InputFieldPw from "components/fields/InputFieldPw";
import { useAppDispatch } from "hooks/appHook";
import { md5 } from "js-md5";
import { ERROR_CODE } from "lib/errorCode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { resetPassword } from "store/actions/userAction";
import * as yup from "yup";
import IcSuccess from "assets/img/auth/ic_success.svg";
import IcWarn from "../../assets/svg/warn.svg";
import useValidatePw from "hooks/useValidatePw";
import { HeaderPw, Notify } from "./common";

const schema = yup.object({
  password: yup.string().required("Vui lòng nhập mật khẩu"),
  new_password: yup
    .string()
    .required("Vui lòng nhập xác nhận mật khẩu")
    .oneOf(
      [yup.ref("password"), null],
      "Mật khẩu đã nhập không khớp, vui lòng nhập lại"
    ),
});

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { condition1, condition2 } = useValidatePw(watch("new_password"));

  const checkToken = async () => {
    const res: any = await dispatch(
      resetPassword({
        password: "check_token",
        new_password: "check_token",
        token,
      })
    );
    if (!res?.payload?.data) {
      const errCode = res?.payload?.error_code;
      if (["TOKEN_RESET_EXPIRED", "TOKEN_INCORRECT"].includes(errCode)) setStatus("expired");
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const res: any = await dispatch(
      resetPassword({ ...data, new_password: md5(data.new_password), token })
    );
    console.log(">>> reset password:", res);
    if (res?.payload?.data) {
      setStatus("success");
    } else {
      const errCode = res?.payload?.error_code;
      if (["TOKEN_RESET_EXPIRED", "TOKEN_INCORRECT"].includes(errCode)) {
        setStatus("expired");
      } else
        setError("new_password", {
          type: "manual",
          message: ERROR_CODE[errCode],
        });
    }
    setIsLoading(false);
  };

  const disabled =
    !watch("password") ||
    !watch("new_password") ||
    !condition1 ||
    !condition2 ||
    isLoading;

  useEffect(() => {
    token && checkToken();
  }, [token]);

  return status == "success" ? (
    <Notify icon={IcSuccess} title="Thiết lập mật khẩu mới thành công" />
  ) : status == "expired" ? (
    <Notify
      icon={IcWarn}
      title="Xin lỗi vì sự bất tiện này!"
      subTitle="Liên kết đã hết hạn. Vui lòng thao tác lại từ đầu."
    />
  ) : (
    <div className="space-y-6 md:space-y-8">
      <HeaderPw
        title="Thiết lập mật khẩu mới"
        desc="Quý khách vui lòng thiết lập mật khẩu mới để sử dụng cho những lần đăng nhập sau"
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-6 md:space-y-8"
      >
        <div className="space-y-4">
          <InputFieldPw
            isRequired
            errors={errors}
            register={register}
            state={errors?.password ? "error" : " "}
            id={"password"}
            name={"password"}
            variant="auth"
            label="Nhập Mật khẩu"
            placeholder="Nhập mật khẩu"
          />
          <InputFieldPw
            isRequired
            errors={errors}
            register={register}
            state={errors?.new_password ? "error" : " "}
            id={"new_password"}
            name={"new_password"}
            variant="auth"
            label="Xác nhận mật khẩu mới"
            placeholder="Nhập mật khẩu mới"
          />
          <div className="text-sm font-normal">
            <p
              className={`${condition1 ? "text-[#8C8C8C]" : "text-red-error"}`}
            >
              • Mật khẩu phải có độ dài từ 7-20 ký tự.
            </p>
            <p
              className={`${condition2 ? "text-[#8C8C8C]" : "text-red-error"}`}
            >
              • Mật khẩu phải có ký tự số (0, 1, 2, ...), chữ hoa (A, B, C,
              ...), chữ thường (a, b, c, ...) và ký tự đặc biệt (@, #, $, ...).
            </p>
          </div>
        </div>
        <Button title="Tiếp tục" disabled={disabled} className="w-full" />
      </form>
    </div>
  );
};

export default ResetPassword;
