import HeaderTitle from "components/header-title/HeaderTitle";
import { useNavigate, useParams } from "react-router-dom";
import FormTelegram from "../integrate/telegram/components/FormTelegram";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/appHook";
import { createIntegrateTelegram } from "store/actions/telegramAction";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";

const AddPaymentTele = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [defaultValues, setDefaultValues] = useState(null);
  const { va_account_no, bank_account } = useParams();

  useEffect(() => {
    if (va_account_no && bank_account)
      setDefaultValues({ bank_account, va_account_no, is_active: "true" });
  }, [va_account_no, bank_account]);

  const onSubmit = async (data: any, config: any) => {
    const res: any = await dispatch(
      createIntegrateTelegram({
        type: "telegram",
        config,
      })
    );
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      CustomToastMessage.success(
        "Thêm tích hợp thành công",
        `Bạn vừa thêm thành công tích hợp ${data.name}.`
      );
      navigate(-1);
    } else
      CustomToastMessage.error(
        "Thêm tích hợp thất bại",
        ERROR_CODE[res?.payload?.error_code]
      );
  };

  return (
    <div className="flex flex-col items-center gap-6 font-inter">
      <HeaderTitle
        onClickTitle={() => navigate("/admin/payment")}
        title="Kênh thanh toán"
        subTitle="Thêm tích hợp Telegram"
      />
      <FormTelegram defaultValues={defaultValues} onSubmit={onSubmit} />
    </div>
  );
};

export default AddPaymentTele;
