import { useNavigate } from "react-router-dom";
import { HeaderTitle, useAppDispatch } from "../vam";
import FormTelegram from "./components/FormTelegram";
import { createIntegrateTelegram } from "store/actions/telegramAction";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";

const defaultValues = {
  bank_account: "all",
  va_account_no: "all",
  is_active: "true",
};

const AddTelegram = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const onSubmit = async (data: any, config: any) => {
    const res: any = await dispatch(
      createIntegrateTelegram({
        type: "telegram",
        config,
      })
    );
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      CustomToastMessage.success(
        "Thêm tích hợp thành công",
        `Bạn vừa thêm thành công tích hợp ${data.name}.`
      );
      navigate(-1);
    } else
      CustomToastMessage.error(
        "Thêm tích hợp thất bại",
        ERROR_CODE[res?.payload?.error_code]
      );
  };

  return (
    <div className="flex flex-col items-center gap-6 font-inter">
      <HeaderTitle
        onClickTitle={() => navigate("/admin/integrate-telegram")}
        title="Tích hợp Telegram"
        subTitle="Thêm tích hợp Telegram"
      />
      <FormTelegram defaultValues={defaultValues} onSubmit={onSubmit}/>
    </div>
  );
};

export default AddTelegram;
