import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { telegramService } from "store/services/telegramService";

export const testNoticeTelegram = createAsyncThunk(
  "telegram/testNoticeTelegram",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await telegramService.testNoticeTelegram(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const getIntegrateTelegramDevices = createAsyncThunk(
  "telegram/getIntegrateTelegramDevices",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await telegramService.getIntegrateTelegramDevices(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const createIntegrateTelegram = createAsyncThunk(
  "telegram/createIntegrateTelegram",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await telegramService.createIntegrateTelegram(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const updateIntegrateTelegram = createAsyncThunk(
  "telegram/updateIntegrateTelegram",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await telegramService.updateIntegrateTelegram(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const deleteIntegrateTelegram = createAsyncThunk(
  "telegram/deleteIntegrateTelegram",
  async (params: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await telegramService.deleteIntegrateTelegram(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const getDetailIntegrateTelegram = createAsyncThunk(
  "telegram/getDetailIntegrateTelegram",
  async (params: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await telegramService.getDetailIntegrateTelegram(params);
      return response
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);
