import { useEffect } from "react";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import axios from "axios";

// Define TypeScript types for clearer code
interface NotificationData {
  title: string;
  body: string;
}

interface EventData {
  data:
    | {
        notification: NotificationData;
      }
    | undefined;
}

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1UoabDtFJ7RqfWCONgMYjTQr0R5ePUzs",
  authDomain: "atomsolutionvam.firebaseapp.com",
  projectId: "atomsolutionvam",
  storageBucket: "atomsolutionvam.appspot.com",
  messagingSenderId: "945741826323",
  appId: "1:945741826323:web:93427a82addc9ff33cb2e6",
  measurementId: "G-BLD4QMK0D8",
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export async function fetchToken() {
  const currentToken = await getToken(messaging, {
    vapidKey:
      "BHOCC_UEh6BnTGW1jQhPiuiXb8JEDzsIq38w4SQ5vOE7zDmMllEUqDnEGYZ4mqmiPllE1CWy0GEaaGafk3AvDtk",
  });
  if (currentToken) {
    console.log("Token firebase: ", currentToken);
    localStorage.setItem("firebaseToken", currentToken);
    return currentToken;
  } else {
    console.log(
      "No registration token available. Request permission to generate one."
    );
  }
}
export default function FirebaseMsg() {
  useEffect(() => {
    requestPermissionAndFetchToken();
    const handleSWMessage = (e: EventData) =>
      handleListenMessageFromServiceWorker(e);
    navigator.serviceWorker.addEventListener("message", handleSWMessage);
    return () =>
      navigator.serviceWorker.removeEventListener("message", handleSWMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function requestPermissionAndFetchToken() {
    try {
      if ("serviceWorker" in navigator && "Notification" in window) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted.");
          localStorage.setItem("noti", "true");

          // Register the service worker and wait for it to become active
          await navigator.serviceWorker.register("/firebase-messaging-sw.js");
          console.log("Service Worker registered successfully.");

          // Ensure the service worker is ready
          await navigator.serviceWorker.ready;
          console.log("Service Worker is active and ready.");

          // Assuming fetchToken is defined and properly handles the subscription
          await fetchToken(); // Pass the registration if needed
          await subscribeToMessages();
        } else {
          console.log("Notification permission denied.");
          localStorage.setItem("noti", "false");
        }
      } else {
        console.error(
          "Service Worker or Notifications are not supported by this browser."
        );
      }
    } catch (error) {
      console.error("Error setting up notifications:", error);
      window.location.reload();
    }
  }

  function subscribeToMessages() {
    onMessage(messaging, (payload: any) => {
      console.log("Message received. ", payload);
      showNotification(
        payload.notification?.title,
        payload.notification?.body,
        payload.notification?.icon
      );
    });
  }

  function showNotification(title: string, body: string, icon: string) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification(title, {
        body: body,
        icon: icon,
        tag: "Atom Solutions",
      });
    });
  }

  async function handleListenMessageFromServiceWorker(e: EventData) {
    if (e.data && e.data.notification) {
      const { title, body } = e.data.notification;
      const message = `${title}. ${body}`.replace(/,/g, "");
      console.log(message);
      speakNotification(message);
    }
  }

  async function speakNotification(textToSpeak: string) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_VOICE}`,
        { text: textToSpeak },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          responseType: "arraybuffer",
        }
      );
      const audioBlob = new Blob([response.data], { type: "audio/mp3" });
      const audio = new Audio(URL.createObjectURL(audioBlob));
      audio.play();
    } catch (error) {
      console.error("Error during speech synthesis:", error);
    }
  }

  return <></>;
}
