import { formatTimeStampToStr } from "lib/date";
import { TFilter } from "types/txn";

export const defaultFilter: TFilter = {
  page: 1,
  page_size: 10,
  status: "all",
  start_time: formatTimeStampToStr(Date.now()),
  end_time: formatTimeStampToStr(Date.now(), true),
  bank_account_no: "",
  bank_name: "",
  channel: "",
  date: "today",
};

export const PAGE = "page";
export const PAGE_SIZE = "page_size";
export const STATUS = "status";
export const START_TIME = "start_time";
export const END_TIME = "end_time";
export const BILL_ID = "bill_id";
export const CUSTOMER_ID = "customer_id";
export const DATE = "date";
