/**
 * Retrieves the date filter data based on the selected value.
 * @param value - The selected date filter value.
 * @returns An array of Date objects representing the date filter data.
 */
import { PAGE, PAGE_SIZE, STATUS } from "constants/txn.constant";
import { SelectDateValue, TFilter, TStatus } from "types/txn";

import {
  formatDateToMilliseconds,
  getLastMonth,
  getSevenDayAgo,
  getThisMonth,
  getThisQuarter,
  getToday,
  getYesterday,
} from "../date";

/**
 * Retrieves the date filter data based on the selected value.
 * @param value - The selected date filter value.
 * @returns An array of Date objects representing the date filter data.
 */
export function getDateFilterData(value: SelectDateValue): number[] {
  let data: Date[] = [];

  switch (value) {
    case "today": {
      data = getToday();
      break;
    }
    case "yesterday": {
      data = getYesterday();
      break;
    }
    case "7_days_ago": {
      data = getSevenDayAgo();
      break;
    }
    case "this_month": {
      data = getThisMonth();
      break;
    }
    case "last_month": {
      data = getLastMonth();
      break;
    }
    case "this_quarter": {
      data = getThisQuarter();
      break;
    }
  }

  const startTime = formatDateToMilliseconds(data[0]);
  const endTime = formatDateToMilliseconds(data[1]);

  return [startTime, endTime];
}

/**
 * Maps the status label based on the status value.
 * @param status - The status value.
 * @returns The corresponding status label.
 */
export function mappingStatusLabel(status: TStatus): string {
  switch (status) {
    case "all":
      return "all";
    case "success":
      return "success";
    case "fail":
      return "fail";
    case "pending":
      return "pending";
  }
}

/**
 * Maps the status color based on the status value.
 * @param status - The status value.
 * @returns The corresponding status color.
 */
export function mappingStatusColor(status: TStatus): string {
  switch (status) {
    case "success":
      return "green";
    case "fail":
      return "red";
    case "pending":
      return "yellow";
    default:
      return "gray";
  }
}

/**
 * Retrieves the filter data from the URL search parameters.
 * @param searchParams - The URL search parameters.
 * @returns The filter data object.
 */
export function retrieveFilterFromURL(searchParams: URLSearchParams): TFilter {
  const [startTime, endTime] = getToday();

  const page = Number(searchParams.get(PAGE)) || 1;
  const pageSize = Number(searchParams.get(PAGE_SIZE)) || 10;
  const status = searchParams.get(STATUS) || "all";
  const startTimeParam =
    Number(searchParams.get("start_time")) ||
    Math.floor(startTime.getTime() / 1000);
  const endTimeParam =
    Number(searchParams.get("end_time")) ||
    Math.floor(endTime.getTime() / 1000);
  const bankAccountNo = searchParams.get("bank_account_no") || "";
  const bankName = searchParams.get("bank_name") || "";
  const date = (searchParams.get("date") || "today") as SelectDateValue;

  return {
    page,
    page_size: pageSize,
    status,
    start_time: startTimeParam,
    end_time: endTimeParam,
    bank_account_no: bankAccountNo,
    bank_name: bankName,
    date,
  };
}
