import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const BoxForm = ({ children }: Props) => {
  return <div className="flex flex-col gap-6 p-6 rounded-2xl border border-Grey-grey-5 sm-max:gap-4">{children}</div>;
};

export default BoxForm;
