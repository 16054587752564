import { createSlice } from "@reduxjs/toolkit";

const deviceSlice = createSlice({
    name: 'device',
    initialState: { device: false },
    reducers: {
      setDevice(state, action) {
        state.device = action.payload;
      },
    },
  });

  export const { setDevice } = deviceSlice.actions;
  export default deviceSlice.reducer;
