import { Label } from '@radix-ui/react-dropdown-menu';
import SelectDateField from './select-date';
import { SelectDateValue } from '../../../types/txn';

type Props = {
  value: SelectDateValue;
  onChange: (value: SelectDateValue, startTime?: number | undefined, endTime?: number | undefined) => void;
};

const ChooseDate = ({ value, onChange }: Props) => {
  return (
    <div className="space-y-1 flex flex-col gap-2">
      <Label className='text-sm font-semibold'>Khung thời gian</Label>
      <SelectDateField value={value} onChange={onChange} />
    </div>
  );
};

export default ChooseDate;
