import fActive from "assets/img/overview/fActive.svg";
import firstLabel from "assets/img/overview/fLabel.svg";
import lActive from "assets/img/overview/lActive.svg";
import lastLabel from "assets/img/overview/l_Lable.svg";
import bgLabel from "assets/img/overview/label.svg";
import lbActive from "assets/img/overview/labelActive.svg";
const labels = [
  {
    id: 0,
    title: "Đăng ký lần đầu",
  },

  {
    id: 1,
    title: "Đăng nhập",
  },
  {
    id: 2,
    title: "Quên mật khẩu",
  },
  {
    id: 3,
    title: "Giao dịch",
  },
  {
    id: 4,
    title: "Ngân hàng",
  },
  {
    id: 5,
    title: "Kênh thanh toán",
  },
  {
    id: 6,
    title: "Tích hợp thiết bị",
  },
  {
    id: 7,
    title: "Thiết lập",
  },
];

const Label = ({ active, setActive, disabled }: any) => {
  return (
    <div className={`my-[21px] flex w-full flex-wrap items-center justify-center gap-2 md:gap-3 ${disabled && 'pointer-events-none'}`}>
      <div
        onClick={() => {
          setActive(0);
        }}
        className="flex h-[42px] w-[160px] cursor-pointer items-center justify-center"
        style={{
          background: `${
            active !== 0 ? `url(${firstLabel})` : `url(${fActive})`
          }  no-repeat`,
        }}
      >
        <span
          className={`${
            active === 0 ? "text-[#fff]" : "text-primary"
          } text-[14px]  font-medium`}
        >
          {labels[0].title}
        </span>
      </div>
      {labels.slice(1, labels.length - 1).map((item, index) => {
        return (
          <div
            onClick={() => {
              setActive(item.id);
            }}
            key={index}
            className="flex h-[42px] w-[160px] cursor-pointer items-center justify-center"
            style={{
              background: `${
                active !== item.id ? `url(${bgLabel})` : `url(${lbActive})`
              }  no-repeat`,
            }}
          >
            <span
              className={`${
                active === item.id ? "text-[#fff]" : "text-primary"
              } text-[14px]  font-medium`}
            >
              {item.title}
            </span>
          </div>
        );
      })}
      <div
        onClick={() => {
          setActive(labels[labels.length - 1].id);
        }}
        className="flex h-[42px] w-[160px] cursor-pointer items-center justify-center"
        style={{
          background: `${
            active !== labels[labels.length - 1].id
              ? `url(${lastLabel})`
              : `url(${lActive})`
          }  no-repeat`,
        }}
      >
        <span
          className={`${
            active === labels[labels.length - 1].id
              ? "text-[#fff]"
              : "text-primary"
          } text-[14px]  font-medium`}
        >
          {labels[labels.length - 1].title}
        </span>
      </div>
    </div>
  );
};

export default Label;
