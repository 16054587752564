import { TErrorStatus } from "types/support";

type StatusCellProps = {
  status: TErrorStatus;
};

const displayStatus = {
  PENDING: {
    text: "Đang xử lý",
    // background: '#EDA61C1A', color: '#FA8C16'
    background: '#1890FF1A', color: '#1890FF'
  },
  SUCCESS: {
    text: "Đã xử lý",
    background: '#2EB5531A', color: '#2EB553'
  },
  FAIL: {
    text: "Xử lý thất bại",
    background: '#ED1C241A', color: '#F5222D'
  }
};

const StatusCell = ({ status }: StatusCellProps) => {
  const { background, color } = displayStatus[status];
  return (
    <div
      style={{background}}
      className="flex w-[114px] items-center gap-x-[6px] rounded-2xl px-3 py-1"
    >
      <div style={{background: color}} className="h-2 w-2 rounded-full" />
      <p style={{color}} className="text-sm font-medium">
        {displayStatus[status].text}
      </p>
    </div>
  );
};

export default StatusCell;
