import { useRef, useState } from 'react';
import { formatDateFromDate, formatDateToMilliseconds, getDaysDifference } from '../../../lib/date';

type CustomSelectDateProps = {
    onClose: () => void;
    onSubmit: (startTime: number, endTime: number) => void;
};

const SelectCustomDate = ({ onClose, onSubmit }: CustomSelectDateProps) => {

    const [startTime, setStartTime] = useState(new Date().toISOString().split('T')[0]);
    const [endTime, setEndTime] = useState(new Date().toISOString().split('T')[0]);

    const startTimeRef = useRef<HTMLInputElement>(null);
    const endTimeRef = useRef<HTMLInputElement>(null);

    function handleSubmit() {
        onSubmit(formatDateToMilliseconds(startTime), formatDateToMilliseconds(endTime));
    }

    function handleClose() {
        onClose();
    }

    return (
        <div className="p-4" onClick={(e) => e.stopPropagation()}>
            <div className="text-base font-semibold leading-5 text-text-secondary">
                Tùy chỉnh
            </div>
            <div className="text-[#B1BECF] text-sm mb-4 mt-3 font-semibold">
                {formatDateFromDate(startTime)} - {formatDateFromDate(endTime)}
            </div>

            <div className="mt4 flex items-center gap-4">
                <div className="flex flex-col gap-1">
                    <label className="font-semibold text-text-secondary">Bắt đầu</label>
                    <div className="relative cursor-pointer">
                        <div
                            onClick={() => startTimeRef.current?.showPicker()}
                            style={{
                                top: '1px',
                                bottom: '1px',
                                left: '1px',
                                padding: '10px 16px',
                                borderTopLeftRadius: 8,
                                borderBottomLeftRadius: 8,
                            }}
                            className="absolute left-0 z-10 flex w-28 items-center bg-white font-medium text-text-secondary "
                        >
                            {formatDateFromDate(startTime)}
                        </div>
                        <input
                            ref={startTimeRef}
                            type="date"
                            name={'start_time'}
                            id={'start_time'}
                            defaultValue={startTime}
                            max={endTime}
                            value={startTime}
                            onChange={(e) => {
                                setStartTime(e.target.value);
                            }}
                            style={{ padding: '10px 16px' }}
                            className="border-gray-primary rounded-lg border"
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-1">
                    <label className="font-semibold text-text-secondary">Kết thúc</label>
                    <div className="relative cursor-pointer">
                        <div
                            onClick={() => endTimeRef.current?.showPicker()}
                            style={{
                                top: '1px',
                                bottom: '1px',
                                left: '1px',
                                padding: '10px 16px',
                                borderTopLeftRadius: 8,
                                borderBottomLeftRadius: 8,
                            }}
                            className="absolute z-10 flex w-28 items-center bg-white font-medium text-text-secondary"
                        >
                            {formatDateFromDate(endTime)}
                        </div>
                        <input
                            ref={endTimeRef}
                            type="date"
                            name={'end_time'}
                            id={'end_time'}
                            defaultValue={endTime}
                            min={startTime}
                            max={endTime}
                            value={endTime}
                            onChange={(e) => {
                                setEndTime(e.target.value);
                            }}
                            style={{ padding: '10px 16px' }}
                            className="border-gray-primary rounded-lg border"
                        />
                    </div>
                </div>
            </div>

            <div className="text-[#B1BECF] gap-[4px] flex text-sm my-4 font-semibold leading">
                Đã chọn <p className='text-[#565E85] font-bold'>{getDaysDifference(startTime, endTime)}{' '}</p> ngày
            </div>

            <div className="flex w-full items-center justify-end gap-6">
                <button className="text-14px font-medium leading-5 text-text-secondary hover:opacity-75" onClick={handleClose}>
                    Hủy
                </button>
                <button className={` text-14px font-medium leading-5 text-primary hover:opacity-75`} onClick={handleSubmit}>
                    Đồng ý
                </button>
            </div>
        </div>
    );
};

export default SelectCustomDate;
