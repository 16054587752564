import { useState } from "react";

import SelectLanguage from "components/dropdown/SelectLanguage";
import { useProfile } from "hooks/useProfile";
import { FaCircleUser } from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import bell from "../../assets/img/navbar/bell.svg";

const Navbar = ({ setOpenSidebar }: any) => {
  const [openMenuNavbar, setOpenMenuNavbar] = useState(false);
  const { data } = useProfile();

  return (
    <div className="sticky top-0 z-10 flex h-[68px] w-full shrink-0 items-center justify-end gap-6 border-b border-[#E8E8E8] bg-white px-4 md:px-6">
      {/* Buttons */}
      <div style={{ flex: 1 }} className="block md:hidden relative z-[60]">
        <FiMenu onClick={() => setOpenSidebar(true)} size={24} />
      </div>

      <div
        className={`${openMenuNavbar ? "flex" : "hidden md:flex"
          } md:bg-transparent absolute top-[100%] w-[100px] flex-col items-end justify-between gap-y-3 rounded-lg bg-white p-[10px] shadow-xl md:relative md:right-0 md:top-0 md:w-[103px] md:flex-row md:items-center md:gap-y-0 md:rounded-none md:p-0 md:shadow-none`}
      >
        {/* Search */}
        {/* <div className="w-6 cursor-pointer">
          <img src={search} alt="search" />
        </div> */}
        {/* Bell */}
        <div className="w-6 cursor-pointer">
          <img src={bell} alt="bell" />
        </div>
        {/* Change Language */}
        <SelectLanguage disabled />
      </div>

      {/* Account */}
      <div
        className="flex cursor-pointer items-center gap-2"
        onClick={() => setOpenMenuNavbar(!openMenuNavbar)}
      >
        {/* Avatar */}
        <FaCircleUser className="h-[40px] w-[40px]" />
        {/* Name */}
        <p className="text-sm font-semibold text-[#0F1D40]">
          {data?.data?.last_name || data?.data?.first_name || "---"}
        </p>
      </div>
    </div>
  );
};

export default Navbar;
