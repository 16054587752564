import { useDisclosure } from "@chakra-ui/hooks";
import ImgOcbBank from "assets/img/bank/ocbbank.png";
import InputField from "components/fields/InputField";
import SelectReportField from "components/fields/SelectReportField";
import { useAppDispatch, useAppSelector } from "hooks/appHook";
import useDebounce from "hooks/useDebounce";
import { useProfile } from "hooks/useProfile";
import {
  toastAddBankSuccess,
  toastConfirmFormBankFail,
  toastMessage,
} from "lib/constants";
import { ERROR_CODE } from "lib/errorCode";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { addBankAccount, getHolderName } from "store/actions/bankAction";
import { selectBankAccountLoading } from "store/reducers/verifyBankSlice";
import ModalDownloadOcb from "../../step1/CreateAccountOCB";
import Button from "components/button/button";

const FORM = {
  BANK_CODE: "bank_code",
  BANK_ACCOUNT_NO: "bank_account_no",
  HOLDER_NAME: "holder_name",
  PHONE: "phone",
  CHECKED_PHONE: "checked_phone",
  TRANSACTION_TYPE_NOTICE: "transaction_type_notice",
};

const FormOcb = ({
  type,
  isOnboard,
}: {
  type: string;
  isOnboard?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectBankAccountLoading);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const debounce = useDebounce(watch(FORM.BANK_ACCOUNT_NO));
  const { data: profile } = useProfile();

  const onFormSubmit = async (value: any) => {
    delete value?.[FORM.CHECKED_PHONE];
    try {
      const checkType = profile.data.account_type;
      const res: any = await dispatch(
        addBankAccount({ ...value, bank_code: type })
      );
      if (res?.meta?.requestStatus === "fulfilled") {
        toastAddBankSuccess({
          bank_code: value.bank_code,
          bank_account_no: value.bank_account_no,
        });
        navigate(isOnboard ? `/register/add-va` : "/admin/bank");
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          toastConfirmFormBankFail();
          return;
        }
        toastMessage.error(msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onErrors = (errors: any) => console.error(errors);

  const getDataHolderName = async () => {
    try {
      const res: any = await dispatch(
        getHolderName({
          bank_code: type,
          bank_account_no: debounce,
        })
      );
      if (
        res?.meta?.requestStatus === "fulfilled" &&
        res?.payload?.data !== ""
      ) {
        setValue(FORM.HOLDER_NAME, res?.payload?.data?.trim());
      } else if (res?.payload?.error_code == "NOT_FOUND") {
        setValue(FORM.HOLDER_NAME, undefined);
      } else
        toastMessage.error(
          ERROR_CODE[res?.payload?.error_code] || "Lỗi không xác định"
        );
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const disabled =
    !watch(FORM.BANK_ACCOUNT_NO) ||
    !watch(FORM.HOLDER_NAME) ||
    !watch(FORM.PHONE) ||
    !watch(FORM.CHECKED_PHONE) ||
    !watch(FORM.TRANSACTION_TYPE_NOTICE) ||
    isLoading;

  useEffect(() => {
    if (!debounce.trim()) {
      setValue(FORM.HOLDER_NAME, "");
      return;
    }
    getDataHolderName();
  }, [debounce]);

  return (
    <>
      <ModalDownloadOcb isOpen={isOpen} onClose={onClose} />

      <form
        onSubmit={handleSubmit(onFormSubmit, onErrors)}
        className="flex h-full flex-col gap-6 2xl:flex-row"
      >
        <div className="flex w-full max-w-[454px] flex-col space-y-4 rounded-[8px] border border-[#D9D9D9] p-[24px]">
          <div className="text-[18px] font-bold leading-[22px]">
            Điền số tài khoản
          </div>
          <div className="space-y-2">
            <InputField
              isRequired
              type="number"
              label="Số tài khoản"
              placeholder="Nhập số tài khoản"
              id={FORM.BANK_ACCOUNT_NO}
              name={FORM.BANK_ACCOUNT_NO}
              extra=""
              register={register}
              errors={errors}
              state={watch(FORM.HOLDER_NAME) == undefined && "error"}
              variant="account"
            />
            <p className="mt-2 text-xs font-normal text-[#262626]">
              Nhập chính xác số tài khoản ngân hàng. Hỗ trợ tài khoản cá nhân và
              doanh nghiệp.
            </p>
          </div>

          <div className="space-y-2">
            <InputField
              readOnly
              isRequired
              label="Tên thụ hưởng"
              placeholder="Tên thụ hưởng"
              id={FORM.HOLDER_NAME}
              name={FORM.HOLDER_NAME}
              extra=""
              register={register}
              errors={errors}
              state={watch(FORM.HOLDER_NAME) == undefined && "error"}
              variant="account"
            />
            <p className="mt-2 text-xs font-normal text-[#262626]">
              Tên thụ hưởng sẽ được tự động điền theo số tài khoản.
            </p>
            {watch(FORM.HOLDER_NAME) == undefined && (
              <p className="mt-2 text-xs font-normal text-red-error">
                {"Không tìm thấy tên chủ tài khoản"}
              </p>
            )}
          </div>

          <div className="space-y-2">
            <InputField
              isRequired
              type="number"
              label="Số điện thoại"
              placeholder="Nhập số điện thoại"
              id={FORM.PHONE}
              name={FORM.PHONE}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
              variant="account"
            />
            <label className="flex items-start gap-[7px]">
              <input
                type="checkbox"
                {...register(FORM.CHECKED_PHONE)}
                id=""
                className="mt-[2px]"
              />
              <p className="[#262626] text-[14px] font-normal leading-[20px]">
                Số điện thoại trên là số điện thoại đã đăng ký với ngân hàng và
                Tôi đồng ý sử dụng số điện thoại trên để xác thực tài khoản
              </p>
            </label>
          </div>

          <div className="space-y-2">
            <SelectReportField
              isRequired
              register={register}
              name={FORM.TRANSACTION_TYPE_NOTICE}
              setValue={setValue}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          </div>

          <Button title="Tiếp tục" disabled={disabled} className="mt-[42px]" />
        </div>

        <div className="flex w-full max-w-[454px] flex-col gap-[16px]">
          <div className="h-fit rounded-[8px] border border-[#D9D9D9] p-[24px]">
            <div className="font-inter text-[18px] font-bold">Hướng dẫn</div>
            <div className="mt-[24px]">
              <p className="[#262626] text-[14px] font-normal leading-[20px]">
                Điền thông tin số tài khoản OCB của bạn. Nếu chưa có tài khoản,
                vui lòng làm theo hướng dẫn sau:
                <br />
                <button
                  type="button"
                  onClick={() => onOpen()}
                  className="font-inter italic text-primary underline"
                >
                  Hướng dẫn mở tài khoản OCB cá nhân trực tuyến.
                </button>
              </p>
            </div>
          </div>

          <div className="w-full flex-1">
            <img
              src={ImgOcbBank}
              alt=""
              className="h-full w-full rounded-2xl"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default FormOcb;
