import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { BtnClose } from "components/button/actionButton";
import Button from "components/button/button";
import InputField from "components/fields/InputField";
import { useAppDispatch } from "hooks/appHook";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { millisToMinutesAndSeconds } from "lib/utils";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PiWarningCircleLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { registerDevice } from "store/actions/deviceAction";
import { setCode } from "store/reducers/codeSlice";
import { setDevice } from "store/reducers/deviceSlice";

export default function DeviceForm(props: { isOpen?: boolean; onClose?: any }) {
  const { isOpen, onClose } = props;
  const [integrateStatus, setIntegrateStatus] = useState(false);
  const code = useSelector((state: any) => state.code);
  const linkNoti = process.env.REACT_APP_NOTIFY_ENDPOINT;
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: linkNoti,
      sharecode: code.share_code,
      pincode: code.bin_code,
    },
    values: {
      link: linkNoti,
      sharecode: code.share_code,
      pincode: code.bin_code,
    },
  });

  const dispatch = useAppDispatch();
  const registerNewDevice = async (data: any) => {
    const firebaseToken = localStorage.getItem("firebaseToken") ?? "";
    const payload = {
      share_code: data.sharecode,
      bin_code: data.pincode,
      firebase_token: firebaseToken,
    };

    const res: any = await dispatch(registerDevice(payload));
    if (res.meta.requestStatus === "fulfilled" && res.payload.data) {
      console.log(res.meta.requestStatus);
      console.log(res.payload.data);
      if (data.sharecode) {
        localStorage.setItem("sharecode", data.sharecode);
      }
      setIntegrateStatus(false);
      CustomToastMessage.success(
        "Thêm tích hợp thành công",
        `Bạn vừa thêm thành công tích hợp ${res.payload.data.va_name}.`
      );
      onClose();
      dispatch(setCode({}));
      setTimeLeft(0);
      dispatch(setDevice(true));
      onClose();
    } else {
      setIntegrateStatus(false);
      CustomToastMessage.error(
        "Xác thực không thành công",
        "Vui lòng kiểm tra lại mã chia sẻ và mã pin"
      );
      onClose();
      dispatch(setCode({}));
      setTimeLeft(0);
      dispatch(setDevice(true));
      onClose();
    }
  };
  const onErrors = (errors: any) => console.error(errors);

  const onSubmit = (data: any) => {
    setIntegrateStatus(true);
    const notificationStatus = localStorage.getItem("noti");
    if (notificationStatus === "false" || !notificationStatus) {
      CustomToastMessage.error(
        "Tích hợp thất bại",
        "Vui lòng cho phép thông báo trên trình duyệt."
      );
      setIntegrateStatus(false);
    } else {
      registerNewDevice(data);
    }
  };
  const [timeLeft, setTimeLeft] = useState(0);
  useEffect(() => {
    if (!code.share_code || !code.bin_code) return;
    setTimeLeft(300000);
    const getDevice5sec = setInterval(() => {
      dispatch(setDevice(true));
    }, 15000);

    return () => {
      clearInterval(getDevice5sec);
    };
  }, [code]);

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1000);
      if (timeLeft <= 1000) {
        dispatch(setCode({}));
        onClose();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size={"xl"}
    >
      <ModalOverlay>
        <ModalContent
          style={{
            borderRadius: 12,
            width: "92.27%",
            maxWidth: 635,
            position: "relative",
          }}
        >
          <div className="flex w-full flex-col gap-6 rounded-2xl bg-white p-4 font-inter md:gap-8 md:p-8">
            <h1 className="text-2xl font-bold text-[#0F1D40] md:text-[32px] md:leading-[44px]">
              Tích hợp thiết bị
            </h1>
            <BtnClose onClick={() => onClose()} />

            <form
              onSubmit={handleSubmit(onSubmit, onErrors)}
              className="flex flex-col gap-8"
            >
              <div className="flex flex-col gap-4">
                <p className="whitespace-normal text-sm font-medium text-[#262626] md:whitespace-nowrap">
                  Truy cập{" "}
                  <a
                    href={linkNoti}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                  >
                    liên kết{" "}
                  </a>
                  bên dưới trên thiết bị quý khách muốn nhận thông báo
                </p>
                <InputField
                  readOnly
                  id="link"
                  name="link"
                  type="text"
                  label="Liên kết"
                  getValues={getValues}
                  copy={true}
                  register={register}
                  errors={errors}
                  state={errors?.link ? "error" : " "}
                />
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-sm font-medium text-[#262626]">
                  Nhập mã chia sẻ và mã pin bên dưới vào trường thông tin tương
                  ứng trên trình duyệt
                </p>
                <InputField
                  readOnly
                  id="share-code"
                  name="sharecode"
                  label="Mã chia sẻ"
                  getValues={getValues}
                  copy={true}
                  register={register}
                  errors={errors}
                  state={errors?.sharecode ? "error" : " "}
                />
                <InputField
                  readOnly
                  id="pin-code"
                  name="pincode"
                  label="Mã pin"
                  getValues={getValues}
                  copy={true}
                  register={register}
                  errors={errors}
                  state={errors?.pincode ? "error" : " "}
                />
              </div>
              <div className="flex items-center gap-[8px]">
                <PiWarningCircleLight className="text-[#505B76]" />
                <div className="space-x-1 text-[14px] font-medium italic leading-[20px] text-[#505B76]">
                  <span>Các mã có hiệu thực trong</span>
                  <span className="text-primary">
                    {millisToMinutesAndSeconds(timeLeft)}
                  </span>
                  <span>giây</span>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Button onClick={onClose} title="Hủy" outline type="button" />
                <Button title="Tích hợp" />
              </div>
              {/* <FirebaeMsg /> */}
            </form>
          </div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
