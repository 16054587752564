import axiosClient from "./axiosClient";

export const integrationService = {
  initKey: () => {
    return axiosClient.get("integration/info");
  },
  getKey: () => {
    return axiosClient.post("integration/gen-key");
  },
  submitIntegration: (param: { callback_url: string }) => {
    return axiosClient.put("integration/info", param);
  },
};
