import { useAppDispatch } from "hooks/appHook";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { handleToastErr } from "lib/utils";
import { testNoticeTelegram } from "store/actions/telegramAction";

const InputFieldWithButton = (props: {
  id: string;
  label?: string;
  extra?: string;
  placeholder?: string;
  variant?: string;
  state?: string;
  readOnly?: boolean;
  disabled?: boolean;
  checked?: boolean;
  name: string;
  type?: string;
  register?: any;
  isRequired?: boolean;
  errors?: any;
  value?: string;
  maxLength?: number;
  onClick?: () => void;
  onChange?: any;
  defaultValue?: string;
  watch?: any;
}) => {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    readOnly,
    disabled,
    checked,
    name,
    register,
    isRequired,
    errors,
    value,
    maxLength,
    onClick,
    onChange,
    defaultValue = "",
    watch,
  } = props;
  const dispatch = useAppDispatch();
  const handleTestNotice = async () => {
    const data = {
      group_id: watch("group_id"),
      topic: Number(watch("topic")) || null,
    };
    const res: any = await dispatch(testNoticeTelegram(data));
    if (res?.meta?.requestStatus === "fulfilled")
      CustomToastMessage.success("Đã gửi tin test", "");
    else CustomToastMessage.error("Gửi tin test thất bại", "");
  };

  return (
    <div className={`${extra} font-inter`}>
      {label && (
        <div className="mb-2 flex items-center gap-[9px] text-sm font-semibold">
          <div
            className={`${variant === "auth" || "telegram" ? "" : "space-x-1"}`}
          >
            <label htmlFor={id} className="text-[#262626]">
              {label}
            </label>
            {isRequired && <span className="text-red-error">*</span>}
          </div>
          <span className="text-[#2790C3]">Xem hướng dẫn</span>
        </div>
      )}
      <div className="relative flex">
        <input
          defaultValue={defaultValue}
          checked={checked}
          onClick={onClick}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled}
          {...register(name, {
            required: {
              value: isRequired,
              message: handleToastErr(id),
            },
          })}
          name={name}
          type={type}
          value={value}
          id={id}
          placeholder={placeholder}
          onKeyDown={(evt) => {
            type === "number" &&
              ["e", "E", "+", ".", ",", "-"].includes(evt.key) &&
              evt.preventDefault();
            if (type == "prefix" && !/^[a-zA-Z0-9]+$/.test(evt.key))
              evt.preventDefault();
          }}
          onPaste={(e) => {
            const pastedContent = e.clipboardData.getData("Text");
            type == "prefix" &&
              !/^[a-zA-Z0-9]+$/.test(pastedContent) &&
              e.preventDefault();
          }}
          onWheel={(event) => type === "number" && event.currentTarget.blur()}
          className={`flex w-full items-center justify-center rounded-l-lg border border-[#E8E8E8] bg-white/0 p-3 text-[14px] text-sm leading-[20px] text-[#0F1D40] outline-none ${
            disabled === true
              ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : errors[name] || state == "error"
              ? "border-red-error text-red-error placeholder:text-red-error dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          } ${type === "radio" ? "h-auto cursor-pointer" : "h-11"}`}
        />
        <button
          disabled={!watch("group_id")}
          type="button"
          onClick={handleTestNotice}
          className="flex items-center gap-2 rounded-r-lg bg-primary px-[12px] py-[8px] disabled:opacity-50 text-primary"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_6093_61779)">
              <path
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="white"
              />
              <path
                d="M3.66084 7.8268L11.3742 4.8528C11.7322 4.72347 12.0448 4.94014 11.9288 5.48147L10.6162 11.6681C10.5188 12.1068 10.2582 12.2135 9.89351 12.0068L7.89351 10.5328L6.92884 11.4621C6.82217 11.5688 6.73218 11.6588 6.52551 11.6588L6.66751 9.62347L10.3742 6.2748C10.5355 6.1328 10.3382 6.05281 10.1255 6.19414L5.54484 9.07814L3.57017 8.46214C3.14151 8.32614 3.13218 8.03347 3.66084 7.8268Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_6093_61779">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className="whitespace-nowrap text-[14px] font-semibold leading-[20px] text-[#FFFFFF]">
            Gửi tin test
          </span>
        </button>
      </div>
      {errors[name] && (
        //Shows if error exist
        <p className="mt-2 text-xs font-normal text-red-error">
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

export default InputFieldWithButton;
