import InputField from "components/fields/InputField";
import InputFieldWithButton from "components/fields/InputFieldWithButton";
import SelectField from "components/fields/SelectField";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "../../vam";
import ExampleNoti from "./ExampleNoti";
import useTelegram from "hooks/useTelegram";

const listStatus = [
  { label: "Kích hoạt", value: "true" },
  { label: "Tạm ngưng", value: "false" },
];

type FormProps = {
  defaultValues?: any;
  onSubmit?: any;
  submitBtnTitle?: string;
};

const FormTelegram = ({
  defaultValues,
  onSubmit,
  submitBtnTitle = "Tích hợp",
}: FormProps) => {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { listBank, listVa, listNotice } = useTelegram({
    bank_account: watch("bank_account"),
    bank_code: watch("bank_code"),
  });

  useEffect(() => {
    if (defaultValues) reset(defaultValues);
  }, [defaultValues]);

  const onFormSubmit = async (data: any) => {
    setLoading(true);
    Object.keys(data).forEach((key) => {
      if (typeof data[key] == "string") data[key] = data[key].trim();
    });
    const config = {
      ...data,
      transaction_type_notice: String(data.transaction_type_notice),
      is_active: data.is_active === "true" ? true : false,
      topic: Number(data.topic),
    };
    onSubmit(data, config);
    setLoading(false);
  };

  const disabled =
    !watch("bank_account") ||
    !watch("va_account_no") ||
    !watch("transaction_type_notice") ||
    !watch("group_id") ||
    !watch("name") ||
    !watch("is_active") ||
    loading;

  return (
    <div className="flex w-full max-w-[1060px] flex-col gap-4 rounded-2xl md:flex-row md:bg-white md:p-8 md:shadow-[0px_8px_11px_-3px_#00000014]">
      {/* <Form /> */}
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="space-y-6 bg-white md:w-[600px] md-max:rounded-2xl md-max:p-4 md-max:shadow-[0px_8px_11px_-3px_#00000014]"
      >
        <div className="flex flex-col gap-6 rounded-2xl border-[#D9D9D9] md:border md:p-6">
          <p className="text-lg font-semibold text-[#0F1D40]">1. Giao dịch</p>
          <SelectField
            formField="transaction_type_notice"
            title="Loại báo cáo giao dịch"
            required
            data={listNotice}
            setValue={setValue}
            placeHolder="Chọn loại báo cáo giao dịch"
            watch={watch}
            defaultValue={watch("transaction_type_notice")}
          />

          <p className="text-lg font-semibold text-[#0F1D40]">2. Cấu hình</p>
          <div className="space-y-4">
            <SelectField
              formField="bank_account"
              title="Tài khoản chính"
              required
              data={listBank}
              // placeHolder="Chọn tài khoản chính"
              hasAllOption
              setValue={setValue}
              watch={watch}
              setValue2={(value: string) => setValue("bank_code", value)}
              defaultValue={watch("bank_account")}
            />
            <SelectField
              formField="va_account_no"
              title="Kênh thanh toán"
              required
              data={listVa}
              hasAllOption
              setValue={setValue}
              // placeHolder="Chọn kênh thanh toán"
              watch={watch}
              defaultValue={watch("va_account_no")}
            />
          </div>

          <p className="text-lg font-semibold text-[#0F1D40]">
            3. Cấu hình Telegram
          </p>
          <div className="space-y-4">
            <InputFieldWithButton
              label="Chat ID"
              name="group_id"
              register={register}
              id="group_id"
              variant="telegram"
              errors={errors}
              isRequired
              placeholder="Ví dụ: 191028181"
              watch={watch}
            />
            <InputField
              label="Topic ID"
              name="topic"
              register={register}
              id="topic"
              variant="telegram"
              errors={errors}
              placeholder="Nhập topic id dành cho nhóm chat có topics"
            />
            <InputField
              label="Tên tích hợp Telegram"
              name="name"
              register={register}
              id="name"
              variant="telegram"
              errors={errors}
              isRequired
              placeholder="Nhập tên tích hợp telegram"
            />
            <SelectField
              formField="is_active"
              title="Trạng thái"
              required
              data={listStatus}
              setValue={setValue}
              watch={watch}
              defaultValue={watch("is_active")}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Button
            title="Hủy"
            type="button"
            outline
            onClick={() => navigate(-1)}
          />
          <Button disabled={disabled} title={submitBtnTitle} />
        </div>
      </form>
      <ExampleNoti />
    </div>
  );
};

export default FormTelegram;
