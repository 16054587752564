import atom from "assets/img/auth/atom-login.png";

const Footer = ({ hideBorder }: { hideBorder?: boolean }) => {
  return (
    <div
      className={`flex items-center justify-center gap-[6px] ${
        !hideBorder && "border-t border-[#E8E8E8]"
      } bg-white py-3`}
    >
      <p className="text-xs font-normal leading-5 text-[#262626]">
        Môi trường thí điểm được hỗ trợ bởi
      </p>
      <img src={atom} alt="unipay" className="h-6 w-auto" />
    </div>
  );
};

export default Footer;
