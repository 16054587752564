import IconChevronDown from "assets/icon/icon-chevron-down.svg";
import { ELanguage, LIST_LANGUAGE } from "lib/constants";
import { useEffect, useState } from "react";

const SelectLanguage = ({ disabled = false }: { disabled?: boolean }) => {
  const [language, setLanguage] = useState<ELanguage>(ELanguage.vi);

  const onChange = (lang: ELanguage) => {
    setLanguage(lang);
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  useEffect(() => {
    const data = localStorage.getItem("lang") as ELanguage;
    if (Boolean(data)) {
      setLanguage(data);
    } else localStorage.setItem("lang", ELanguage.vi);
  }, []);

  return (
    <div className={`${!disabled && 'group'} relative z-10 cursor-pointer transition-all after:inset-x-0 after:top-full after:block after:h-[6px]`}>
      <div className="flex gap-2 rounded-lg bg-Grey-grey-3 px-3 py-2">
        <img src={LIST_LANGUAGE[language]?.icon} alt="icon" />
        <img src={IconChevronDown} alt="icon" />
      </div>

      <div className="invisible absolute right-0 top-full overflow-hidden rounded-lg bg-white shadow-language-popup backdrop-blur-[50px] transition-all group-hover:visible">
        {Object.entries(LIST_LANGUAGE).map(([key, value]) => {
          return (
            <div
              key={key}
              onClick={() => onChange(key as ELanguage)}
              style={{
                backgroundColor: key === language && "#F5F5F5",
                cursor: key === language && "default",
              }}
              className="flex w-[150px] items-center justify-between gap-2.5 p-4 transition-colors hover:bg-Grey-grey-3"
            >
              <span className="whitespace-nowrap font-inter text-sm font-medium leading-tight text-[#262626]">
                {value.label_vi}
              </span>
              <img
                src={value.icon}
                alt="icon"
                className="size-6 rounded-full"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectLanguage;
