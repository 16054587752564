import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { bankService } from "store/services/bankService";

export const getListBank = createAsyncThunk(
  "list-bank/getListBank",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.getListBank(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const getListBankNotice = createAsyncThunk(
  "list-bank/getListBankNotice",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.getListBankNotice(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const getQrDownloadApp = createAsyncThunk(
  "list-bank/getListBankNotice",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.getQrDownloadApp(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const getBankAccount = createAsyncThunk(
  "user/getBankAccount",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.getBankAccount(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addBankAccount = createAsyncThunk(
  "user/addBankAccount",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.addBank(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteBank = createAsyncThunk(
  "delete-bank/deleteBank",
  async (params: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.deleteBank(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const getHolderName = createAsyncThunk(
  "holder-name-bank/getHolderName",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.getHolderName(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const getNoticeType = createAsyncThunk(
  "notice-type-bank/getNoticeType",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.getNoticeType(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const checkRegister = createAsyncThunk(
  "check-bank-register/checkRegister",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.checkRegister(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const getBalance = createAsyncThunk(
  "user/getBalance",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await bankService.getBalance();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
