import React from "react";
import { usePagination } from "../../lib/usePagination";
import {
  LuChevronLast,
  LuChevronFirst,
  LuChevronLeft,
  LuChevronRight,
} from "react-icons/lu";
import { useSelector } from "react-redux";
import { useAppDispatch } from "hooks/appHook";

const Pagination = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const newPaginationRange = paginationRange.length > 0 ? paginationRange : [1];

  // If there are less than 2 times in pagination range we shall not render the component
  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = newPaginationRange[newPaginationRange.length - 1];

  const onLast = () => {
    onPageChange(lastPage);
  };

  const firstPage = paginationRange[0];

  const onFirst = () => {
    onPageChange(firstPage);
  };

  return (
    <ul className={`pagination-container`}>
      {/* Left navigation arrow */}
      <li
        className={`pagination-item ${
          currentPage === 1 ? "disabled" : "text-primary"
        }`}
        onClick={onFirst}
      >
        <LuChevronFirst width={24} height={24} />
      </li>
      {/* previous button */}
      <li
        className={`pagination-item ${
          currentPage === 1 ? "disabled" : "text-primary"
        }`}
        onClick={onPrevious}
      >
        <LuChevronLeft width={24} height={24} />
      </li>
      {/* Pagination items */}
      {newPaginationRange.map((pageNumber, index) => {
        // If the page number is a dot, render the dots div
        if (pageNumber === "...") {
          return (
            <li className="pagination-item dots" key={index}>
              &#8230;
            </li>
          );
        }

        // Render our Page Pills
        return (
          <li
            className={`pagination-item ${
              pageNumber === currentPage ? "active" : "pagination-item-hidden"
            }`}
            key={index}
            onClick={() => onPageChange(pageNumber)}
          >
            <p>{pageNumber}</p>
          </li>
        );
      })}
      {/* next button */}
      <li
        className={`pagination-item ${
          currentPage === lastPage ? "disabled" : "text-primary"
        }`}
        onClick={onNext}
      >
        <LuChevronRight width={24} height={24} />
      </li>
      {/* right navigation arrow */}
      <li
        className={`pagination-item ${
          currentPage === lastPage ? "disabled" : "text-primary"
        }`}
        onClick={onLast}
      >
        <LuChevronLast width={24} height={24} />
      </li>
    </ul>
  );
};

export default Pagination;
