import Button from "components/button/button";
import ic_success from "assets/img/auth/ic_success.svg";
import warn from "assets/svg/warn.svg";
import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/modal";

interface IProps {
  title: string;
  type: "success" | "error" | '';
  desc?: string;
  onClose: () => void;
  isOpen?: boolean
}
const Notify = ({ title, type, desc, onClose, isOpen }: IProps) => {
  const Icon = type === "success" ? ic_success : warn;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay>
        <ModalContent
          style={{
            borderRadius: 16,
            width: "92.27%",
            maxWidth: 428,
            padding: "32px 24px",
            position: "relative",
          }}
        >
          <div className="flex w-full flex-col gap-8 rounded-2xl bg-white font-inter">
            <div className="flex flex-col items-center gap-6">
              <img src={Icon} alt="warn" className="h-[48px] w-[48px]" />
              <div className="flex flex-col gap-[12px] text-center font-inter text-[#0A203D]">
                <h1 className="text-xl font-bold">{title}</h1>
                <p className="text-sm font-normal">{desc}</p>
              </div>
            </div>
            <Button title="Đóng" outline onClick={onClose} />
          </div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default Notify;
