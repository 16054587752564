import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "hooks/appHook";
import { useProfile } from "hooks/useProfile";
import useWebSocket from "hooks/useWebSocket";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { addBankAccount } from "store/actions/bankAction";
import * as yup from "yup";

import Button from "components/button/button";
import InputField from "components/fields/InputField";
import SelectReportField from "components/fields/SelectReportField";
import DetailChannel from "views/admin/payment/components/DetailChannel";
import QrDownload from "./QrDownload";
import QrVerify from "./QrVerify";

import ImgBvbBusiness from "assets/img/bank/bvbank-business.png";
import ImgBvbNotBusiness from "assets/img/bank/bvbank-not-business.jpg";
import Consent from "./consent-popup";

const FormBVB = ({
  type,
  isOnboard,
}: {
  type: string;
  isOnboard?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [VA, setVA] = useState<any>();
  const [showModal, setShowModal] = useState<
    "verify" | "download" | "channel" | "consent" | false
  >(false);
  const [bankData, setBankData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [qrVerify, setQrVerify] = useState("");
  const { data: profile } = useProfile();
  const isBusiness = profile?.data?.account_type === "BUSINESS";
  const schema = yup.object({
    bank_code: yup.string().trim(),
    bank_account_no: yup.string().trim().required("Vui lòng nhập số tài khoản"),
    phone: isBusiness
      ? yup.string().trim()
      : yup.string().trim().required("Vui lòng nhập số điện thoại"),
    checked_rule: yup
      .boolean()
      .required("Vui lòng đồng ý sử dụng số điện thoại"),
    transaction_type_notice: yup
      .string()
      .trim()
      .required("Vui lòng chọn loại báo cáo giao dịch"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (value: any) => {
    setBankData({ ...value, bank_code: type });
    setIsLoading(true);
    try {
      const res: any = await dispatch(
        addBankAccount({ ...value, bank_code: type })
      );
      if (res?.meta?.requestStatus === "fulfilled") {
        setQrVerify(res?.payload?.data?.qr_code);
        setShowModal("verify");
      } else {
        if (
          ["CUSTOMER_NOT_FOUND_VCCB", "ACCOUNT_CLOSED_UNREGISTERED"].includes(
            res?.payload?.error_code
          )
        ) {
          CustomToastMessage.error(
            "Thêm ngân hàng thất bại",
            ERROR_CODE[res?.payload?.error_code](isBusiness)
          );
          setShowModal("download");
        } else
          CustomToastMessage.error(
            "Thêm ngân hàng thất bại",
            ERROR_CODE[res?.payload?.error_code]
          );
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const { data } = useWebSocket({
    start: showModal == "verify",
    event: watch("bank_account_no"),
    handleEvent: (data) => {
      if (data.id) {
        CustomToastMessage.success(
          "Xác thực thành công",
          "Bạn đã xác thực sử dụng dịch vụ thành công."
        );
        setShowModal(false);
        if (!isOnboard) {
          setVA({ id: data.id });
          setShowModal("channel");
        } else navigate(`/register/transfer-va/${data.id}`);
      }
    },
  });

  const disabled =
    !watch("bank_account_no") ||
    (!isBusiness && !watch("phone")) ||
    !watch("checked_rule") ||
    !watch("transaction_type_notice") ||
    isLoading;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full flex-wrap gap-6"
    >
      <div className="flex w-full flex-col space-y-4 rounded-[8px] border border-[#D9D9D9] p-4 md:p-6 lg:max-w-[300px] xl:max-w-[454px]">
        <div className="text-[18px] font-bold leading-[22px]">
          Điền số tài khoản
        </div>
        <InputField
          isRequired
          type="number"
          info="Nhập chính xác số tài khoản ngân hàng. 
                Hỗ trợ tài khoản cá nhân và doanh nghiệp."
          label="Số tài khoản"
          placeholder="Nhập số tài khoản"
          id={"bank_account_no"}
          name={"bank_account_no"}
          register={register}
          errors={errors}
          variant="account"
        />

        {!isBusiness && (
          <div className="space-y-2">
            <InputField
              isRequired
              type="number"
              label="Số điện thoại"
              placeholder="Nhập số điện thoại"
              id={"phone"}
              name={"phone"}
              register={register}
              errors={errors}
              variant="account"
            />
            <p className="text-sm font-normal">
              Số điện thoại trên là số điện thoại đã đăng ký với ngân hàng và
              Tôi đồng ý sử dụng số điện thoại trên để xác thực tài khoản.
            </p>
          </div>
        )}

        <div className="space-y-2">
          <SelectReportField
            isRequired
            register={register}
            name={"transaction_type_notice"}
            setValue={setValue}
            errors={errors}
          />

          <div
            onClick={() =>
              watch("checked_rule")
                ? setValue("checked_rule", false)
                : setShowModal("consent")
            }
            className="flex cursor-pointer items-start gap-2"
          >
            <input
              type="checkbox"
              {...register("checked_rule")}
              className="h-5 w-5 cursor-pointer rounded border border-[#BFBFBF]"
              onClick={(e) => {
                watch("checked_rule") && e.stopPropagation();
                setValue("checked_rule", false);
              }}
            />
            <div className="text-sm font-normal text-[#262626]">
              Tôi đã đọc và đồng ý{" "}
              <a
                href={
                  process.env.REACT_APP_REDIRECT_REGISTER +
                  (localStorage.getItem("language") ||
                    process.env.REACT_APP_DEFAULT_LANGUAGE) +
                  "/privacy-policy"
                }
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className="font-inter italic text-[#1890FF] underline"
              >
                Điều khoản và Điều kiện
              </a>{" "}
              về sử dụng Dịch vụ chấp nhận thanh toán mã QR
            </div>
          </div>
        </div>

        <div className="mt-[42px] flex gap-4">
          {!isOnboard && (
            <Button
              onClick={() => navigate("/admin/bank/create")}
              title="Hủy"
              type="button"
              outline
              className="flex-1"
            />
          )}
          <Button title="Tiếp tục" disabled={disabled} className="flex-1" />
        </div>
      </div>
      <div className="flex-[1_0_0] lg:max-w-[534px]">
        <img
          src={isBusiness ? ImgBvbBusiness : ImgBvbNotBusiness}
          alt="banner"
          className="rounded-2xl"
        />
      </div>
      <QrDownload
        isOpen={showModal == "download"}
        bankData={bankData}
        setQrVerify={setQrVerify}
        setShowModal={setShowModal}
      />
      <QrVerify
        isOpen={showModal == "verify"}
        onClose={() => setShowModal(false)}
        data={qrVerify}
      />
      <Consent
        isOpen={showModal == "consent"}
        onClose={() => setShowModal(false)}
        onClickBtn={() => setValue("checked_rule", true)}
      />
      {showModal == "channel" && (
        <div className="no-scrollbar fixed inset-0 z-50 overflow-auto bg-[#000000B2]">
          <DetailChannel setShow={setShowModal} selectedChannel={VA} />
        </div>
      )}
    </form>
  );
};

export default FormBVB;
