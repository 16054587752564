import { useAppDispatch } from "hooks/appHook";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { updatePaymentChannel } from "store/actions/paymentChannelAction";

import Button from "components/button/button";

import { BtnClose } from "components/button/actionButton";
import { Tooltip } from "@chakra-ui/tooltip";

const FrmUpdatePChannel = ({
  selectedChannel,
  setShowEditModal,
  handleListChannel,
}: any) => {
  const { success, error } = CustomToastMessage;
  const dispatch = useAppDispatch();
  const [name, setName] = useState(selectedChannel.name);
  const [errorPaymentName, setErrorPaymentName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit } = useForm();

  const onFormSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      if (name.trim() === "") {
        setErrorPaymentName("Vui lòng nhập tên");
        return;
      }

      const res: any = await dispatch(
        updatePaymentChannel({ name, channel_id: selectedChannel.id })
      );
      if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
        setShowEditModal(false);
        handleListChannel();
        success(
          "Chỉnh sửa kênh thanh toán thành công",
          `Bạn vừa sửa thành công kênh thanh toán ${selectedChannel.name} thành ${name}.`
        );
      } else
        error(
          "Chỉnh sửa kênh thanh toán thất bại",
          ERROR_CODE[res?.payload?.error_code] || res?.payload?.msg
        );
      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
    }
  };

  const onErrors = (errors: any) => console.error(errors);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#000000B2]">
      <form
        onSubmit={handleSubmit(onFormSubmit, onErrors)}
        className="relative flex w-[92.27%] max-w-[480px] flex-col gap-6 rounded-2xl bg-white p-4 text-[#0F1D40] md:gap-8 md:p-10"
        style={{
          boxShadow:
            "0px 1px 10px 0px rgba(0, 0, 0, 0.04), 0px 1px 10px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        {/* Close Icon */}
        <BtnClose onClick={() => setShowEditModal(false)} />

        {/* Header */}
        <h3 className="text-xl font-bold">Chỉnh sửa kênh thanh toán</h3>

        <div className="space-y-4 rounded-xl border border-[#D9D9D9] px-4 py-6 md:p-6">
          {/* Name */}
          <div className="space-y-2">
            <p className="text-sm font-semibold">
              Tên kênh<span className="text-red-error">*</span>
            </p>
            <input
              placeholder="Nhập tên kênh thanh toán"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full rounded-lg border border-[#D9DBE9] p-3 text-[#0F1D40] outline-none"
            />
            {errorPaymentName && (
              <p className="text-xs font-normal text-red-error">
                {errorPaymentName}
              </p>
            )}
          </div>
          <div className="flex items-center gap-4 text-sm">
            <span className="w-[max-content] font-semibold">
              Tài khoản QR:{" "}
            </span>
            <p>
              <span className="font-semibold text-primary">
                {selectedChannel.prefix_va}
              </span>
              {selectedChannel.va_account_no.replace(
                selectedChannel.prefix_va,
                ""
              )}
            </p>
          </div>
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2 text-sm">
            <span className="font-semibold">Thông tin ngân hàng: </span>
            <div className="flex items-center gap-2">
              <img
                src={selectedChannel.bank_logo}
                alt="bank_logo"
                className="h-[25px] w-auto"
              />
              <p>-</p>
              <Tooltip
                label={selectedChannel.holder_name}
                hasArrow
                fontSize={12}
                fontWeight={400}
                lineHeight={5}
                fontFamily={"Montserrat"}
                bg="#262626"
                px={3}
                py={1.5}
                borderRadius={8}
                color="white"
                placement="top"
                textAlign={"center"}
              >
                <p className="line-clamp-1">{selectedChannel.holder_name}</p>
              </Tooltip>
              <p>-</p>
              <p>{selectedChannel.bank_account_no}</p>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="grid grid-cols-2 gap-4">
          <Button
            onClick={() => setShowEditModal(false)}
            type="button"
            title="Hủy"
            outline
          />
          <Button
            disabled={isLoading || !name}
            className="text-white"
            title="Cập nhật"
          />
        </div>
      </form>
    </div>
  );
};

export default FrmUpdatePChannel;
