import { createAsyncThunk } from "@reduxjs/toolkit";
import { supportService } from "store/services/supportService";

export const getTicketList = createAsyncThunk(
    "support/getTicketList",
    async (params: any, { dispatch, getState, rejectWithValue }) => {
      try {
        const response = await supportService.getTickets(params);
        return response;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  );

  export const createTicket = createAsyncThunk(
    "support/createTicket",
    async (params: FormData, { dispatch, getState, rejectWithValue }) => {
      try {
        const response = await supportService.createTicket(params);
        return response;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  );