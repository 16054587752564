import { useProfile } from "hooks/useProfile";
import React, { useState } from "react";

import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import Button from "components/button/button";

import "assets/css/ModalCustom.css";

import ImgDigibiz from "assets/img/bank/digibiz.png";
import ImgDigimi from "assets/img/bank/digimi.png";
import QrDigimi from "assets/img/bank/qr-download-digimi.png";
import QrDigibiz from "assets/img/bank/qr-download-digibiz.jpg";

import { BtnClose } from "components/button/actionButton";
import { useAppDispatch } from "hooks/appHook";
import { addBankAccount } from "store/actions/bankAction";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { ERROR_CODE } from "lib/errorCode";

interface IProps {
  isOpen: boolean;
  bankData?: any;
  setQrVerify: any;
  setShowModal: any;
}
const QrDownload: React.FC<IProps> = ({
  isOpen,
  bankData,
  setQrVerify,
  setShowModal,
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { data: profile } = useProfile();
  const accountType =
    profile?.data?.account_type === "BUSINESS"
      ? {
          title: "Digibiz",
          image: ImgDigibiz,
        }
      : {
          title: "Digimi",
          image: ImgDigimi,
        };

  const handleClick = async () => {
    setIsLoading(true);
    const res: any = await dispatch(addBankAccount(bankData));
    if (res?.meta?.requestStatus === "fulfilled") {
      setQrVerify(res?.payload?.data?.qr_code);
      setShowModal("verify");
    } else {
      CustomToastMessage.error(
        "Thêm ngân hàng thất bại",
        ERROR_CODE[res?.payload?.error_code]
      );
    }
    setIsLoading(false);
  };
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={() => setShowModal(false)}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: 16,
          width: "92.27%",
          maxWidth: 400,
          padding: "32px 24px",
          position: "relative",
        }}
      >
        <div className="flex w-full flex-col items-center gap-8 font-inter">
          <BtnClose onClick={() => setShowModal(false)} />
          <div className="flex flex-col items-center gap-2 text-[#0A203D]">
            <h4 className="text-xl font-bold">Tải ứng dụng</h4>
            <p className="text-center text-sm">
              Bạn chưa cài đặt ứng dụng{" "}
              <span className="font-bold text-primary">
                {accountType.title}
              </span>
              . Di chuyển camera đến vùng chứa mã QR bên dưới.
            </p>
          </div>

          <img
            src={accountType.title == 'Digibiz' ? QrDigibiz : QrDigimi}
            alt="qr"
            className="h-[220px] w-[220px] object-contain"
          />
          <img
            src={accountType.image}
            alt="image"
            className="h-8 w-auto object-contain"
          />

          <Button
            disabled={isLoading}
            onClick={handleClick}
            title={`Đã hoàn tất cài đặt ứng dụng`}
            className="text-nowrap w-full md:w-[277px]"
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default QrDownload;
