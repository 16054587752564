import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { settingService } from "store/services/settingService";


export const updateInfoAccount = createAsyncThunk(
  "setting/updateInfoAccount",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await settingService.updateInfoAccount(params);
      return response;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const settingPassword = createAsyncThunk(
  "setting/settingPassword",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await settingService.settingPassword(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
