// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      /* overflow: hidden; */
      display: none;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 16px;
      background: white;
      box-shadow: 0px 5px 16px 0px #080F340F;
    }
    
    table td {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .8em;
      text-align: right;
      height: min-content !important;
      padding: 0px !important;
      /* max-width: 360px; */
    }
    
    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #8C8C8C;
      text-align: left;
      /* width: 240px; */
    }
    
    table tr:last-child {
      border-bottom: 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/table.css"],"names":[],"mappings":"AAAA;IACI;MACE,YAAY;MACZ,mBAAmB;MACnB,WAAW;MACX,YAAY;MACZ,sBAAsB;MACtB,aAAa;MACb,UAAU;MACV,kBAAkB;MAClB,UAAU;IACZ;;IAEA;MACE,aAAa;MACb,sBAAsB;MACtB,SAAS;MACT,aAAa;MACb,kBAAkB;MAClB,mBAAmB;MACnB,iBAAiB;MACjB,sCAAsC;IACxC;;IAEA;MACE,aAAa;MACb,8BAA8B;MAC9B,mBAAmB;MACnB,eAAe;MACf,iBAAiB;MACjB,8BAA8B;MAC9B,uBAAuB;MACvB,sBAAsB;IACxB;;IAEA;MACE;;;OAGC;MACD,yBAAyB;MACzB,eAAe;MACf,iBAAiB;MACjB,gBAAgB;MAChB,cAAc;MACd,gBAAgB;MAChB,kBAAkB;IACpB;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":["@media screen and (max-width: 600px) {\n    table thead {\n      border: none;\n      clip: rect(0 0 0 0);\n      height: 1px;\n      margin: -1px;\n      /* overflow: hidden; */\n      display: none;\n      padding: 0;\n      position: absolute;\n      width: 1px;\n    }\n    \n    table tr {\n      display: flex;\n      flex-direction: column;\n      gap: 16px;\n      padding: 16px;\n      border-radius: 8px;\n      margin-bottom: 16px;\n      background: white;\n      box-shadow: 0px 5px 16px 0px #080F340F;\n    }\n    \n    table td {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      font-size: .8em;\n      text-align: right;\n      height: min-content !important;\n      padding: 0px !important;\n      /* max-width: 360px; */\n    }\n    \n    table td::before {\n      /*\n      * aria-label has no advantage, it won't be read inside a table\n      content: attr(aria-label);\n      */\n      content: attr(data-label);\n      font-size: 14px;\n      line-height: 20px;\n      font-weight: 500;\n      color: #8C8C8C;\n      text-align: left;\n      /* width: 240px; */\n    }\n    \n    table tr:last-child {\n      border-bottom: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
