import { createAsyncThunk } from "@reduxjs/toolkit";
import { paymentChannelService } from "store/services/paymentChannelService";

export const getListPaymentChannel = createAsyncThunk(
    "channel-payment",
    async (params: any, { dispatch, getState, rejectWithValue }) => { 
        try {
            const response = await paymentChannelService.getListPaymentChannel(params);
            return response;
        } catch (err) { 
            return rejectWithValue(err);
        }
    }
)

export const getVA = createAsyncThunk(
    "channel-payment",
    async (params: any, { dispatch, getState, rejectWithValue }) => { 
        try {
            const response = await paymentChannelService.getVA(params);
            return response;
        } catch (err) { 
            return rejectWithValue(err);
        }
    }
)

export const createPChannel = createAsyncThunk(
    "channel-payment",
    async ( formData: any, { dispatch, getState, rejectWithValue }) => { 
        try {
            const response = await paymentChannelService.createPChannel(formData);
            return response;
        } catch (err) { 
            return rejectWithValue(err);
        }
    }
)

export const updatePaymentChannel = createAsyncThunk(
    "channel-payment",
    async (params: any, { dispatch, getState, rejectWithValue }) => { 
        try {
            const response = await paymentChannelService.updatePaymentChannel(params);
            return response;
        } catch (err) { 
            return rejectWithValue(err);
        }
    }
)

export const deletePaymentChannel = createAsyncThunk(
    "channel-payment",
    async (params: any, { dispatch, getState, rejectWithValue }) => { 
        try {
            const response = await paymentChannelService.deletePaymentChannel(params);
            return response;
        } catch (err) { 
            return rejectWithValue(err);
        }
    }
)

export const verifyChannel = createAsyncThunk(
    "channel-payment",
    async (params: any, { dispatch, getState, rejectWithValue }) => { 
        try {
            const response = await paymentChannelService.verifyChannel(params);
            return response;
        } catch (err) { 
            return rejectWithValue(err);
        }
    }
)
