import { Highlight, LinkText, SectionWrap } from "../common";

const Sections = () => {
  return (
    <div className="flex flex-col gap-6">
      <SectionWrap title="Điều 1. Phạm vi điều chỉnh" titleSize="base" tabSpace>
        Bản điều khoản điều kiện này áp dụng cho Khách hàng là cá nhân/hộ kinh
        doanh và tổ chức có đăng ký sử dụng Dịch vụ chấp nhận thanh toán bằng mã
        QR của ATOM SOLUTION liên kết với BVBank.
      </SectionWrap>

      <SectionWrap
        title="Điều 2. Giải thích từ ngữ và viết tắt"
        titleSize="base"
        tabSpace
      >
        <Highlight semibold text="Mã QR/QR Code:" /> Là một phương thức mã hóa
        các thông tin trong giao dịch thanh toán bao gồm nhưng không giới hạn:
        thông tin Khách hàng (KH), thông tin sản phẩm dịch vụ, thông tin đơn
        hàng, thông tin thanh toán và/hoặc các thông tin liên quan khác (nếu
        có). <br />
        <Highlight
          semibold
          text="Dịch vụ chấp nhận thanh toán mã QR (“Dịch vụ”):"
        />{" "}
        Là dịch vụ chấp nhận thanh toán qua tài khoản thanh toán được ATOM
        SOLUTION hợp tác với BVBank (Ngân hàng Thương mại cổ phần Bản Việt).{" "}
        <br />
        <Highlight semibold text="Tài khoản QR:" /> Là mã tài khoản thanh toán
        được ATOM SOLUTION cấp cho KH theo cấu trúc đã thống nhất với BVBank khi
        KH đăng ký sử dụng Dịch vụ của ATOM SOLUTION và được gắn với thông tin
        số TKTT mà KH cung cấp nhằm để xác định KH, TKTT nhận tiền của KH trong
        các mã QR thanh toán. <br />
        <Highlight semibold text="Thanh toán bằng mã QR:" /> Là giao dịch thanh
        toán hàng hóa, dịch vụ thông qua phương thức chuyển tiền/thanh toán bằng
        mã QR trên các Ứng dụng thanh toán. <br />
      </SectionWrap>

      <SectionWrap title="Điều 3. Nội dung Dịch vụ" titleSize="base">
        <SectionWrap
          title="1. Đối tượng khách hàng và điều kiện sử dụng Dịch vụ"
          tabSpace
        >
          Là cá nhân, hộ kinh doanh cá thể (có năng lực hành vi dân sự đầy đủ)
          hoặc tổ chức hoạt động kinh doanh hàng hóa, dịch vụ hợp pháp tại Việt
          Nam có nhu cầu sử dụng Dịch vụ của ATOM SOLUTION.
        </SectionWrap>
        <SectionWrap title="2. Đăng ký Dịch vụ" tabSpace>
          Người đại diện hợp pháp/Người được ủy quyền sử dụng TKTT (tài khoản
          thanh toán) của KH có thể đăng ký sử dụng Dịch vụ hoặc chấm dứt sử dụng
          Dịch vụ thông qua Nền tảng của ATOM SOLUTION sẽ được gửi tới BVBank là
          chính xác, hợp pháp, hợp lệ và/hoặc các phương thức khác được triển
          khai từng thời kỳ.
        </SectionWrap>
        <SectionWrap
          title="3. Mã QR và nguyên tắc khởi tạo Tài khoản QR"
          tabSpace
        >
          Mã QR được tích hợp vào nền tảng/hệ thống/hạ tầng/phần mềm quản lý bán
          hàng/ thiết bị bán hàng/ quản lý giao dịch thanh toán của KH được in
          sẵn đặt tại các điểm kinh doanh của KH hoặc được khởi tạo mỗi khi KH
          yêu cầu thanh toán theo tiêu chuẩn VietQR của NAPAS và tiêu chuẩn cơ
          sở cho mã QR do NHNN Việt Nam ban hành từng thời kỳ. <br />
          <Highlight semibold text="Số tài khoản QR:" /> là một chuỗi 18 ký tự
          số và/hoặc chữ, được hệ thống ATOM SOLUTION khởi tạo khi KH đăng ký
          Dịch vụ với ATOM SOLUTION. <br />
          <Highlight semibold text="Tên tài khoản QR:" /> Tên Tài khoản QR được
          mặc định là Tên Chủ TKTT được liên kết với Tài khoản QR (đối với KH cá
          nhân) và theo yêu cầu của KH (đối với KH là tổ chức). <br />
        </SectionWrap>
        <SectionWrap title="4. Quy định giao dịch chấp nhận thanh toán bằng mã QR" />
      </SectionWrap>

      <SectionWrap title="Điều 4. Phí dịch vụ" titleSize="base" tabSpace>
        Theo Biểu phí (nếu có) được BVBank quy định trong từng thời kỳ và thông
        báo công khai tại các website chính thức của BVBank, ứng dụng NHĐT khi
        KH đăng ký Dịch vụ, các kênh giao dịch của ATOM SOLUTION và/hoặc các
        kênh thông báo khác của BVBank.
      </SectionWrap>

      <SectionWrap
        title="Điều 5. Thu thập, xử lý dữ liệu cá nhân"
        titleSize="base"
        tabSpace
      >
        Việc KH xác nhận Bản điều khoản điều kiện này cũng đồng nghĩa với việc
        KH đã được nhận thông báo, đọc hết, hiểu rõ và chấp thuận toàn bộ Bản
        điều khoản điều kiện bảo vệ và xử lý dữ liệu cá nhân của ATOM SOLUTION
        được đăng tải trên <Highlight text="“Thỏa thuận người dùng”" /> được
        ATOM niêm yết công khai tại địa chỉ:{" "}
        <LinkText text="https://atomsolution.vn/vi/user-agreement" /> và{" "}
        <Highlight text="“Chính sách bảo mật thông tin mở rộng”" /> được ATOM
        niêm yết công khai tại địa chỉ:{" "}
        <LinkText text="https://atomsolution.vn/vi/privacy-policy" />. Các thỏa
        thuận và chính sách này sẽ được cập nhật và chỉnh sửa để phù hợp với
        luật pháp Việt Nam, các quy định liên quan của các cơ quan quản lý nhà
        nước và BVBank cung cấp dịch vụ cho ATOM SOLUTION theo từng thời kì.
      </SectionWrap>

      <SectionWrap
        title="Điều 6. Quyền và trách nhiệm của KH"
        titleSize="base"
        tabSpace
      >
        Được quy định chi tiết trong{" "}
        <Highlight text="“Thỏa thuận người dùng”" /> được ATOM niêm yết công
        khai tại địa chỉ:{" "}
        <LinkText text="https://atomsolution.vn/vi/user-agreement" /> và{" "}
        <Highlight text="“Chính sách bảo mật thông tin mở rộng”" /> được ATOM
        niêm yết công khai tại địa chỉ:{" "}
        <LinkText text="https://atomsolution.vn/vi/privacy-policy" />.
      </SectionWrap>

      <SectionWrap
        title="Điều 7. Quyền và nghĩa vụ của ATOM SOLUTION"
        titleSize="base"
        tabSpace
      >
        Được quy định chi tiết trong{" "}
        <Highlight text="“Thỏa thuận người dùng”" /> được ATOM niêm yết công
        khai tại địa chỉ:{" "}
        <LinkText text="https://atomsolution.vn/vi/user-agreement" /> và{" "}
        <Highlight text="“Chính sách bảo mật thông tin mở rộng”" /> được ATOM
        niêm yết công khai tại địa chỉ:{" "}
        <LinkText text="https://atomsolution.vn/vi/privacy-policy" />.
      </SectionWrap>

      <SectionWrap
        title="Điều 8. Thời hạn hiệu lực và điều khoản thi hành"
        titleSize="base"
        tabSpace
      >
        Bản điều khoản điều kiện này có hiệu lực kể từ ngày KH xác nhận đã đọc,
        hiểu rõ và đồng ý với tất cả các điều kiện và điều khoản được nêu.
        <br />
        KH cam kết thực hiện đúng theo Bản điều khoản điều kiện này cũng như các
        quy định của ATOM SOLUTION cho đến khi có sự kiện pháp lý làm chấm dứt
        Bản điều khoản điều kiện. Các quy định, thông tin chi tiết khác nhằm
        giải thích, diễn giải, quy định cụ thể của các điều khoản trong chính
        sách này và các sản phẩm, dịch vụ do ATOM SOLUTION cung cấp được quy
        định tại <Highlight text="“Thỏa thuận người dùng”" /> được ATOM SOLUTION
        niêm yết công khai tại địa chỉ:{" "}
        <LinkText text="https://atomsolution.vn/vi/user-agreement" /> và{" "}
        <Highlight text="“Chính sách bảo mật thông tin mở rộng”" /> được ATOM
        SOLUTION niêm yết công khai tại địa chỉ:{' '}
        <LinkText text="https://atomsolution.vn/vi/privacy-policy" />. Các thỏa
        thuận và chính sách này sẽ được cập nhật và chỉnh sửa để phù hợp với
        luật pháp Việt Nam, các quy định liên quan của các cơ quan quản lý nhà
        nước và đối tác cung cấp dịch vụ cho ATOM SOLUTION theo từng thời kì.
      </SectionWrap>
    </div>
  );
};

export default Sections;
