import { useAppDispatch } from './appHook';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorage } from 'lib/LocalStorage';
import { getBankAccount } from 'store/actions/bankAction';
import { getListPaymentChannel } from 'store/actions/paymentChannelAction';

export const useOnboardRoute = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const route = useLocation().pathname
    // console.log('>>> route: ', route)

    const checkBank = async () => {
        const res: any = await dispatch(getBankAccount({}))
        if (res?.payload?.data?.items) {
            const listBank = res.payload.data.items
            if (listBank.length == 0) navigate('/register/add-bank')
            else if (listBank.length == 1) checkVA()
            else navigate('/admin/overview')
        } else navigate('/auth/signin')
    }

    const checkVA = async () => {
        const res: any = await dispatch(getListPaymentChannel({}))
        if (res?.payload?.data) {
            const listVa = res.payload.data
            if (listVa.length == 0) navigate('/register/add-va')
            else if (listVa.length == 1) {
                if (!listVa[0].is_transfer) navigate(`/register/transfer-va/${listVa[0].id}`)
                else navigate('/admin/overview')
            } else navigate('/admin/overview')
        } else navigate('/auth/signin')
    }

    const handleRouter = async () => {
        const accessToken = LocalStorage.getAccessToken()
        if (accessToken) checkBank()
        else navigate('/auth/signin')
    }
    
    return { handleRouter, route }
}