import HeaderTitle from "components/header-title/HeaderTitle";
import Table from "components/table";
import { useMemo, useState } from "react";
import StatusCell from "./StatusCell";
import { ReactComponent as ViewIcon } from "assets/svg/view.svg";
import { TErrorReport } from "types/support";
import ErrorDetailModal from "./ErrorDetailModal";

type ErrorListProps = {
  data: TErrorReport[];
};

const ErrorList = ({ data }: ErrorListProps) => {
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<TErrorReport>();

  const colummns = useMemo(
    () => [
      {
        Header: "STT",
        Cell: ({ row }: any) => row.original.idx + 1,
      },
      {
        Header: "Tên kênh thanh toán",
        accessor: "payment_channel_name",
      },
      {
        Header: "Mô tả",
        accessor: "description",
      },
      // {
      //   Header: "Loại báo cáo lỗi",
      //   accessor: "error_type",
      // },
      {
        Header: "Trạng thái",
        accessor: "status",
        Cell: ({ value }: any) => <StatusCell status={value} />,
      },
      {
        Header: "Hành động",
        Cell: ({ row }: any) => (
          <button
            onClick={() => {
              setSelectedItem(row.original);
              setIsDetailModalOpen(true);
            }}
          >
            <ViewIcon fill="#ABB2C2" />
          </button>
        ),
      },
    ],
    []
  );

  return (
    <div className="flex w-full flex-col gap-y-4 md:mx-auto">
      <HeaderTitle subTitle="Danh sách báo lỗi" />
      <Table
        data={data}
        columns={colummns}
        noPagination={data.length <= 10}
        noRowDivider
        disableMinWidth
        row
      />

      <ErrorDetailModal
        isOpen={isDetailModalOpen}
        onClose={() => setIsDetailModalOpen(false)}
        reportDetail={selectedItem}
      />
    </div>
  );
};

export default ErrorList;
