import { ReactNode } from "react";
import { IoIosArrowDown } from "react-icons/io";

type SupportDropdownProps = {
  title: string;
  noti: string;
  subtitle?: string;
  children: string | string[];
  subquestion?: {
    title: string;
    answer: string;
  }[]
  showDropdown: boolean;
  idx: number;
  onToggle: (idx: number) => void;
};

const SupportDropdown = ({
  title,
  noti,
  subtitle,
  children,
  subquestion,
  showDropdown,
  idx,
  onToggle,
}: SupportDropdownProps) => {

  return (
    <div key={idx}
      className="flex w-full flex-col gap-y-2 rounded-lg border border-[#E8E8E8] p-3">
      <button
        onClick={() => onToggle(idx)}
        className="flex w-full items-center justify-between"
      >
        <p
          className={`font-inter text-left ${showDropdown ? "font-semibold" : "text-sm font-medium"
            }`}
        >
          {title}&nbsp;
          <span className="italic">
            {noti}
          </span><br />
          {subtitle}
        </p>
        <IoIosArrowDown
          width={16}
          className={`${showDropdown && "-rotate-180"
            } text-[#ABB2C2] transition-all`}
        />
      </button>
      {showDropdown && (
        <div className="animate-slideDown duration-200">
          {
            typeof (children) === 'string' ? children : children.map((child) => (
              <p>{child}</p>
            ))
          }
          {subquestion &&
            subquestion.map((subques) => (
              <ul className="pl-5">
                <li className="list-disc">{subques.title}</li>
                <li>{`>> `}{subques.answer}</li>
              </ul>))
          }
        </div>
      )}
    </div>
  );
};

export default SupportDropdown;
