import { twMerge } from "tailwind-merge";
import clsx, { ClassValue } from "clsx";
import { ReactNode } from "react";

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number
) {
  let timer: NodeJS.Timeout;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function millisToMinutesAndSeconds(millis: any) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (Number(seconds) < 10 ? "0" : "") + seconds;
}

export const handleToastErr = (key: string) => {
  if (key === "bank_code") return "Vui lòng chọn ngân hàng";
  if (key === "tax_no") return "Tên đăng nhập không hợp lệ";
  if (key === "channel-name") return "Vui lòng nhập tên kênh thanh toán";
  if (key === "prefix") return "Vui lòng nhập tiền tố đơn hàng";
  if (key === "identification_no") return "Vui lòng nhập số CCCD";
  return "This field is required";
};

export const formatBankAccount = (accountNumber: string) => {
  if (accountNumber?.toString()?.length < 4) return accountNumber;
  const lastFourDigits = accountNumber?.slice(-4);
  const maskedPart = "***";

  return maskedPart + lastFourDigits;
};

export const formatEmail = (email: string) => {
  const [local, domain] = email.split("@");
  const formattedLocal = local.slice(0, 2) + "..." + local.slice(-2);
  // Concatenate the formatted local part with the domain
  return formattedLocal + "@" + domain;
};

export const formatDate = (data: string) => {
  const formatLessThan10 = (value: string) => {
    if (Number(value) < 10) return "0" + value;
    return value;
  };
  const [date, time] = data.split(" ");
  let [day, month, year] = date.split("/");
  let [hour, minute, second] = time.split(":");

  // day = formatLessThan10(day)
  // month = formatLessThan10(month)
  // hour = formatLessThan10(hour)
  // minute = formatLessThan10(minute)
  // second = formatLessThan10(day)

  return date + " " + hour + ":" + minute;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type LabelFunc = (item: any) => ReactNode;

export function toOptionsData(
  data: any[],
  label: string | LabelFunc,
  value: any,
  value2?: any
) {
  return data.map((item) => {
    return {
      label: typeof label == 'function' ? label(item) : item[label],
      value: String(item[value]),
      ...(value2 && { value2: item[value2] }),
    };
  });
}
