import axiosClient from "./axiosClient";

export const telegramService = {
  testNoticeTelegram: (params: any) => {
    return axiosClient.post("/test-notice-telegram", params);
  },
  getIntegrateTelegramDevices: (params: any) => {
    return axiosClient.get("/integration/payment-notice", { params });
  },
  getDetailIntegrateTelegram: (id: string) => {
    return axiosClient.get(`/integration/payment-notice?id=${id}`);
  },
  createIntegrateTelegram: (params: any) => {
    return axiosClient.post("/integration/payment-notice", params);
  },
  updateIntegrateTelegram: (params: any) => {
    return axiosClient.put("/integration/payment-notice", params);
  },
  deleteIntegrateTelegram: (id: string) => {
    return axiosClient.delete(`/integration/payment-notice`, {
      data: { payment_notice_id: id },
    });
  },
};
