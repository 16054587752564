import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";

type Props = {
  setValueOTP: (code: string) => void;
  disabled?: boolean;
};

const InputOtp = (props: Props) => {
  const { setValueOTP, disabled } = props;

  const [otp, setOtp] = useState("");

  const inputStyle = {
    width: "40px",
    height: "40px",
    outlineColor: "#096DD9",
    border: "solid 1px #B2BCC2",
    borderRadius: "4px",
    color: "#096DD9",
  };

  useEffect(() => {
    if (otp.length > 0) {
      setValueOTP(otp);
    }
  }, [otp]);

  return (
    <OTPInput
      containerStyle={{ display: 'flex', gap: 16, justifyContent: 'center' }}
      value={otp}
      inputStyle={inputStyle}
      shouldAutoFocus
      inputType="number"
      onChange={setOtp}
      numInputs={6}
      renderInput={(props: any, index: any) => <input {...props} disabled={disabled} />}
    />
  );
};

export default InputOtp;
