import { Skeleton } from "@chakra-ui/react";
import { useAppDispatch } from "hooks/appHook";
import { toPng } from "html-to-image";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { useEffect, useState } from "react";
import { createQR } from "store/actions/userAction";

import Button from "components/button/button";

import atom from "assets/img/auth/atom-login.png";
import ocb from "assets/img/bank/ocb.png";
import vccb from "assets/img/bank/vccb.png";
import download from "assets/img/payment/download.svg";
import napas from "assets/img/payment/napas.png";
import { BtnClose } from "components/button/actionButton";

export const handleDownloadQR = () => {
  const qrImg = document.getElementById("qrcode");
  if (!qrImg) return;
  toPng(qrImg, { cacheBust: true })
    .then((dataUrl) => {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = dataUrl;
      link.click();
    })
    .catch((err) => console.log(err));
};

export const QRChannel = ({
  channelInfo,
  onlyImg = false,
  setLoading,
}: any) => {
  const dispatch = useAppDispatch();
  const [qrData, setQrData] = useState(null);

  const getQR = async (va_account_no: string) => {
    const res: any = await dispatch(createQR({ va_account_no }));
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      setQrData(res.payload.data);
      if (setLoading) setLoading(false);
    } else CustomToastMessage.error("Lỗi hiển thị QR", "Có lỗi xảy ra.");
  };

  useEffect(() => {
    if (channelInfo?.va_account_no) {
      getQR(channelInfo.va_account_no);
    }
  }, [channelInfo?.va_account_no]);

  return (
    <div
      id={onlyImg ? "" : "qrcode"}
      className="flex h-[min-content] flex-col items-center gap-5 rounded-lg border border-[#8991A3] bg-white px-8 py-6"
    >
      {!onlyImg && (
        <div className="flex flex-col items-center gap-1">
          {/* Channel Name */}
          <p className="text-sm font-semibold text-[#0F1D40]">
            {channelInfo?.holder_name}
          </p>
          {/* VA Code */}
          <p className="text-sm font-normal text-primary">
            {channelInfo?.va_account_no}
          </p>
        </div>
      )}
      {/* QR */}
      <Skeleton isLoaded={qrData?.qr_base64} height={227} borderRadius={12}>
        <div className="rounded-xl border-[6px] border-primary">
          <img
            src={`data:image/png;base64,${qrData?.qr_base64}`}
            alt="qr"
            className="w-[227px] rounded-xl"
          />
        </div>
      </Skeleton>
      {/* Logos */}
      <div className="mt-3 flex w-full items-center justify-center gap-3">
        <img src={napas} alt="napas" className="h-[22px] w-auto" />
        <div className="h-6 w-px bg-[#8991A3]"></div>
        <img src={atom} alt="unipay" className="h-[22px] w-auto" />
        <div className="h-6 w-px bg-[#8991A3]"></div>
        <img
          src={channelInfo?.bank_logo?.includes("OCB") ? ocb : vccb}
          alt="bank_logo"
          className="h-[25px] w-auto object-contain"
        />
      </div>
    </div>
  );
};

const ShowQR = ({
  setShowQR,
  selectedChannel,
}: {
  setShowQR?: any;
  selectedChannel?: any;
}) => {
  const [loadingQr, setLoadingQr] = useState(true);

  return (
    <div className={`my-[60px] flex justify-center`}>
      <div className="relative w-[92.27%] max-w-[418px] rounded-2xl bg-white px-[22px] py-10 text-[#262626] shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)] md:p-10">
        {/* Close */}
        <BtnClose onClick={() => setShowQR(false)} />
        {/* Body */}
        <div className="flex w-full flex-col items-center gap-8">
          {/* QR Wrap */}
          <QRChannel channelInfo={selectedChannel} setLoading={setLoadingQr} />
          <div className="grid w-full grid-cols-2 gap-4">
            <Button onClick={() => setShowQR(false)} outline title="Đóng" />
            <Button
              disabled={loadingQr}
              onClick={handleDownloadQR}
              className="text-white"
              title="Tải xuống QR"
              small
              icon={download}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowQR;
