// Custom components
import { useState } from "react";
import IcEyeOff from "../../assets/img/auth/ic_eye-off.svg";
import IcEyeOn from "../../assets/img/auth/ic_eye-on.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { handleToastErr } from "lib/utils";

function InputFieldPw(props: {
  id: string;
  label?: string;
  extra?: string;
  placeholder?: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  name: string;
  register?: any;
  isRequired?: boolean;
  errors?: any;
  value?: string;
}) {
  const {
    label,
    id,
    extra,
    placeholder,
    variant,
    state,
    disabled,
    name,
    register,
    isRequired,
    errors,
    value,
  } = props;
  const [showPw, setShowPw] = useState<boolean>(false);

  return (
    <div className={`${extra} font-inter`}>
      {label && (
        <label
          htmlFor={id}
          className={`text-sm text-[#262626] ${variant === "auth" ? "font-semibold" : "font-medium"
            }`}
        >
          {label}
          {isRequired && <span className="text-red-error">*</span>}
        </label>
      )}

      <div className="relative">
        <input
          disabled={disabled}
          {...register(name, {
            required: {
              value: isRequired,
              message: handleToastErr(name),
            },
          })}
          value={value}
          type={showPw ? "text" : "password"}
          id={id}
          placeholder={placeholder}
          className={`mt-2 flex h-[44px] w-full items-center justify-center rounded-xl border md:bg-white p-3 text-sm outline-none ${disabled === true
            ? "!border-none !bg-gray-100"
            : errors[name]
              ? "border-red-error text-red-error placeholder:text-red-error"
              : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500"
                : "border-gray-200"
            }`}
        />
        <button
          type="button"
          onClick={() => setShowPw(!showPw)}
          className="absolute right-3 top-1/2 z-[1] -translate-y-1/2 transform cursor-pointer"
        >
          {showPw ? <AiOutlineEyeInvisible className="size-5" color="#ABB2C2"/> : <AiOutlineEye className="size-5" color="#ABB2C2"/>}
        </button>
      </div>
      <p className="mt-2 text-xs text-red-error">
        {errors[name] && errors[name].message}
      </p>
    </div>
  );
}

export default InputFieldPw;
