/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { SlArrowLeft } from "react-icons/sl";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

import SidebarItem from "./SidebarItem";
import {
    IcOverview,
    IcTxn,
    IcBank,
    IcPChannel,
    IcDevice,
    IcSetting,
    IcLogout,
    IcPhone,
    IcTelegram,
    IcUser,
    IcKey,
    IcAPI,
    IcSupport,
} from "./SidebarIcon";

import atomVam from "assets/img/auth/atom-vam-sidebar.png";
import atom from "../../assets/img/auth/atom.png";
import { LocalStorage } from "lib/LocalStorage";
import { logoutAction } from "store/actions/userAction";
import { useAppDispatch } from "hooks/appHook";
import useWindowSize from "hooks/useWindowSize";

const Sidebar = ({
    firstLogin = false,
    open,
    setOpen,
}: {
    firstLogin?: boolean;
    open: boolean;
    setOpen: any;
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const route = useLocation().pathname.split("/")[2];
    const [activedItem, setActivedItem] = useState(route || "overview");
    const { isMd } = useWindowSize();
    useEffect(() => setActivedItem(route), [route]);
    useEffect(() => {
        setOpen(isMd);
    }, [isMd]);
    // const [open, setOpen] = useState(true);
    const [openGlobalSubItems, setOpenGlobalSubItems] = useState(null);

    const handleLogout = async () => {
        const res = await dispatch(logoutAction({}));
        navigate("/auth/signin");
        LocalStorage.clearToken();
    };

    const sidebarItems = [
        {
            title: "Tổng quan",
            icon: <IcOverview />,
            link: "overview",
        },
        {
            title: "Giao dịch",
            icon: <IcTxn />,
            link: "",
            subItems: [
                {
                    title: "Kênh hoạt động",
                    icon: <IcAPI />,
                    link: "active-channel",
                },
                {
                    title: "Kênh đã xóa",
                    icon: <IcKey />,
                    link: "deleted-channel",
                }
            ]
        },
        {
            title: "Ngân hàng",
            icon: <IcBank />,
            link: "bank",
        },
        {
            title: "Kênh thanh toán",
            icon: <IcPChannel />,
            link: "payment",
        },
        {
            title: "Tích hợp",
            icon: <IcPhone />,
            link: "",
            subItems: [
                {
                    title: "Tích hợp Open API",
                    icon: <IcAPI />,
                    link: "integrate-vam",
                },
                {
                    title: "Tích hợp thiết bị",
                    icon: <IcDevice />,
                    link: "integrate-device",
                },
                {
                    title: "Tích hợp Telegram",
                    icon: <IcTelegram />,
                    link: "integrate-telegram",
                },
            ],
        },
        {
            title: "Thiết lập",
            icon: <IcSetting />,
            link: "",
            subItems: [
                {
                    title: "Thông tin tài khoản",
                    icon: <IcUser />,
                    link: "setting-update",
                },
                {
                    title: "Đổi mật khẩu",
                    icon: <IcKey />,
                    link: "setting-change-password",
                },
            ],
        },
        {
            title: "Hỗ trợ",
            icon: <IcSupport />,
            link: "support",
        },
    ];

    return (
        <div
            style={{ transition: isMd && "all 0.3s linear" }}
            className={`absolute ${open ? "z-50 w-screen md:w-[272px]" : "z-50 w-0 md:w-[112px]"
                } md:relative`}
        >
            <div
                onClick={() => setOpen(false)}
                className="absolute inset-0 bg-[#00000070] md:hidden"
            ></div>
            <div
                style={{
                    transition: "all 0.3s linear",
                    overflow: !isMd && !open && "hidden",
                }}
                className={`bg-sidebar flex h-screen flex-col px-4 py-8 md:rounded-[0_24px_24px_0] ${open
                    ? "w-[272px] translate-x-0"
                    : "w-0 -translate-x-[105%] md:w-[112px] md:translate-x-0"
                    }`}
            >
                {/* Brand Logo */}
                <div
                    className="relative flex h-12 w-full items-center"
                    style={{ paddingLeft: !open && 6, transition: "all 0.3s linear" }}
                >
                    <img
                        src={atomVam}
                        alt="logo"
                        className={`max-h-12 w-auto object-contain transition ${open ? "h-12" : "h-auto"
                            }`}
                    />
                    <div
                        onClick={() => setOpen(!open)}
                        style={{
                            zIndex: 41,
                            left: "100%",
                            top: "50%",
                            transform: "translateY(-50%)",
                        }}
                        className="absolute hidden h-8 w-8 cursor-pointer items-center justify-center rounded-lg border border-primary bg-white text-primary md:flex"
                    >
                        <SlArrowLeft
                            size={16}
                            color="currentColor"
                            style={{
                                transition: "all 0.3s linear",
                                transform: !open && "rotate(180deg)",
                            }}
                        />
                    </div>
                    <div
                        onClick={() => setOpen(false)}
                        style={{ zIndex: 41, right: 0, top: -16 }}
                        className="absolute flex h-8 w-8 cursor-pointer items-center justify-center md:hidden"
                    >
                        <IoClose size={24} color={"white"} />
                    </div>
                </div>

                {/* Sidebar Items */}
                <div
                    style={{
                        transition: "all 0.3s linear",
                        maxHeight: "calc(100vh - 212.667px - 40px)",
                    }}
                    className={`custom-scrollbar ${open ? "m-[32px_0_24px] overflow-auto" : "32px_12px_24px"
                        } ${firstLogin && "pointer-events-none opacity-50"}`}
                >
                    {sidebarItems.map((item, index) => {
                        const { icon, title, link, subItems } = item;
                        return (
                            <div key={index}>
                                <SidebarItem
                                    icon={icon}
                                    title={title}
                                    link={link}
                                    subItems={subItems}
                                    openSidebar={open}
                                    activedItem={activedItem}
                                    setActivedItem={setActivedItem}
                                    openGlobalSubItems={openGlobalSubItems}
                                    setOpenGlobalSubItems={setOpenGlobalSubItems}
                                    setOpenSidebar={setOpen}
                                />
                            </div>
                        );
                    })}
                </div>

                {/* Logout */}
                <div
                    className={`flex-[1_0_0] border-t border-[#E8E8E8] pt-6 transition ${!open && "mx-3"
                        }`}
                >
                    <div
                        onClick={handleLogout}
                        className="group flex cursor-pointer items-center gap-[10px] rounded-xl text-white transition hover:bg-[#0B11261A] hover:text-white"
                        style={{ padding: open ? "12px 10px" : "14px 20px" }}
                    >
                        <IcLogout />
                        {open && (
                            <span className="flex-[1_0_0] truncate text-sm font-medium leading-5">
                                Đăng xuất
                            </span>
                        )}
                    </div>
                </div>

                {/* Atom Logo */}
                {/* <div className={`mt-5 flex ${!open && "flex-col"} justify-center items-center gap-1`}>
                    <p className="text-white text-xs leading-4 font-medium truncate">Powered by</p>
                    <img src={atom} alt="atom" className="h-5 w-auto" />
                </div> */}
            </div>
        </div>
    );
};
export default Sidebar;
