import {
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton
} from "@chakra-ui/react";
import "assets/css/ModalCustom.css";
import IcLogoOcb from "assets/img/bank/logo_ocb.png";
import { BtnClose } from "components/button/actionButton";
import { QRCodeCanvas } from "qrcode.react";
import React from "react";
interface IProps {
  onClose: any;
  isOpen: boolean;
}
const CreateAccountOCB: React.FC<IProps> = ({ onClose, isOpen }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const qrData = "https://ocbomni.onelink.me/RE8p/ATOM";

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        style={{ borderRadius: 16, width: "92.27%", maxWidth: 390 }}
      >
        <BtnClose onClick={() => onClose()} />
        <div className="rounded-2xl p-10 font-inter">
          <div className="flex w-full flex-col items-center ">
            <h4 className="text-center text-[20px] font-bold leading-[28px] text-[#0A203D]">
              Tải ứng dụng <span className="text-[#018D46]">OCB OMNI</span>
            </h4>

            <Skeleton isLoaded={!!qrData} height={220} className="my-[32px]">
              <QRCodeCanvas value={qrData} size={220} />
            </Skeleton>

            <div className="flex items-center gap-[6px]">
              <div className="font-inter text-[14px] leading-[20px] text-[#0A203D]">
                Quét QR tải ngay ứng dụng
              </div>
              <img src={IcLogoOcb} alt="IcLogoOcb" className="h-[36px] " />
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CreateAccountOCB;
