import axiosClient from "./axiosClient";

export const deviceService = {
  generateBinCode: (params: any) => {
    return axiosClient.post(`/create-bin-code`, params);
  },
  registerDevice: (params: any) => {
    return axiosClient.post(`/integrate-device-info`, params);
  },
  getDeviceListPerChannel: (params: any) => {
    return axiosClient.get(`/integrate-device-info?page=${params.page}&page_size=${params.page_size}&id=${params.id}`);
  },
  getDeviceList: (params: any) => {
    return axiosClient.get(`/integrate-device-info`, { params });
  },
  deleteDevice: (params: any) => {
    return axiosClient.delete(`/integrate-device-info`, params);
  }
}