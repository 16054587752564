import { combineReducers } from "@reduxjs/toolkit";
import codeSlice from "./codeSlice";
import deviceSlice from "./deviceSlice";
import profileSlice from "./profileSlice";
import verifyBankSlice from "./verifyBankSlice";
import verifyBankWithOtpSlice from "./verifyBankWithOtpSlice";
import userSlice from "./userSlice";
import loadingSlice from "./loadingSlice";
import pageSlice from "./pageSlice";
import transactionModalSlice from './transactionSlice';
import integrationSlice from "./integrationSlice";
const rootReducer = combineReducers({
  verifyBank: verifyBankSlice,
  verifyBankWithOtp: verifyBankWithOtpSlice,
  profile: profileSlice,
  code: codeSlice,
  device: deviceSlice,
  user: userSlice,
  loading: loadingSlice,
  page: pageSlice,
  transactionModal: transactionModalSlice,
  integration: integrationSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
