import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { TAccountInfo } from "types/txn";
import { BankCell } from "views/admin/transaction/active-channel";

type SingleHiddenFieldProps = {
  label: string;
  fieldName: string;
  fieldValue: number;
};

export const SingleHiddenField = ({
  label,
  fieldName,
  fieldValue,
}: SingleHiddenFieldProps) => {
  const [showPw, setShowPw] = useState(false);
  return (
    <div className="flex w-full flex-col gap-y-4">
      <p className="font-bold">{label}</p>
      <div className="flex w-full justify-between rounded-lg bg-[#FAFAFA] p-3">
        <p className="text-sm text-[#505B76]">{fieldName}</p>
        <div className="flex items-center gap-x-4">
          <div className="flex items-center gap-x-1">
            <p className="w-fit max-w-[140px] bg-[#FAFAFA] text-sm font-medium">
              {showPw ? fieldValue.toLocaleString() : "••••••••"}
            </p>
            <p className="font-medium">VND</p>
          </div>
          <button type="button" onClick={() => setShowPw(!showPw)}>
            {showPw ? (
              <AiOutlineEyeInvisible className="size-5" color="#ABB2C2" />
            ) : (
              <AiOutlineEye className="size-5" color="#ABB2C2" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const AccountInfoField = ({
  bank_logo,
  bank_account_no,
  account_balance,
}: TAccountInfo) => {
  const [showPw, setShowPw] = useState(false);

  return (
    <div className="flex items-center justify-between py-[10px] pl-4 pr-5">
      <BankCell logo={bank_logo} text={bank_account_no} />
      <div className="flex items-center gap-x-3">
        <p className="w-fit max-w-[140px] bg-[#FAFAFA] text-sm">
          {showPw ? account_balance.toLocaleString() : "••••••••"}
        </p>
        <button type="button" onClick={() => setShowPw(!showPw)}>
          {showPw ? (
            <AiOutlineEyeInvisible className="size-5" color="#ABB2C2" />
          ) : (
            <AiOutlineEye className="size-5" color="#ABB2C2" />
          )}
        </button>
      </div>
    </div>
  );
};

type HiddenFieldGroupProps = {
  label: string;
  firstField: string;
  secondField: string;
  data: TAccountInfo[];
  loading?: boolean;
};

export const HiddenFieldGroup = (props: HiddenFieldGroupProps) => {
  const { label, firstField, secondField, data, loading = false } = props;
  return (
    <div className="flex w-full flex-col gap-y-4">
      <p className="font-bold">{label}</p>
      <div className="no-scrollbar flex max-h-[160px] w-full flex-col overflow-y-scroll rounded-lg bg-[#FAFAFA]">
        <div className="flex justify-between border-b border-[#DBE5EA] px-4 py-2 text-sm font-medium text-[#8C8C8C]">
          <p>{firstField}</p>
          <p>{secondField}</p>
        </div>
        {loading && <p className="py-[10px] pl-4 text-sm">Loading...</p>}
        {data.map((el) => (
          <AccountInfoField
            key={el.bank_account_no}
            bank_logo={el.bank_logo}
            bank_account_no={el.bank_account_no}
            account_balance={el.account_balance}
          />
        ))}
      </div>
    </div>
  );
};
