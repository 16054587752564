import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { userService } from "store/services/userService";

export const login = createAsyncThunk(
  "user/login",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userService.login(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const logoutAction = createAsyncThunk(
  "user/logout",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userService.logout(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userService.profile(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createQR = createAsyncThunk(
  "user/qr",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userService.qr(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emailRecovery = createAsyncThunk(
  "user/emailRecovery",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userService.emailRecovery(params);
      return response;
    } catch (err) {
      return rejectWithValue(err as AxiosError);
    }
  }
);

export const emailAuthenticator = createAsyncThunk(
  "user/emailAuthenticator",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userService.emailAuthenticator(params);
      return response;
    } catch (err) {
      return rejectWithValue(err as AxiosError);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (params: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userService.resetPassword(params);
      return response;
    } catch (err) {
      return rejectWithValue(err as AxiosError);
    }
  }
);
