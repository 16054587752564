import { useAppDispatch } from "hooks/appHook";
import { useProfile } from "hooks/useProfile";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getListPaymentChannel } from "store/actions/paymentChannelAction";
import { QRChannel, handleDownloadQR } from "./ShowQR";

import Button from "components/button/button";
import { AiOutlineLoading } from "react-icons/ai";
import { GoCheckCircle } from "react-icons/go";

import download from "assets/img/payment/download.svg";
import device from "assets/img/sidebar/device.svg";
import telegram from "assets/img/sidebar/telegram.svg";
import vam from "assets/img/sidebar/vam-api.svg";
import { BtnClose } from "components/button/actionButton";
import HeaderTitle from "components/header-title/HeaderTitle";
import useWebSocket from "hooks/useWebSocket";
import { Tooltip } from "@chakra-ui/tooltip";

const DetailChannel = ({
  setShow,
  selectedChannel,
}: {
  setShow?: any;
  selectedChannel?: any;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loadingQr, setLoadingQr] = useState(true);
  const [channelInfo, setChannelInfo] = useState<any>();
  const [isTransfer, setIsTransfer] = useState(null);
  const isOnboard = useLocation().pathname.split("/")[1] == "register";
  const { data: profile } = useProfile();
  const isBusiness = profile?.data.account_type == "BUSINESS";
  const { channelId } = useParams();
  const { pathname } = useLocation();
  const isAddBank = pathname.includes("bank/create/step2");

  const getDetailVA = async () => {
    const res: any = await dispatch(
      getListPaymentChannel({ id: channelId || selectedChannel?.id })
    );
    if (res?.meta?.requestStatus == "fulfilled" && res?.payload?.data) {
      setChannelInfo(res.payload.data[0]);
    }
  };

  useEffect(() => {
    if (channelId || !selectedChannel?.bank_name) {
      getDetailVA();
    } else setChannelInfo(selectedChannel);
  }, []);

  useEffect(() => {
    if (channelInfo && channelInfo.is_transfer) setIsTransfer(true);
  }, [channelInfo]);

  const { data } = useWebSocket({
    start: channelInfo && !channelInfo.is_transfer,
    event: channelInfo?.va_account_no,
    handleEvent: (data: any) => {
      if (data && data.is_transfer) {
        setIsTransfer(true);
        CustomToastMessage.success(
          "Xác thực kênh thanh toán thành công",
          "Bạn vừa thực hiện thành công giao dịch xác thực."
        );
      }
    },
  });

  const listOptIntegrate = [
    {
      title: "Tích hợp thiết bị",
      desc: "Tích hợp thiết bị để nhận được các thông tin giao dịch thông qua thiết bị đã tích hợp.",
      icon: device,
      link: "/admin/integrate-device",
    },
    {
      title: "Tích hợp Telegram",
      desc: "Tích hợp Telegram để nhận được các thông tin giao dịch thông qua ứng dụng một cách thuận tiện và nhanh chóng. ",
      icon: telegram,
      link: "/admin/integrate-telegram",
    },
    {
      title: "Tích hợp OPEN API",
      desc: "Tích hợp OPEN API giúp bạn tích hợp API với hệ thống ATOM SOLUTION.",
      icon: vam,
      link: "/admin/integrate-vam",
    },
  ];

  const listInfo = [
    { label: "Tài khoản QR", value: "va_account_no" },
    { label: "Tên cửa hàng", value: "name" },
    { label: "Ngân hàng", value: "bank_name" },
    { label: "Tên thụ hưởng", value: "holder_name" },
    { label: "Số tài khoản", value: "bank_account_no" },
  ];

  return (
    <div
      className={`${
        !channelId && "my-[60px]"
      } flex flex-col items-center gap-6`}
    >
      {!isOnboard && channelId && (
        <HeaderTitle
          title="Kênh thanh toán"
          onClickTitle={() => navigate("/admin/payment")}
          subTitle="Thêm kênh thanh toán"
        />
      )}
      <div className="relative flex w-[92.27%] max-w-[824px] flex-col items-end gap-4 rounded-2xl bg-white px-4 py-6 text-[#262626] shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)] md:gap-8 md:p-8">
        {/* Icon Close */}
        {!channelId && (
          <BtnClose
            onClick={() => {
              setShow(false);
              isAddBank && navigate("/admin/bank");
            }}
          />
        )}

        {/* Title */}
        <h3 className="w-full text-2xl font-bold">Kênh thanh toán</h3>
        {/* Body */}
        <div className="custom-scrollbar flex max-h-[442px] w-full flex-col gap-6 overflow-scroll md:max-h-max md:flex-row">
          <div className="flex flex-col items-center gap-5 rounded-2xl border border-[#D9D9D9] px-4 py-6">
            <h2 className="w-full text-lg font-bold leading-8">
              Chi tiết kênh thanh toán
            </h2>
            <div className="flex flex-col items-center gap-6">
              <div className="space-y-2 text-sm md:w-[384px]">
                {listInfo.map((info) => (
                  <div
                    key={info.value}
                    className="flex items-center justify-between gap-3"
                  >
                    <p className="whitespace-nowrap">{info.label}:</p>
                    {channelInfo && channelInfo[info.value] && (
                      <Tooltip
                        label={
                          info.label == "Tên thụ hưởng" &&
                          channelInfo[info.value]
                        }
                        hasArrow
                        fontSize={12}
                        fontWeight={400}
                        lineHeight={5}
                        fontFamily={"Montserrat"}
                        bg="#262626"
                        px={3}
                        py={1.5}
                        borderRadius={8}
                        color="white"
                        placement="top"
                        textAlign={"center"}
                      >
                        <span className="truncate font-semibold">
                          {channelInfo[info.value]}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                ))}
                {!isTransfer && (
                  <p className="!mt-4 text-primary">
                    Để kiểm tra kết nối tài khoản QR. Bạn hãy thực hiện một giao
                    dịch mới với số tiền
                    <span className="font-semibold">
                      {" "}
                      {isBusiness ? 200 : 30},000 VND{" "}
                    </span>
                    đến tài khoản QR.
                  </p>
                )}
              </div>
              <QRChannel
                channelInfo={channelInfo}
                setLoading={setLoadingQr}
                onlyImg
              />
              <p className="flex items-center gap-2 text-sm font-normal">
                <span className="font-semibold">Trạng thái:</span>
                {isTransfer ? "Đã xác thực" : "Chưa giao dịch"}
                {isTransfer ? (
                  <GoCheckCircle size={20} color="#4DD077" />
                ) : (
                  <AiOutlineLoading className="animate-spin" size={20} />
                )}
              </p>
            </div>
            <Button
              disabled={loadingQr}
              onClick={handleDownloadQR}
              className="text-white"
              title="Tải xuống QR"
              small
              icon={download}
            />
          </div>
          {/* Guidline */}
          <div className="flex flex-[1_0_0] flex-col gap-5 rounded-2xl border border-[#D9D9D9] px-4 py-6">
            <h2 className="text-lg font-bold leading-8">Hướng dẫn</h2>
            {listOptIntegrate.map((opt, index) => (
              <div
                key={index}
                className="w-full space-y-4 rounded-xl border border-[#E8E8E8] p-4 text-sm"
              >
                <p className="font-semibold">
                  {index + 1}
                  {". "}
                  {opt.title}
                </p>
                <p className="">{opt.desc}</p>
                <Button
                  disabled={!isTransfer}
                  title="Tích hợp"
                  small
                  onClick={() => navigate(opt.link)}
                  icon={opt.icon}
                />
              </div>
            ))}
          </div>
        </div>
        <Button
          disabled={!isTransfer}
          onClick={() => {
            if (channelId) {
              isOnboard
                ? navigate("/admin/overview")
                : navigate("/admin/payment");
            } else {
              setShow(false);
              isAddBank && navigate("/admin/bank");
            }
          }}
          outline={channelId ? false : true}
          className="w-full md:w-fit"
          title={channelId ? "Hoàn tất" : "Đóng"}
        />
      </div>
      <div className="absolute top-[-500%]">
        <QRChannel channelInfo={channelInfo} setLoading={setLoadingQr} />
      </div>
    </div>
  );
};

export default DetailChannel;
