import { yupResolver } from "@hookform/resolvers/yup";
import InputFieldPw from "components/fields/InputFieldPw";
import HeaderTitle from "components/header-title/HeaderTitle";
import { useAppDispatch } from "hooks/appHook";
import useValidatePw from "hooks/useValidatePw";
import { md5 } from "js-md5";
import { toastMessage } from "lib/constants";
import { ERROR_CODE } from "lib/errorCode";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { settingPassword } from "store/actions/settingAction";
import * as yup from "yup";
import { Button } from "../integrate/vam";
import Notify from "./Notify";
import { useNavigate } from "react-router-dom";

const schemaChangePassword = yup.object({
  old_password: yup.string().required("Vui lòng nhập mật khẩu hiện tại"),
  new_password: yup
    .string()
    .required("Vui lòng nhập mật khẩu mới")
    .min(6, "Mật khẩu phải có ít nhất 6 ký tự")
    .test("", "Vui lòng nhập mật mới khác mật khẩu hiện tại", function (value) {
      return value != this.parent.old_password;
    }),
  confirm_password: yup
    .string()
    .required("Vui lòng nhập xác nhận mật khẩu mới")
    .oneOf(
      [yup.ref("new_password"), null],
      "Vui lòng nhập xác nhận mật khẩu mới trùng khớp"
    ),
});

const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const [result, setResult] = useState<"success" | "error" | "">("");
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaChangePassword),
  });
  const { condition1, condition2 } = useValidatePw(watch("new_password"));

  const onFormSubmit = async (value: any) => {
    // console.log('>>> data form: ', value)
    try {
      const res: any = await dispatch(
        settingPassword({
          new_password: md5(value.new_password),
          old_password: md5(value.old_password),
        })
      );
      if (res?.meta?.requestStatus === "fulfilled") {
        setResult("success");
      } else {
        if (res?.payload?.error_code == "PASSWORD_NOT_MATCH") {
          setError("old_password", {
            type: "manual",
            message: "Mật khẩu hiện tại không đúng",
          });
        } else toastMessage.error(ERROR_CODE[res?.payload?.error_code]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const disabled =
    !watch("old_password") ||
    !watch("new_password") ||
    !watch("confirm_password") ||
    !condition1 ||
    !condition2;

  return (
    <section className="space-y-6">
      <Notify
        onClose={() => {
          reset();
          result == "success" ? navigate('/admin/overview') : setResult("");
        }}
        title={`Cập nhật mật khẩu mới ${
          result == "success" ? "thành công" : "thất bại"
        }`}
        type={result}
        isOpen={!!result}
      />
      <HeaderTitle title="Thiết lập" subTitle="Đổi mật khẩu" />
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="mx-auto w-full rounded-lg bg-[#fff] p-6 shadow-[0_5px_16px_0_rgba(8,15,52,0.06)] md:w-[805px] md:p-8"
      >
        <div className="grid grid-cols-1 gap-2">
          <InputFieldPw
            isRequired
            id="old_password"
            label="Mật khẩu hiện tại"
            placeholder="Nhập mật khẩu hiện tại"
            name="old_password"
            register={register}
            extra=""
            errors={errors}
          />
          <InputFieldPw
            isRequired
            id="new_password"
            label="Mật khẩu mới"
            placeholder="Nhập mật khẩu mới"
            name="new_password"
            register={register}
            extra=""
            errors={errors}
          />
          <InputFieldPw
            isRequired
            id="confirm_password"
            label="Xác nhận mật khẩu mới"
            placeholder="Nhập xác nhận mật khẩu mới"
            name="confirm_password"
            register={register}
            extra=""
            errors={errors}
          />
          <div className="text-sm font-normal">
            <p
              className={`${condition1 ? "text-[#8C8C8C]" : "text-red-error"}`}
            >
              • Mật khẩu phải có độ dài từ 7-20 ký tự.
            </p>
            <p
              className={`${condition2 ? "text-[#8C8C8C]" : "text-red-error"}`}
            >
              • Mật khẩu phải có ký tự số (0, 1, 2, ...), chữ hoa (A, B, C,
              ...), chữ thường (a, b, c, ...) và ký tự đặc biệt (@, #, $, ...).
            </p>
          </div>
        </div>
        <Button
          title="Xác nhận"
          disabled={disabled}
          className="mt-[42px] w-full"
        />
      </form>
    </section>
  );
};

export default ChangePassword;
