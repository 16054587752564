import { Skeleton } from '@chakra-ui/react'
import { useOnboardRoute } from 'hooks/useOnboardRoute'
import { useEffect } from 'react'

const DefaultLogin = () => {
    const { handleRouter } = useOnboardRoute()
    
    useEffect(() => {
        handleRouter()
    }, [])

    return (
        <Skeleton className='h-screen'>
            <div className='h-screen'></div>
        </Skeleton>
    )
}

export default DefaultLogin