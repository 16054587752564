import InputField from "components/fields/InputField";
import HeaderTitle from "components/header-title/HeaderTitle";
import { useProfile } from "hooks/useProfile";
import { ACCOUNT_TYPE } from "lib/constants";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const AccountInfo = () => {
  const { data } = useProfile();
  const language = localStorage.getItem("lang");
  const accountType = data?.data?.account_type;

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (data && language) {
      setValue("enterprise_name", data.data.enterprise_name);
      setValue("enterprise_no", data.data.enterprise_no);
      // setValue('relationship', data?.data?.relationship?.name[language])
      setValue("relationship", data.data.relationship);
      setValue("name", data.data.first_name + " " + data.data.last_name);
      setValue("tax_no", data.data.tax_no);
      setValue("email", data.data.email);
      setValue("industry", data.data.industry[language]);
      setValue("account_type", ACCOUNT_TYPE[data.data.account_type]);
      setValue("short_name", data.data.short_name.toUpperCase());
    }
  }, [data, language]);

  return (
    <section className="space-y-6">
      <HeaderTitle title="Thiết lập" subTitle="Thông tin tài khoản" />
      <form className="mx-auto w-full space-y-8 rounded-2xl p-0 md:w-[696px] md:bg-white md:p-8 md:shadow-[0_5px_16px_0_rgba(8,15,52,0.06)]">
        {accountType != "PERSONAL" && (
          <div className="space-y-4 rounded-2xl border border-[#D9D9D9] bg-white p-6">
            <h1 className="text-lg font-bold leading-8">
              1. Thông tin{" "}
              {accountType == "BUSINESS" ? "doanh nghiệp" : "hộ kinh doanh"}
            </h1>
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-4">
                <InputField
                  readOnly
                  id="enterprise_name"
                  label={`Tên ${
                    accountType == "BUSINESS" ? "doanh nghiệp" : "hộ kinh doanh"
                  }`}
                  name="enterprise_name"
                  register={register}
                  errors={errors}
                  tooltip={watch('enterprise_name')}
                />
                <InputField
                  readOnly
                  id="industry"
                  label="Ngành nghề"
                  name="industry"
                  register={register}
                  errors={errors}
                  tooltip={watch('industry')}
                />
                <InputField
                  readOnly
                  id="short_name"
                  label="Tên viết tắt/Tên thương hiệu"
                  name="short_name"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="space-y-4">
                {accountType == "BUSINESS" && (
                  <InputField
                    readOnly
                    id="enterprise_no"
                    label="Mã số doanh nghiệp"
                    name="enterprise_no"
                    register={register}
                    errors={errors}
                  />
                )}
                <InputField
                  readOnly
                  id="tax_no"
                  label="Mã số thuế"
                  name="tax_no"
                  register={register}
                  errors={errors}
                />
                <InputField
                  readOnly
                  id="relationship"
                  label="Quan hệ"
                  name="relationship"
                  register={register}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        )}
        <div className="space-y-4 rounded-2xl border border-[#D9D9D9] bg-white p-6">
          <h1 className="text-lg font-bold leading-8">
            {accountType == "PERSONAL" ? 1 : 2}. Thông tin đăng ký
          </h1>
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2">
            <InputField
              readOnly
              id="name"
              label="Họ và tên"
              name="name"
              register={register}
              errors={errors}
              tooltip={watch('name')}
            />
            {accountType == "PERSONAL" && (
              <InputField
                readOnly
                id="tax_no"
                label="Mã số thuế"
                name="tax_no"
                register={register}
                errors={errors}
              />
            )}
            <InputField
              readOnly
              id="email"
              label="Email (cũng là tên đăng nhập)"
              name="email"
              register={register}
              errors={errors}
              tooltip={watch('email')}
            />
            {accountType == "PERSONAL" && (
              <InputField
                readOnly
                id="industry"
                label="Ngành nghề"
                name="industry"
                register={register}
                errors={errors}
                tooltip={watch('industry')}
              />
            )}
            <InputField
              readOnly
              id="account_type"
              label="Đối tượng"
              name="account_type"
              register={register}
              errors={errors}
            />
            {accountType == "PERSONAL" && (
              <InputField
                readOnly
                id="short_name"
                label="Tên viết tắt"
                name="short_name"
                register={register}
                errors={errors}
              />
            )}
          </div>
        </div>
      </form>
    </section>
  );
};

export default AccountInfo;
