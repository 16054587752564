import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/button/button";
import MultipleImgUploadBtn from "components/button/multipleImgUploadBtn";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import HeaderTitle from "components/header-title/HeaderTitle";
import { useAppDispatch } from "hooks/appHook";
import { toastMessage } from "lib/constants";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getListPaymentChannel } from "store/actions/paymentChannelAction";
import { createTicket } from "store/actions/supportAction";
import { TSelectFieldData } from "types/common";
import * as yup from "yup";

export type MultipleImgUploadBtnRef = {
  onReset: () => void;
};

export const errorReportSchema = yup.object().shape({
  payment_channel: yup.string().required("Vui lòng chọn tên kênh thanh toán"),
  error_type: yup.string().required("Vui lòng chọn loại báo cáo lỗi"),
  desc: yup.string().required("Vui lòng nhập mô tả"),
  error_image: yup
    .array(yup.mixed())
    .min(1, "Vui lòng chọn ít nhất 1 hình ảnh"),
  in_progress_desc: yup.string(),
});

type ErrorReportFormProps = {
  handleGetTickets: () => Promise<void>;
};

const ErrorReportForm = ({ handleGetTickets }: ErrorReportFormProps) => {
  const methods = useForm({
    resolver: yupResolver(errorReportSchema),
  });
  const dispatch = useAppDispatch();
  const uploadImgBtnRef = useRef<MultipleImgUploadBtnRef>(null);
  const [paymentChannel, setPaymentChannel] = useState<TSelectFieldData[]>([]);
  const [isSending, setIsSending] = useState(false);

  const onCancel = () => {
    methods.reset();
    if (uploadImgBtnRef.current) uploadImgBtnRef.current.onReset();
  };

  const onSubmit = async (data: yup.Asserts<typeof errorReportSchema>) => {
    setIsSending(true);
    const formData = new FormData();
    formData.append("payment_channel_name", data.payment_channel);
    formData.append("error_type", data.error_type);
    formData.append("description", data.desc);
    data.error_image.forEach((img: any) => {
      formData.append("image", img);
    });
    const res: any = await dispatch(createTicket(formData));
    if (res?.meta?.requestStatus === "fulfilled") {
      onCancel();
      CustomToastMessage.success("Thành công", "Báo cáo lỗi đã được ghi nhận");
      handleGetTickets();
    } else CustomToastMessage.error("Thất bại", "Đã có lỗi xảy ra");
    setIsSending(false);
  };

  const disabled =
    !methods.watch("payment_channel") ||
    !methods.watch("error_type") ||
    !methods.watch("desc") ||
    !methods.watch("error_image");

  const getPaymentChannel = async () => {
    const res: any = await dispatch(getListPaymentChannel({}));
    if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
      setPaymentChannel(
        res.payload?.data?.map((item: any) => {
          return {
            label: item.name,
            value: item.name,
          };
        })
      );
    }
  };
  useEffect(() => {
    getPaymentChannel();
  }, []);

  return (
    <div className="flex w-full flex-col gap-6 md:mx-auto">
      <HeaderTitle subTitle="Báo cáo lỗi" />
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex w-full flex-col gap-y-10 rounded-xl bg-[#fff] p-4 shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)]"
        >
          <div className="flex flex-col gap-y-4">
            <SelectField
              formField="payment_channel"
              title="Tên kênh thanh toán"
              placeHolder="Chọn tên kênh thanh toán"
              watch={methods.watch}
              setValue={methods.setValue}
              required
              errors={methods.formState.errors}
              className="h-[44px]"
              data={paymentChannel}
            />
            <SelectField
              formField="error_type"
              title="Loại báo cáo lỗi"
              placeHolder="Chọn loại báo cáo lỗi"
              watch={methods.watch}
              setValue={methods.setValue}
              required
              errors={methods.formState.errors}
              className="h-[44px]"
              data={[
                { label: "Error 1", value: "ERROR_1" },
                { label: "Error 2", value: "ERROR_2" },
              ]}
            />
            <InputField
              name="desc"
              id="desc"
              label="Mô tả"
              isRequired
              placeholder="Nhập mô tả"
              register={methods.register}
              errors={methods.formState.errors}
              variant="account"
            />
            <MultipleImgUploadBtn ref={uploadImgBtnRef} />
          </div>
          <div className="flex gap-x-4 self-end">
            <Button
              onClick={onCancel}
              title="Hủy"
              outline
              type="button"
              className="w-[182px]"
            />
            <Button
              title={isSending ? "Đang gửi" : "Gửi"}
              disabled={disabled}
              type="submit"
              className="w-[182px]"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ErrorReportForm;
