/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "../../assets/icon/icon-search.svg";
import ResetIcon from "../../assets/icon/icon-reset.svg";
import { useAppDispatch } from "hooks/appHook";
import Button from "components/button/button";
import { defaultFilter } from "../../constants/txn.constant";
import { useControlQueryParams } from "../../hooks/useControlQueryParams";
import { SelectDateValue, TFilter, TStatus } from "types/txn";
import {
  getDateFilterData,
  mappingStatusLabel,
  retrieveFilterFromURL,
} from "../../lib/transaction/filter";
import { getListPaymentChannel } from "store/actions/paymentChannelAction";

import ChooseDate from "./choose-date";
import { Label } from "@radix-ui/react-dropdown-menu";

import SelectField from "components/fields/SelectField";
import { getTransacions } from "store/actions/transactionAction";
import { bankService } from "store/services/bankService";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { formatTimeStampToStr } from "lib/date";

type Props = {
  /**
   * The status options for filtering transactions.
   */
  isDeletedChannel: boolean;
  getTransaction: () => void;
  setValue: any;
  watch: any;
  statusOptions: TStatus[];
  handleApplyFilter: (params: TFilter) => void;
  handleResetFilter: () => void;
  filters: TFilter;
  setFilters: Dispatch<SetStateAction<TFilter>>;
};

/**
 * Filter component for filtering transactions.
 * When applying the filter, the filter value will be pushed to the URL.
 * When resetting the filter, the filter value will be removed from the URL.
 */
const Filter = ({
  setValue,
  watch,
  getTransaction,
  isDeletedChannel,
  handleApplyFilter,
  handleResetFilter: _handleResetFilter,
  filters,
  setFilters,
}: Props) => {
  const { setParams, deleteParams } = useControlQueryParams();
  const [searchParams] = useSearchParams();

  function handleChangeStatus(status: string) {
    setFilters({ ...filters, status });
  }

  const [channel, setChannel] = useState<any>([]);
  const [channelResData, setChannelResData] = useState<any>([]);
  const [bankAccount, setBankAccount] = useState<any>([]);
  const dispatch = useAppDispatch();

  const getPaymentChannel = async () => {
    const res: any = await dispatch(
      getListPaymentChannel({ is_deleted: isDeletedChannel })
    );
    setChannel(
      res?.payload?.data?.map((item: any) => {
        return {
          label: item?.name,
          value: item?.va_account_no,
        };
      })
    );
    setChannelResData(res?.payload?.data);
  };
  const getBankAccounts = async () => {
    try {
      const res: any = await bankService.getBankAccount({});
      setBankAccount(
        res?.data?.items?.map((item: any) => {
          return { label: item?.account_no, value: item?.account_no };
        })
      );
    } catch (error) {
      toast.error("Get bank accounts failed!");
    }
  };

  useEffect(() => {
    getPaymentChannel();
    getBankAccounts();
  }, []);

  /**
   * Handles the change event of the date filter.
   * @param value - The selected date value.
   * @param startTime - The start time for custom date range.
   * @param endTime - The end time for custom date range.
   */
  function handleChangeDate(
    value: SelectDateValue,
    startTime?: number,
    endTime?: number
  ) {
    // Not custom date => generate start_time and end_time
    if (value !== "custom") {
      const data = getDateFilterData(value);
      if (data.length < 2) return;

      const [_startTime, _endTime] = data;
      const startT = formatTimeStampToStr(_startTime);
      const endT = formatTimeStampToStr(_endTime, true);

      setFilters((prev: any) => ({
        ...prev,
        date: value,
        start_time: startT,
        end_time: endT,
      }));
    } else if (startTime && endTime) {
      const startT = formatTimeStampToStr(startTime);
      const endT = formatTimeStampToStr(endTime, true);
      setFilters({
        ...filters,
        date: "custom",
        start_time: startT,
        end_time: endT,
      });
    }
  }

  const handleResetFilter = () => {
    setFilters(defaultFilter);
    setValue("channel", "");
    setValue("bank_account_no", "");

    _handleResetFilter();
  };

  useEffect(() => {
    setFilters({
      ...filters,
      channel: watch("channel"),
    });
  }, [watch("channel")]);

  useEffect(() => {
    console.log({ value: watch("bank_account_no") });
    if (watch("bank_account_no")) {
      const newChannels = channelResData.filter((item: any) => {
        return item.bank_account_no === watch("bank_account_no");
      });

      setChannel(
        newChannels?.map((item: any) => {
          return {
            label: item?.name,
            value: item?.va_account_no,
          };
        })
      );
      console.log({ newChannels });
    }
    setFilters({
      ...filters,
      bank_account_no: watch("bank_account_no"),
    });
  }, [watch("bank_account_no")]);

  return (
    <div
      id="filter"
      className="z-20 flex flex-col items-end gap-4 rounded-xl border border-[#F5F7FA] bg-white p-6 shadow-[0px_4px_20px_0px_rgba(96,111,134,0.06)] md:relative md:flex-row md:justify-between md:gap-0"
    >
      <div className="relative z-10 flex w-fit grid-cols-4 flex-col gap-6 md:flex-row">
        <ChooseDate value={filters.date} onChange={handleChangeDate} />
        <div className="flex flex-col gap-2 space-y-1">
          <Label className="text-sm font-semibold">Số tài khoản</Label>
          <SelectField
            formField="bank_account_no"
            data={bankAccount}
            setValue={setValue}
            placeHolder="Chọn số tài khoản"
            watch={watch}
            className="h-[44px] !w-[205px] bg-white"
            defaultValue="Tất cả"
          />
        </div>
        {watch("drcr") === "C" && (
          <div className="flex flex-col gap-2 space-y-1">
            <Label className="text-sm font-semibold">Tên kênh thanh toán</Label>
            <SelectField
              formField="channel"
              data={channel}
              setValue={setValue}
              placeHolder="Chọn kênh thanh toán"
              watch={watch}
              className="h-[44px] !w-[205px] bg-white"
              defaultValue="Tất cả"
            />
          </div>
        )}

        {/* <ChooseStatus options={statusOptions} value={filters.status as TStatus} onChange={handleChangeStatus} />
        <ChooseStore value={filters.customer_id} onChange={handleChangeStore} />
        <EnterBillId value={String(filters.bill_id)} onChange={(value) => setFilters({ ...filters, bill_id: value })} /> */}
      </div>
      <div className="grid w-full grid-cols-2 gap-4 md:w-auto">
        <Button
          icon={ResetIcon}
          title="Đặt lại"
          outline
          onClick={handleResetFilter}
        />
        <Button
          icon={SearchIcon}
          title="Áp dụng"
          onClick={() => handleApplyFilter(filters)}
        />
      </div>
    </div>
  );
};

export default Filter;
