import { Tooltip } from "@chakra-ui/tooltip";
import ImgComing from "assets/img/bank/coming.png";
import IcInfo from "assets/img/bank/ic_info.svg";
import Button from "components/button/button";
import { useAppDispatch } from "hooks/appHook";
import { CustomToastMessage } from "lib/CustomToastMessage";
import { BANK_NAME_SHORT } from "lib/constants";
import { ERROR_CODE } from "lib/errorCode";
import { useState } from "react";
import { PiCheckCircleFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { checkRegister } from "store/actions/bankAction";

interface ICell {
  children: React.ReactNode;
  className?: string;
  lastCell?: boolean;
}

interface IBankCell {
  logo: string;
  code: string;
}

interface IBtnCell {
  bankCode: string;
  accountType: string;
  setOpenQr?: any;
}

interface IOpenAccCell extends IBtnCell {
  setOpenQr: any;
}

const RecommendTag = () => {
  return (
    <span className="w-full whitespace-nowrap rounded bg-[rgba(39,144,195,0.1)] px-2 py-1 font-medium text-[#0598ED]">
      Khuyên dùng
    </span>
  );
};

export const Cell: React.FC<ICell> = ({ children, className }) => {
  return (
    <div
      className={`flex h-[68px] items-center border-b border-[#E8E8E8] py-[10px] pr-6 text-sm text-[#0F1D40] ${className}`}
    >
      {children}
    </div>
  );
};

export const BankCell = ({ logo, code }: IBankCell) => {
  return (
    <Cell className="w-full">
      <div className="flex w-[max-content] items-center gap-1">
        <img src={logo} alt="bank_logo" className="h-8 w-auto md:h-[46px]" />
        <div className="flex w-full justify-between gap-1 font-inter text-sm text-[#0F1D40] md:w-fit md:flex-col">
          <div className="flex items-center justify-between gap-2 text-sm">
            {code}
            <Tooltip
              label={
                <div>
                  ATOM đã ký kết hợp tác với ngân hàng.
                  <br /> Để đem đến kết nối cho khách hàng.
                </div>
              }
              hasArrow
              fontSize={12}
              fontWeight={400}
              lineHeight={5}
              fontFamily={"Montserrat"}
              bg="#262626"
              px={3}
              py={1.5}
              borderRadius={8}
              color="white"
              placement="top"
              textAlign={"center"}
            >
              <img src={IcInfo} alt="" className="h-4 w-4" />
            </Tooltip>
          </div>
          <RecommendTag />
        </div>
      </div>
    </Cell>
  );
};

export const OpenAccCell = ({
  bankCode,
  accountType,
  setOpenQr,
}: IOpenAccCell) => {
  const isComing =
    (bankCode === "OCB" && accountType === "BUSINESS") || bankCode === "VPB";
  return (
    <Cell>
      {isComing ? (
        <p>Ký kết hợp đồng</p>
      ) : (
        <p
          onClick={() => setOpenQr(bankCode)}
          className="cursor-pointer text-sm font-normal italic text-[#0598ED] underline"
        >
          Mở tài khoản trực tuyến
        </p>
      )}
    </Cell>
  );
};

export const NotiCell = ({
  moneyIn,
  moneyOut,
}: {
  moneyIn?: boolean;
  moneyOut?: boolean;
}) => {
  return (
    <Cell className="flex items-center gap-1 md:flex-col md:items-start md:justify-center">
      {moneyIn && (
        <div className="flex items-center gap-1 whitespace-nowrap">
          <div className="flex h-5 w-5 items-center justify-center">
            <PiCheckCircleFill
              color={moneyIn ? "#2EB553" : "#BFBFBF"}
              size={16}
            />
          </div>
          <p className="text-sm font-normal text-[#0F1D40]">
            Báo có tài khoản QR
          </p>
        </div>
      )}
      {moneyOut && (
        <div className="flex items-center gap-1 whitespace-nowrap">
          <div className="flex h-5 w-5 items-center justify-center">
            <PiCheckCircleFill
              color={moneyOut ? "#2EB553" : "#BFBFBF"}
              size={16}
            />
          </div>
          <p className="text-sm font-normal text-[#0F1D40]">
            Báo nợ tài khoản QR
          </p>
        </div>
      )}
    </Cell>
  );
};

export const BtnCell = ({ bankCode, accountType, setOpenQr }: IBtnCell) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const isComing =
    (bankCode === "OCB" && accountType === "BUSINESS") || bankCode === "VPB";
  return (
    <Cell className="!pr-0">
      {isComing ? (
        <div className="flex items-center">
          <img src={ImgComing} alt="" className="h-[46px] w-[153px]" />
        </div>
      ) : (
        <Button
          title="Kết nối"
          disabled={loading}
          onClick={async () => {
            setLoading(true)
            const res: any = await dispatch(
              checkRegister({ bank_code: bankCode })
            );
            if (res?.payload?.data) {
              navigate(`step2?type=${BANK_NAME_SHORT[bankCode]}`);
            } else {
              CustomToastMessage.error(
                "Thêm ngân hàng thất bại",
                ERROR_CODE[res?.payload?.error_code]
              );
              if (
                [
                  "CUSTOMER_NOT_FOUND_VCCB",
                  "ACCOUNT_CLOSED_UNREGISTERED",
                ].includes(res?.payload?.error_code)
              )
                setOpenQr("download");
            }
            setLoading(false)
          }}
        />
      )}
    </Cell>
  );
};

export const RecommendTextBox = () => {
  return (
    <div className="rounded-lg border border-[#D9DBE9] p-4 text-sm italic">
      Chúng tôi khuyên bạn nên sử dụng ngân hàng được <RecommendTag /> để có
      trải nghiệm tuyệt vời nhất vì tất cả thao tác đều được thực hiện trực
      tuyến. Bạn chỉ cần:
      <ul className="ml-4 list-disc">
        <li className="italic">
          <span className="text-[14px] font-semibold not-italic text-[rgba(38,38,38,1)]">
            5 phút{" "}
          </span>
          để mở tài khoản (nếu chưa có)
        </li>
        <li className="italic">
          <span className="text-[14px] font-semibold not-italic text-[rgba(38,38,38,1)]">
            2 phút{" "}
          </span>
          để tích hợp ngân hàng vào
          <span className="font-semibold not-italic text-primary"> ATOM.</span>
        </li>
      </ul>
    </div>
  );
};
